// modules/investor/components/InvestorDrawer.tsx

import React from 'react';
import { Drawer, Grid, Table, Text, ScrollArea, Card, Divider, Box, Image, Loader } from "@mantine/core";
import { IconArrowDown, IconArrowUp } from '@tabler/icons';
import dayjs from 'dayjs';
import { imageLink } from 'functions';
import { InvestorReport } from "../../../../../../types/response";
import { Customer as CustomerType } from "../../../../../../types/response";
import { useTranslation } from 'react-i18next';

interface InvestorDrawerProps {
    selectedInvestor: CustomerType | null;
    investorReport: InvestorReport | null;
    onClose: () => void;
}

const InvestorDrawer: React.FC<InvestorDrawerProps> = ({ selectedInvestor, investorReport, onClose }) => {
    const { t } = useTranslation();
    return (
        <Drawer
            opened={selectedInvestor !== null}
            onClose={onClose}
            title={selectedInvestor ? `${selectedInvestor.accountname} (${selectedInvestor.firstname} ${selectedInvestor.lastname})` : ''}
            position="right"
            padding={40}
            size={800}
        >
            {selectedInvestor && !investorReport && (
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                    <Loader />
                </Box>
            )}
            {selectedInvestor && investorReport && (
                <Grid>
                    <Grid.Col>
                        <Table>
                            <tbody>
                                <tr><td>{t('رأس المال')}</td><td>{Number(investorReport?.report?.capitol)?.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td></tr>
                                <tr><td>{t('النسبة المئوية')}</td><td>{Math.round(investorReport?.report?.ratio * 10000) / 100 + '%'}</td></tr>
                                <tr><td>{t('الربح الكلي')}</td><td>{investorReport?.report?.total_profit > 0 ? <IconArrowUp color='green' size={16} /> : <IconArrowDown color='red' size={16} />}{Number(investorReport?.report?.total_profit)?.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td></tr>
                                <tr><td>{t('ربح المستثمر')}</td><td>{investorReport?.report?.investor_profit > 0 ? <IconArrowUp color='green' size={16} /> : <IconArrowDown color='red' size={16} />}{Number(investorReport?.report?.investor_profit)?.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td></tr>
                                <tr><td>{t('ربح الشركة')}</td><td>{investorReport?.report?.company_profit > 0 ? <IconArrowUp color='green' size={16} /> : <IconArrowDown color='red' size={16} />}{Number(investorReport?.report?.company_profit)?.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td></tr>
                                <tr><td>{t('إجممالي السحوبات')}</td><td>{investorReport?.report?.total_draws?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td></tr>
                            </tbody>
                        </Table>
                    </Grid.Col>
                    <Grid.Col>
                        <Text mb={'xs'}>{t('العقارات')}</Text>
                        <ScrollArea style={{ height: 'calc(100vh - 271px)' }}>
                            {investorReport?.details?.map((item, index) => (
                                <Card withBorder mb={'xs'} key={index}>
                                    <Card.Section px={10} py={70}>
                                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                            <Box sx={theme => ({
                                                display: 'flex',
                                                flexDirection: 'column',
                                                backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[0],
                                                padding: theme.spacing.xs,
                                                borderRadius: theme.spacing.xs,
                                            })}>
                                                <Box sx={() => ({ display: 'flex' })}>
                                                    {Boolean(item.property?.documents?.length) && (
                                                        <Image
                                                            src={imageLink(item.property.documents[0].path)}
                                                            sx={{ borderRadius: 5, overflow: 'hidden' }}
                                                            width={100}
                                                            ml={'xs'}
                                                            mb={'xs'}
                                                        />
                                                    )}
                                                    <Box ml={'xs'}>
                                                        <Text size={'sm'}>{item.property.name}</Text>
                                                        <Text size={'sm'}>{item.property.address}</Text>
                                                    </Box>
                                                </Box>
                                                <Box ml={10}>
                                                    <Divider my={'xs'} />
                                                    <Text size={'sm'}><strong>{t('سعر الشراء')}: </strong>{Number(item.property.cost)?.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Text>
                                                    <Text size={'sm'}><strong>{t('سعر العرض')}: </strong>{Number(item.property.price)?.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Text>
                                                    {Boolean(item.property.selling_price) && (
                                                        <Text size={'sm'}><strong>{t('سعر البيع')}: </strong>{Number(item.property.selling_price)?.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Text>
                                                    )}
                                                    <Text size={'sm'}><strong>{t('الربح')}: </strong>
                                                        <Text color={item.profit > 0 ? 'green' : 'red'} sx={{ display: 'inline' }}>
                                                            {Number(item.profit)?.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                        </Text>
                                                    </Text>
                                                    {item.property.selling_price && (
                                                        <Text size={'sm'}><strong>{t('تاريخ العرض')}: </strong>{dayjs(item.property.selling_date).format('DD-MM-YYYY')}</Text>
                                                    )}
                                                    <Text size={'sm'}><strong>{t('تاريخ البيع')}: </strong>{dayjs(item.date).format('DD-MM-YYYY')}</Text>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Card.Section>
                                </Card>
                            ))}
                        </ScrollArea>
                    </Grid.Col>
                </Grid>
            )}
        </Drawer>
    );
};

export default InvestorDrawer;
