// vendors
import {
    Box,
} from "@mantine/core";
import { useTranslation } from 'react-i18next';
import { useForm } from '@mantine/form';
import Axios from 'axios';

// utils
import PageHeading from "../../../../components/PageHeading";
import { useHasPermission } from '../../../../hooks';


// types
import NoPermisionPage from '../../../../components/NoPermisionPage';
import { parseError, randomString } from 'functions';
import { showNotification, updateNotification } from '@mantine/notifications';
import { IconSend } from '@tabler/icons';
import FormFields from './FormFields';

export default function Notifications() {

    const permissionGranted = useHasPermission(["super_users.read"]);

    const { t } = useTranslation();

    const form = useForm({
        initialValues: {
            title: '',
            body: '',
            link: '',
        },
    });

    if (!permissionGranted) {
        return <NoPermisionPage />;
    }
    const handleSubmit = (values: any) => {

        const id = randomString(10);
        showNotification({
            id: id,
            title: t("جاري الارسال"),
            message: t("يرجى الإنتظار..."),
            loading: true,
            autoClose: false,
            disallowClose: true,
        });
        Axios.post('https://fcm.googleapis.com/fcm/send', {
            "notification": {
                "title": values.title,
                "body": values.body,
                "click_action": values.link,
                "icon": "https://www.google.com/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png"
            },
            "to": "/topics/all"
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'key=AAAAsIs1Quk:APA91bG-7AZh7b5p_4ou6t_mPm-SfuK8nkChCz8keoTXxeI2KxbOM8VLD6z1t-FvH4b5KfW6721UN9FH38K9ltFj9uRheAQ43WuptA6nXdrpezKYQKuRG1hMT0zse8jSy3x1th-eeJv3'
            }
        }).then((res) => {
            form.reset();
            updateNotification({
                id: id,
                title: t("نجاح"),
                message: t("تم ارسال الاشعار بنجاح"),
                color: "green",
                icon: <IconSend />,
                autoClose: true,
            });
        }).catch((err) => {
            updateNotification({
                id: id,
                title: t("فشل"),
                message: parseError(err),
                color: "red",
                autoClose: false,
                disallowClose: false,
            });
        });


    }
    return (
        <>
            <PageHeading>
                {t("الاشعارات")}
            </PageHeading>
            <Box
                mx={'xl'}
                my={20}
                sx={{
                    maxWidth: 600,
                }}
            >
                <FormFields
                    form={form}
                    onSubmit={handleSubmit}

                />
            </Box>
        </>
    );
}