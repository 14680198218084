import React from "react";
import { NumberInput } from "@mantine/core";
import { useTranslation } from "react-i18next";

interface ScreenTimeoutInputProps {
    screenTimeout: number;
    setScreenTimeout: (value: number) => void;
}

const ScreenTimeoutInput: React.FC<ScreenTimeoutInputProps> = ({ screenTimeout, setScreenTimeout }) => {
    const { t } = useTranslation();

    return (
        <NumberInput
            value={screenTimeout}
            onChange={(value) => setScreenTimeout(value || 0)}
            label={t("Screen timeout (minutes)")}
            size="xs"
        />
    );
};

export default ScreenTimeoutInput;
