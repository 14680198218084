import React from 'react';
import { Table, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';

interface RevenueTableProps {
    invoicesReport: any;
}

const RevenueTable: React.FC<RevenueTableProps> = ({ invoicesReport }) => {
    const { t } = useTranslation();

    return (
        <>
            <Text size={'lg'} weight={'bold'} mb={'lg'} mt={'xl'}>
                {t("تقارير المصروفات")}
            </Text>
            <Table>
                <thead>
                    <tr>
                        <th>
                            {t("المبلغ")}
                        </th>
                        <th>
                            {t("الموضوع")}
                        </th>
                        <th>
                            {t("الوصف")}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        invoicesReport.expenses.map((type: any, index: number) => (
                            <tr key={index}>
                                <td>
                                    {type.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </td>
                                <td>
                                    {t(type.subject)}
                                </td>
                                <td>
                                    {type.description}
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
        </>
    );
};

export default RevenueTable;
