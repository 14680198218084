import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { User } from "../types/response";

export default function useHasPermission(permissions: string[]): boolean {

    const user: User = useSelector((state: any) => state.user.data);
    const userPermissions: any = user?.permissions?.permissions;

    const [hasPermission, setHasPermission] = useState(false);
    useEffect(() => {
        if (userPermissions && permissions.every((item: any) =>
            Object.keys(userPermissions).includes(item.split('.')[0]) 
            && userPermissions[item.split('.')[0]][item.split('.')[1]]
        )) {
            setHasPermission(true);
        }
    }, [userPermissions]);

    return hasPermission
}