import React from 'react';
import {
    Grid,
    TextInput,
    Button,
    NumberInput,
    Textarea,
    LoadingOverlay,
    Text,

} from "@mantine/core";
import tafqeet from 'inc/tafqeet';
import MediaLibraryField from '../../../../../components/MediaLibraryField';
import UtilityCategorySelectField from '../../../../../components/UtilityCategorySelectField';
import { IconSend } from '@tabler/icons';
import VendorSelectField from 'components/VendorSelectField';
interface FormFieldsProps {
    form: any;
    data?: any;
    loading: boolean;
    onSubmit: (values: any) => void;
    isEdit: boolean;
    t: (key: string) => string;
}

const FormFields: React.FC<FormFieldsProps> = ({ form, data, loading, onSubmit, isEdit, t }) => {
    return (
        <form onSubmit={form.onSubmit(onSubmit)}>
            <Grid gutter="lg" mt={20}>
                <LoadingOverlay visible={loading} overlayBlur={2} />
                <Grid.Col span={12}>
                    <TextInput
                        withAsterisk
                        size="xs"
                        label={t("Name")}
                        placeholder={t("Name")}
                        {...form.getInputProps('name')}
                        defaultValue={data?.name}
                    />
                </Grid.Col>
                <Grid.Col span={12}>
                    <Textarea
                        withAsterisk
                        size="xs"
                        label={t("Description")}
                        placeholder={t("Description")}
                        {...form.getInputProps('description')}
                        defaultValue={data?.description}
                    />
                </Grid.Col>
                <Grid.Col span={12}>
                    <NumberInput
                        withAsterisk
                        size="xs"
                        label={t("Price")}
                        placeholder={t("Price")}
                        icon={<Text size='xs'>IQD</Text>}
                        defaultValue={data?.price}
                        parser={(value: any) => value.replace(/[^\d]/g, '')}
                        onChange={(val: any) => {
                            form.setFieldValue('price', val);
                            form.setFieldValue('price_literal', tafqeet(val));
                        }}
                        formatter={(value: any) =>
                            !Number.isNaN(parseFloat(value))
                                ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                : ''
                        }
                    />
                </Grid.Col>
                <Grid.Col span={12}>
                    <TextInput
                        withAsterisk
                        size="xs"
                        label={t("Price Literal")}
                        placeholder={t("Price Literal")}
                        {...form.getInputProps('price_literal')}
                        defaultValue={data?.price_literal}
                    />
                </Grid.Col>
                <Grid.Col span={12}>
                    <TextInput
                        withAsterisk
                        size="xs"
                        label={t("Address")}
                        placeholder={t("Address")}
                        defaultValue={data?.address}
                        {...form.getInputProps('address')}
                    />
                </Grid.Col>
                <Grid.Col span={12}>
                    <UtilityCategorySelectField
                        withAsterisk
                        utility={true}
                        size="xs"
                        label={t("Category")}
                        placeholder={t("Category")}
                        defaultValue={data?.category}
                        {...form.getInputProps('category')}
                    />
                </Grid.Col>

                <Grid.Col span={12}>
                    <VendorSelectField
                        withAsterisk
                        size="xs"
                        label={t("Vendor")}
                        placeholder={t("Vendor")}
                        onChange={(value: string) => {
                            form.setFieldValue('vendor', value);
                        }}
                        defaultValue={data?.vendor?.id}
                        {...form.getInputProps('vendor')}
                    />
                </Grid.Col>
                <Grid.Col span={12}>
                    <MediaLibraryField
                        inputProps={{
                            withAsterisk: true,
                            size: "xs",
                            label: t("Document"),
                            placeholder: t("Select documents"),
                        }}
                        multiple={true}
                        defaultValue={data?.documents}
                        {...form.getInputProps('documents')}
                    />
                </Grid.Col>
                <Grid.Col span={12}>
                    <Button
                        size="xs"
                        leftIcon={<IconSend size={16} />}
                        type="submit"
                    >
                        {isEdit ? t("Save") : t("Create")}
                    </Button>
                </Grid.Col>
            </Grid>
        </form>
    );
};

export default FormFields;
