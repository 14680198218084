import React, { useEffect } from "react";
import { Select } from "@mantine/core";

// api
import { getVendors } from "../api/vendor";

// types
import { Category as CategoryType, Vendor as VendorType } from "../types/response";
import { useTranslation } from "react-i18next";

export default function VendorSelectField({
    defaultData = [],
    ...props
}: {
    defaultData?: VendorType[];
    [key: string]: any;
}) {

    const [data, setData] = React.useState<VendorType[]>(defaultData);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [search, setSearch] = React.useState<string>('');
    const [value, setValue] = React.useState<string | null>(props.defaultValue ? props.defaultValue : null);


    useEffect(() => {
        if (defaultData.length > 0 && search === '') {
            setData(defaultData);
            return;
        }
        setLoading(true);
        getVendors(1, 10, search).then((res) => {
            setData(res.data.vendors);
        }).catch((err) => {

        }).finally(() => {
            setLoading(false);
        });
    }, [search]);

    return (
        <Select
            label="Vendor"
            placeholder={loading ? 'Loading...' : 'Select vendor'}
            {...props}
            searchable
            data={[
                ...data?.map((vendor: VendorType) => ({
                    label: `${vendor.vendorname} - ${(vendor.category as CategoryType).name}`,
                    value: vendor.id,
                }))
            ]}
            value={value}
            onChange={(v) => {
                props.onChange && props.onChange(v);
                setValue(v);
            }}
            onSearchChange={(value) => {
                setSearch(value);
            }}
        />
    )

}