import { TFunction } from 'i18next';
import { IFieldMetadata } from 'types/printingTypes/types';

export const getDynamicFieldMetadata = (
  t: TFunction,
  formType: 'letter' | 'invoice' | 'property' | 'table',
  type?: string
): IFieldMetadata => {

  switch (formType) {
    case 'letter':
      return {
        num_id: {
          inputSize: 'xs',
          style: {},
          label: {
            default: t('العدد'),
          },
          placeholder: {
            default: t('العدد'),
          },
          type: 'TextInput',
          initialPositions: {
            default: [
              {
                x: -570.56005859375,
                y: 54.56005859375,
                percentX: -0.7185894944505667,
                percentY: 0.04862750320298574,
              },
            ],
          },
        },
        issued_at: {
          inputSize: 'xs',
          style: {
            fontSize: '20px',
            fontFamily: 'Tajawal',
            backgroundColor: '#fff',
            transform: 'translate(-20px,-20px)',
          },
          label: {
            default: t('Issue Date'),
          },
          placeholder: {
            default: t('Issue Date'),
          },
          type: 'TextInput',
          initialPositions: {
            default: [
              {
                x: -570.5600891113281,
                y: 85.76002502441406,
                percentX: -0.7185895328858037,
                percentY: 0.07643495991480755,
              },
            ],
          },
        },
        receiver: {
          inputSize: 'xs',
          style: {
            fontSize: '25px',
            fontFamily: 'Tajawal',
            textAlign: 'center',
          },
          label: {
            default: t('Receiver'),
          },
          placeholder: {
            default: t('Receiver'),
          },
          type: 'TextInput',
          initialPositions: {
            default: [
              {
                x: -288.96002197265625,
                y: 165.75997924804688,
                percentX: -0.3639294987061162,
                percentY: 0.14773616688774233,
              },
            ],
          },
        },
        subject: {
          inputSize: 'xs',
          style: {
            fontSize: '25px',
            fontFamily: 'Tajawal',
            textAlign: 'center',
          },
          label: {
            default: t('Subject'),
          },
          placeholder: {
            default: t('Subject'),
          },
          type: 'TextInput',
          initialPositions: {
            default: [
              {
                x: -289.76007080078125,
                y: 199.35995483398438,
                percentX: -0.36493711687755825,
                percentY: 0.17768266919249945,
              },
            ],
          },
        },
        body: {
          inputSize: 'xs',
          rowsCount: 10,
          style: {
            fontSize: '18px',
            fontFamily: 'Tajawal',
          },
          label: {
            default: t('Body'),
          },
          placeholder: {
            default: t('Body'),
          },
          type: 'Textarea',
          initialPositions: {
            default: [
              {
                x: -66.55999755859375,
                y: 247.36001586914062,
                percentX: -0.08382871229042034,
                percentY: 0.22046347225413604,
              },
            ],
          },
        },
        description: {
          inputSize: 'xs',
          rowsCount: 10,
          style: {
            fontSize: '18px',
            fontFamily: 'Tajawal',
          },
          label: {
            default: t('المرفقات'),
          },
          placeholder: {
            default: t('المرفقات'),
          },
          type: 'Textarea',
          initialPositions: {
            default: [
              {
                x: -60.1600341796875,
                y: 612.1600036621094,
                percentX: -0.07576830501220089,
                percentY: 0.5455971512140012,
              },
            ],
          },
        },
        sender: {
          inputSize: 'xs',
          style: {
            fontSize: '18px',
            fontFamily: 'Tajawal',
          },
          label: {
            default: t('Sender'),
          },
          placeholder: {
            default: t('Sender'),
          },
          type: 'TextInput',
          initialPositions: {
            default: [
              {
                x: -560.9600524902344,
                y: 940.1599273681641,
                percentX: -0.7064988066627637,
                percentY: 0.8379321990803601,
              },
            ],
          },
        },
      };
    case 'invoice':
      return {
        amount: {
          inputSize: 'xs',
          style: {},
          label: { default: t('Amount') },
          placeholder: {
            default: t('Amount')
          },
          type: 'TextInput',
          initialPositions: {
            out: [
              {
                x: -245.760009765625,
                y: 229.760009765625,
                percentX: -0.3095214228786209,
                percentY: 0.4095543846089572,
              },
            ],
            in: [
              {
                x: -245.760009765625,
                y: 229.760009765625,
                percentX: -0.3095214228786209,
                percentY: 0.4095543846089572,
              },
            ],
          },
        },
        amount_literal: {
          inputSize: 'xs',
          style: {},
          label: {
            default: t('Amount Letiral')
          },
          placeholder: {
            default: t('Amount Letiral')
          },
          type: 'TextInput',
          initialPositions: {
            out: [
              {
                x: -166.39990234375,
                y: 279.6799774169922,
                percentX: -0.20957166542034006,
                percentY: 0.49853828416576146,
              },
            ],
            in: [
              {
                x: -166.39990234375,
                y: 279.6799774169922,
                percentX: -0.20957166542034006,
                percentY: 0.49853828416576146,
              },
            ],
          },
        },
        subject: {
          inputSize: 'xs',
          style: {},
          label: {
            default: t('Subject')
          },
          placeholder: {
            default: t('Subject')
          },
          type: 'TextInput',
          initialPositions: {
            out: [
              {
                x: -195.199951171875,
                y: 329.59999084472656,
                percentX: -0.24584376721898615,
                percentY: 0.5875222653203682,
              },
            ],
            in: [
              {
                x: -195.199951171875,
                y: 329.59999084472656,
                percentX: -0.24584376721898615,
                percentY: 0.5875222653203682,
              },
            ],
          },
        },
        issued_at: {
          inputSize: 'lg',
          style: {
            fontSize: '25px',
            fontFamily: 'Tajawal',
            backgroundColor: '#fff',
          },
          label: {
            default: t('Issue Date')

          },
          placeholder: {
            default: t('Issue Date')
          },
          type: 'TextInput',
          initialPositions: {
            out: [
              {
                x: -518.5598297119141,
                y: 123.03999328613281,
                percentX: -0.6530980223071966,
                percentY: 0.21932262617848985,
              },
            ],
            in: [
              {
                x: -518.5598297119141,
                y: 123.03999328613281,
                percentX: -0.6530980223071966,
                percentY: 0.21932262617848985,
              },
            ],
          },
        },
        invoice_customer: {
          inputSize: 'xs',
          style: {},
          label: {
            default: t('نوع الفاتورة')

          },
          placeholder: {
            default: t('نوع الفاتورة')
          },
          type: 'TextInput',
          initialPositions: {
            out: [
              {
                x: -193.9198760986328,
                y: 385.2799835205078,
                percentX: -0.24423158198820252,
                percentY: 0.6867735891631155,
              },
            ],
            in: [
              {
                x: -193.9198760986328,
                y: 385.2799835205078,
                percentX: -0.24423158198820252,
                percentY: 0.6867735891631155,
              },
            ],
          },
        },
        customer_name: {
          inputSize: 'xs',
          style: {},
          label: {
            default: t('الزبون')

          },
          placeholder: {
            default: t('الزبون')
          },
          type: 'TextInput',
          initialPositions: {
            out: [
              {
                x: -249.9198760986328,
                y: 178.87998962402344,
                percentX: -0.3147605492426106,
                percentY: 0.3188591615401487,
              },
            ],
            in: [
              {
                x: -249.9198760986328,
                y: 178.87998962402344,
                percentX: -0.3147605492426106,
                percentY: 0.3188591615401487,
              },
            ],
          },
        },
        from_to: {
          inputSize: 'xs',
          style: {},
          label: {
            default: t('From to')

          },
          placeholder: {
            default: t('From to')
          },
          type: 'TextInput',
          initialPositions: {
            out: [
              {
                x: -311.51979064941406,
                y: 500.47999572753906,
                percentX: -0.39234230560379607,
                percentY: 0.8921212045054172,
              },
            ],
            in: [
              {
                x: -483.5198516845703,
                y: 503.68003845214844,
                percentX: -0.6089670676128089,
                percentY: 0.8978253804851131,
              },
            ],
          },
        },
      };


    // table data 
    case 'table':
      return {
        amount: {
          inputSize: 'xs',
          style: {},
          label: { default: t('Amount') },
          placeholder: {
            default: t('Amount')
          },
          type: 'TextInput',
          initialPositions: {
            out: [
              {
                x: -245.760009765625,
                y: 229.760009765625,
                percentX: -0.3095214228786209,
                percentY: 0.4095543846089572,
              },
            ],
            in: [
              {
                x: -245.760009765625,
                y: 229.760009765625,
                percentX: -0.3095214228786209,
                percentY: 0.4095543846089572,
              },
            ],
          },
        },
        amount_literal: {
          inputSize: 'xs',
          style: {},
          label: {
            default: t('Amount Letiral')
          },
          placeholder: {
            default: t('Amount Letiral')
          },
          type: 'TextInput',
          initialPositions: {
            out: [
              {
                x: -166.39990234375,
                y: 279.6799774169922,
                percentX: -0.20957166542034006,
                percentY: 0.49853828416576146,
              },
            ],
            in: [
              {
                x: -166.39990234375,
                y: 279.6799774169922,
                percentX: -0.20957166542034006,
                percentY: 0.49853828416576146,
              },
            ],
          },
        },
        subject: {
          inputSize: 'xs',
          style: {},
          label: {
            default: t('Subject')
          },
          placeholder: {
            default: t('Subject')
          },
          type: 'TextInput',
          initialPositions: {
            out: [
              {
                x: -195.199951171875,
                y: 329.59999084472656,
                percentX: -0.24584376721898615,
                percentY: 0.5875222653203682,
              },
            ],
            in: [
              {
                x: -195.199951171875,
                y: 329.59999084472656,
                percentX: -0.24584376721898615,
                percentY: 0.5875222653203682,
              },
            ],
          },
        },
        issued_at: {
          inputSize: 'lg',
          style: {
            fontSize: '25px',
            fontFamily: 'Tajawal',
            backgroundColor: '#fff',
          },
          label: {
            default: t('Issue Date')

          },
          placeholder: {
            default: t('Issue Date')
          },
          type: 'TextInput',
          initialPositions: {
            out: [
              {
                x: -518.5598297119141,
                y: 123.03999328613281,
                percentX: -0.6530980223071966,
                percentY: 0.21932262617848985,
              },
            ],
            in: [
              {
                x: -518.5598297119141,
                y: 123.03999328613281,
                percentX: -0.6530980223071966,
                percentY: 0.21932262617848985,
              },
            ],
          },
        },
        invoice_type: {
          inputSize: 'xs',
          style: {},
          label: {
            default: t('نوع الفاتورة')

          },
          placeholder: {
            default: t('نوع الفاتورة')
          },
          type: 'TextInput',
          initialPositions: {
            out: [
              {
                x: -193.9198760986328,
                y: 385.2799835205078,
                percentX: -0.24423158198820252,
                percentY: 0.6867735891631155,
              },
            ],
            in: [
              {
                x: -193.9198760986328,
                y: 385.2799835205078,
                percentX: -0.24423158198820252,
                percentY: 0.6867735891631155,
              },
            ],
          },
        },

        from_to: {
          inputSize: 'xs',
          style: {},
          label: {
            default: t('From to')

          },
          placeholder: {
            default: t('From to')
          },
          type: 'TextInput',
          initialPositions: {
            out: [
              {
                x: -245.760009765625,
                y: 229.760009765625,
                percentX: -0.3095214228786209,
                percentY: 0.3202543846089572,
              },
            ],
            in: [
              {
                x: -245.760009765625,
                y: 229.760009765625,
                percentX: -0.3399214228786209,
                percentY: 0.3202543846089572,
              },
            ],
          },
        },
      };

    // this is customizable
    case 'property':
      return {
        day: {
          inputSize: 'xs',
          style: {
            fontSize: '17px',
            width: "60px",
            height: "10px"
          },
          label: {
            default: t('اليوم'),
          },
          placeholder: {
            default: t('اليوم'),
          },
          type: 'TextInput',
          initialPositions: {
            default: [
              {

                x: -234.40008544921875,
                y: 153.60000610351562,
                percentX: -0.29500294987061162,
                percentY: 0.13673616688774233,
              },
            ],
          },
        },
        issued_at: {
          inputSize: 'xs',
          style: {
            fontSize: '17px',
            fontFamily: 'Tajawal',
            backgroundColor: '#fff',
            width: "100px",
            height: "10px",
          },
          label: {
            default: t('Issue Date'),
          },
          placeholder: {
            default: t('Issue Date'),
          },
          type: 'TextInput',
          initialPositions: {
            default: [
              {
                x: -400.40008544921875,
                y: 153.60000610351562,
                percentX: -0.50500294987061162,
                percentY: 0.13673616688774233,
              },
            ],
          },
        },
        investor_name: {
          inputSize: 'xs',
          style: {
            fontFamily: 'Tajawal',
            fontSize: '17px',
          },

          label: {
            default: t('اسم المستثمر'),
          },
          placeholder: {
            default: t('اسم المستثمر'),
          },
          type: 'TextInput',
          initialPositions: {
            default: [
              {
                x: -128.96002197265625,
                y: 205.75997924804688,
                percentX: -0.1599294987061162,
                percentY: 0.18503616688774233,
              },
            ],
          },
        },
        investor_ratio: {
          inputSize: 'xs',
          style: {
            fontFamily: 'Tajawal',
            textAlign: 'center',
            width: "70px",
            height: "10px",
            fontSize: '17px'
          },
          label: {
            default: t('نسبة المستثمر'),
          },
          placeholder: {
            default: t('نسبة المستثمر'),
          },
          type: 'TextInput',
          initialPositions: {
            default: [
              {
                x: -377.76007080078125,
                y: 483.35995483398438,
                percentX: -0.48193711687755825,
                percentY: 0.4334068266919249945,
              },
            ],
          },
        },
        investor_phone: {
          inputSize: 'xs',
          rowsCount: 10,
          style: {
            fontSize: "17px",
            fontFamily: 'Tajawal',
          },
          label: {
            default: t('رقم الجوال'),
          },
          placeholder: {
            default: t('رقم الجوال'),
          },
          type: 'TextInput',
          initialPositions: {
            default: [
              {
                x: -488.9600524902344,
                y: 928.1599273681641,
                percentX: -0.6004988066627637,
                percentY: 0.8300021990803601,
              },
            ],
          },
        },
        company_ratio: {
          inputSize: 'xs',
          rowsCount: 10,
          style: {
            fontSize: "17px",
            fontFamily: 'Tajawal',
            width: "70px",
            height: "10px",
          },
          label: {
            default: t('نسبة الشركة'),
          },
          placeholder: {
            default: t('نسبة الشركة'),
          },
          type: 'TextInput',
          initialPositions: {
            default: [
              {
                x: -393.76007080078125,
                y: 707.35995483398438,
                percentX: -0.49493711687755825,
                percentY: 0.6334068266919249945,
              },
            ],
          },
        },
        investor_name1: {
          inputSize: 'xs',
          style: {
            fontFamily: 'Tajawal',
            fontSize: '17px',

          },
          label: {
            default: t('اسم المستثمر'),
          },
          placeholder: {
            default: t('اسم المستثمر'),
          },
          type: 'TextInput',
          initialPositions: {
            default: [
              {
                x: -490.9600524902344,
                y: 867.1599273681641,
                percentX: -0.6204988066627637,
                percentY: 0.7790021990803601,
              },
            ],
          },
        },

        investor_nationalId: {
          inputSize: 'xs',
          style: {
            fontFamily: 'Tajawal',
            fontSize: '17px',

          },
          label: {
            default: t('رقم الهوية'),
          },
          placeholder: {
            default: t('رقم الهوية'),
          },
          type: 'TextInput',
          initialPositions: {
            default: [
              {
                x: -517.9600524902344,
                y: 895.1599273681641,
                percentX: -0.6504988066627637,
                percentY: 0.80074,
              },
            ],
          },
        },

        investor_address: {
          inputSize: 'xs',
          style: {
            fontFamily: 'Tajawal',
            fontSize: '17px',

          },
          label: {
            default: t('العنوان'),
          },
          placeholder: {
            default: t('العنوان'),
          },
          type: 'TextInput',
          initialPositions: {
            default: [
              {
                x: -498.9600524902344,
                y: 960.1599273681641,
                percentX: -0.6304988066627637,
                percentY: 0.8550021990803601,
              },
            ],
          },
        },

      };
    default:
      return {};
  }
};