import {
    Box,
    Text,
    UnstyledButton,
} from '@mantine/core';
import { useTranslation } from 'react-i18next';

export default function SideButton(props) {

    const { i18n } = useTranslation();

    return (
        <Box
            sx={{
                position: 'absolute',
                top: 0,
                right: 0,
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                pointerEvents: 'none',
                justifyContent: 'flex-end',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'flex-end',
                    flexDirection: 'column',
                    width: '100%',
                    '&>*:not(:first-child)': {
                        marginTop: 5,
                    }
                }}
            >
                {
                    props.buttons.map((button, index) => (
                        <UnstyledButton
                            {...button} 
                            sx={{
                                pointerEvents: 'all',
                                display: 'flex',
                                alignItems: 'stretch',
                                boxShadow: '0 0 25px -10px #000',
                                borderRadius: '50px 0 0 50px',
                                border: '3px solid',
                                borderColor: props.color ? props.color : 'white',
                                position: 'relative',
                                right: 0,
                                width: 'fit-content',
                                transform: button.active ? 'translateX(3px)' : i18n.language === 'en' ? 'translateX(calc(100% - 60px))' : 'translateX(calc(60px - 100%))',
                                transition: 'all 0.3s ease',
                                backgroundColor: button.active ? (button.bgColorActive ? button.bgColorActive : "095ee7") : (props.bgColor ? props.bgColor : "#2c7af7"),
                                '&:hover': {
                                    transform: 'translateX(3px)',
                                    boxShadow: '0 0 50px -30px #000',
                                    backgroundColor: button.active ? (button.bgColorActive ? button.bgColorActive : "095ee7") : (props.bgColorHover ? props.bgColorHover : '#095ee7'),
                                },
                                ...button.sx,
                            }}
                        >
                            <Box
                                sx={{
                                    padding: '1rem',
                                    borderRadius: '50% 0 0 50%',
                                    '& *': {
                                        color: props.color ? props.color : 'white',
                                    }
                                }}
                            >
                                {button.icon}
                            </Box>
                            <Box
                                sx={{
                                    paddingRight: '20px',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <Text
                                    size="md"
                                    sx={{
                                        color: props.color ? props.color : 'white',
                                        textTransform: 'uppercase',
                                    }}
                                >
                                    {
                                        button.active ? (button.activeLabel ? button.activeLabel : button.label) : button.label
                                    }
                                </Text>
                            </Box>
                        </UnstyledButton>
                    ))
                }
            </Box>
        </Box>
    );
}