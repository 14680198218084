
import { Skeleton } from '@mantine/core';

export default function SkeletonRows({ count, height }) {

    return (
        <>
        {Array.from({ length: count }, (_, i) => (
            <Skeleton key={i} style={{ marginBottom: 20 }} height={height} />
        ))}
        </>
    );

}