import React from 'react';
import { Table, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';

interface ExpensesTableProps {
    invoicesReport: any;
}

const ExpensesTable: React.FC<ExpensesTableProps> = ({ invoicesReport }) => {
    const { t } = useTranslation();

    const totalIncome = invoicesReport.income.reduce((total: number, type: any) => {
        return total + type.value;
    }, 0);

    const formattedTotalIncome = totalIncome.toLocaleString();

    return (
        <>
            <Text size={'lg'} weight={'bold'} mb={'lg'}>
                {t("تقارير المقبوضات")}
            </Text>
            <Table>
                <thead>
                    <tr>
                        <th>
                            {t("النوع")}
                        </th>
                        <th>
                            {t("المبلغ")}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        invoicesReport.income.map((type: any, index: number) => (
                            <tr key={index}>
                                <td>
                                    {t(type.type)}
                                </td>
                                <td>
                                    {type.value.toLocaleString()}
                                </td>
                            </tr>
                        ))
                    }
                    <tr>
                        <td>
                            {t("المجموع")}
                        </td>
                        <td>
                            {formattedTotalIncome}
                        </td>
                    </tr>
                </tbody>
            </Table>
        </>
    );
};

export default ExpensesTable;
