import { apiWithInterceptor } from ".";

// get
export const getCities = (page :number = 1, limit: number = -1, search: string = '' ) => apiWithInterceptor.get(`/city/`, { params: {page, limit, search, descending: 0} })
// export const getInvoice = (id: string) => apiWithInterceptor.get(`/invoice/${id}`)
// export const getInvoiceReport = (since: Date, until: Date) => apiWithInterceptor.get(`/invoice/report`, { params: {
//     empty_array: true,
//     since: since.toISOString().split('T')[0],
//     until: until.toISOString().split('T')[0],
// } })

// // post
export const createCity = (name: string) => apiWithInterceptor.post(`/city/`, {name})
// export const createBankInvoice = (data: {}) => apiWithInterceptor.post(`/invoice/`, {...data, asset: "6197a3db-725e-4af9-aee7-8cc62e357338"})
// export const createCashInvoice = (data: {}) => apiWithInterceptor.post(`/invoice/`, {...data, asset: "0d6c5de4-9460-44bf-bc8f-231699d712a5"})

// // delete
// export const deleteInvoice = (id: string) => apiWithInterceptor.delete(`/invoice/${id}`, { params: {approved: true} })
export const deleteCity = (id: string) => apiWithInterceptor.delete(`/city/${id}`, { params: {approved: true} })

// // put
// export const updateInvoice = (id: string, data: {}) => apiWithInterceptor.put(`/invoice/${id}`, data)
export const updateCity = (id: string, data: {}) => apiWithInterceptor.put(`/city/${id}`, data)