// vendors
import { useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import {
    Header as HeaderMantine,
    MediaQuery,
    Burger,
    Grid,
    Image,
} from '@mantine/core';
import { useSelector, useDispatch } from 'react-redux';

// utils
import { toggleNavListState } from '../../redux/reducers/theme';
import Logo from '../../assets/logo/logo-title-right-filled.svg';

export default function Header() {

    const dispatch = useDispatch();
    const sm = useMediaQuery('(max-width: 800px)');
    const navListOpen: boolean = useSelector((state: any) => state.theme.navListOpen);
    const theme = useMantineTheme();

    return (
        <HeaderMantine height={sm ? 70 : 0} p={sm ? "md" : 0}>
            <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
                    <Burger
                        opened={navListOpen}
                        onClick={() => dispatch(toggleNavListState())}
                        size="sm"
                        color={theme.colors.gray[6]}
                        mr="xl"
                    />
                </MediaQuery>

                <Grid>
                    <Image src={Logo} height={45} />
                </Grid>
            </div>
        </HeaderMantine>
    )

}