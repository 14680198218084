import { apiWithInterceptor } from ".";

// get
export const getInvestments = (page: number = 1, limit: number = -1, invertorId: string | null = null) => apiWithInterceptor.get(`/investment/`, { params: { page, limit, ...(invertorId && { investor_id: invertorId }) } })
export const getInvestorInvestments = (id: string, page: number = 1, limit: number = -1) => apiWithInterceptor.get(`/investment/investor/${id}`, { params: { page, limit } })
export const getInvestorReport = (customer_id: string) => apiWithInterceptor.get(`/investment/investor/${customer_id}/report/detailed`)
export const getInvestmentsReport = (page: number = 1, limit: number = -1, search: string = '') => apiWithInterceptor.get(`/investment/report/detailed`, { params: { page, limit, search } })
// get property investments (new route)
export const getPropertyInvestments = (propertyId: string) =>
    apiWithInterceptor.get(`/investment/property/${propertyId}`);
// post
export const createInvestment = (data: any) => apiWithInterceptor.post(`/investment/`, { ...data })

// // delete
export const deleteInvestment = (id: string) => apiWithInterceptor.delete(`/investment/${id}`, { params: { approved: true } })

// // put
export const updateInvestment = (id: string, data: any) => apiWithInterceptor.put(`/investment/${id}`, { ...data })