import { useEffect, useState } from 'react';
import dayjs from 'dayjs';

export function useDateRange() {
    const [dateRange, setDateRange] = useState<[Date, Date]>([
        dayjs().subtract(1, 'month').toDate(),
        dayjs().toDate(),
    ]);

    useEffect(() => {
        setTimeout(() => {
            setDateRange([
                dayjs().subtract(1, 'month').toDate(),
                dayjs().toDate(),
            ]);
        }, 1000);
    }, []);

    return [dateRange, setDateRange] as const;
}