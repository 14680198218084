// vendors
import {
    Box,
    Text,
    Button,
} from "@mantine/core";
import { useEffect } from 'react';
import { showNotification } from '@mantine/notifications';
import { IconAlertCircle } from '@tabler/icons';
import {

    IconPrinter,
} from '@tabler/icons';
import dayjs from 'dayjs';

// utils
import PageHeading from "../../../../../components/PageHeading";


// types
import { InvestmentPropertyReport } from "../../../../../types/response";
import InvestorPropertyReport from "../../../../../types/response/InvestorPropertyReport"

import { dateFilterFn, stringFilterFn } from "mantine-data-grid";
import SkeletonRows from "../../../../../components/SkeletonRows";
import NoDataFound from "../../../../../components/NoDataFound";
import { useTranslation } from "react-i18next";
import { getInvestmentsReport, getPropertyInvestments } from "api/investment";
import { useData } from "../../../../../hooks/useUsersData";
import DataGridComponent from '../../../../../components/DataGridComponent';
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import PropertyDetailsModal from "./components/PropertyDetailsModal"
import { parseError } from "functions";
export default function Users() {
    const { t } = useTranslation();
    const [selectedProperty, setSelectedProperty] = useState<InvestmentPropertyReport | null>(null);
    const [selectedInvestor, setSelectedInvestor] = useState<InvestorPropertyReport | null>(null);
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();

    const coloumn = [
        {
            accessorKey: 'from_to',
            header: t('اسم العقار') as string,
            filterFn: stringFilterFn,
            size: 300,
            cell: (props: any) => props.row.original.name,
        },
        {
            accessorKey: 'subject',
            header: t('سعر الشراء') as string,
            filterFn: stringFilterFn,
            cell: (props: any) => props.row.original.cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        },
        {
            header: t('تاريخ الشراء') as string,
            accessorKey: 'Issue Date',
            filterFn: dateFilterFn,
            cell: (props: any): string => dayjs(props.row.original.purchase_date).format('DD/MM/YYYY'),
        },
        {
            accessorKey: 'subject',
            header: t('سعر البيع') as string,
            filterFn: stringFilterFn,
            cell: (props: any) => props.row.original.sale_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        },
        {
            header: t('تاريخ البيع') as string,
            accessorKey: 'Issue Date',
            filterFn: dateFilterFn,
            cell: (props: any): string => dayjs(props.row.original.sale_date).format('DD/MM/YYYY'),
        },
        {
            accessorKey: 'subject',
            header: t('الربح الكلي') as string,
            filterFn: stringFilterFn,
            cell: (props: any) => props.row.original.profit.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        },
        {
            accessorKey: 'subject',
            header: t('نسبة المستثمرين') as string,
            filterFn: stringFilterFn,
            cell: (props: any) => (props.row.original.investors_ratio * 100).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + '%',
        },
        {
            accessorKey: 'subject',
            header: t('ربح المستثمرين') as string,
            filterFn: stringFilterFn,
            cell: (props: any) => props.row.original.investors_profit.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        },
        {
            accessorKey: 'subject',
            header: t('نسبة الشركة') as string,
            filterFn: stringFilterFn,
            cell: (props: any) => (props.row.original.company_ratio * 100).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + '%',
        },
        {
            accessorKey: 'subject',
            header: t('ربح الشركة') as string,
            filterFn: stringFilterFn,
            cell: (props: any) => props.row.original.company_profit.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        },
        {
            accessorKey: 'subject',
            header: t('طباعة العقد') as string,
            filterFn: stringFilterFn,
            cell: (props: any) => (
                <Button
                    variant="default"

                    onClick={() => {
                        navigate('./printForm', {
                            state: props.row.original,
                        });
                    }}
                >
                    <IconPrinter size={16} />
                    <Text mx={'xs'}>{t('طباعة')}</Text>
                </Button>
            ),
        },
    ];
    const {
        loading,
        data,
        noData,
        total,
        load,
        search,
    } = useData<InvestmentPropertyReport>({
        fetchData: (page: any, limit: any, search: any) => getInvestmentsReport(page, limit, search).then(res => ({
            data: res.data.report,
            total: res.data.total,
        })),
        errorMessage: 'Error while fetching user data',
    });



    return (
        <>
            <PageHeading>
                {t('العقارات الاستثمارية')}
            </PageHeading>
            <Box
                sx={{
                    padding: '0 20px',
                    width: '100%',
                    height: 'calc(100vh - 151.594px)',
                }}
            >
                {
                    data && data.length > 0 ? (
                        <DataGridComponent
                            data={data}
                            total={total}
                            loading={loading}
                            onPageChange={load}
                            onSearch={search}
                            onRowClick={(row) => {
                                setIsOpen(true);
                                setSelectedProperty(row)
                            }}
                            columns={coloumn}
                        />
                    ) : noData ? (
                        <NoDataFound />
                    ) : (
                        <SkeletonRows count={10} height={20} />
                    )
                }
            </Box>
            <PropertyDetailsModal
                opened={isOpen}
                onClose={() => {
                    setSelectedInvestor(null);
                    setIsOpen(false);
                }}
                selectedPropertyId={selectedProperty ? selectedProperty?.property?.id : null}
            />
        </>
    );
}