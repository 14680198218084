import { Text, Image, Box, Card, Grid, useMantineTheme, ActionIcon } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { IconFileText, IconTrash } from "@tabler/icons";
import React from "react";

// utils
import { imageLink, parseError } from "../../functions";

// types
import { Document as DocumentType } from "../../types/response";
import { deleteDocument } from "api/documents";


export default function ImageBox(
    {
        data,
        onClick,
        onDelete,
        selected,
        ...props
    }: {
        data: DocumentType,
        onClick?: (data: DocumentType) => void,
        onDelete?: (data: DocumentType) => void,
        selected?: boolean,
        [key: string]: any
    }
) {

    const [deleting, setDeleting] = React.useState<boolean>(false);
    const theme = useMantineTheme();

    return (
        <Box sx={{ width: 200, '&:hover .remove': { display: 'flex' } }} ml={'md'} mt={'md'} {...props} onClick={() => onClick && onClick(data)}>
            <Card
                withBorder
                m={0}
                sx={
                    selected ? {
                        border: `2px solid ${theme.colors.blue[6]}`,
                    } : {}
                }
            >
                <Card.Section
                    sx={{
                        cursor: 'pointer',
                        border: data.public ? `none` : `4px solid ${theme.colors.red[6]}`,
                    }}
                >
                    <Grid>
                        <Grid.Col span={12} pt={0}>
                            {
                                ['jpg', 'jpeg', 'png'].filter(t => t === data.filename.split('.').pop()).length ? (
                                    <Image src={imageLink(data.path)} alt={data.name} width={'100%'} height={200} />
                                ) : (
                                    <Box
                                        sx={{
                                            width: '100%',
                                            height: 200,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <IconFileText size={60} />
                                    </Box>
                                )
                            }
                        </Grid.Col>
                        <Grid.Col span={12} mt={-5} px={'sm'}>
                            <Text size={'xs'} align={'center'}>{data.name}</Text>
                        </Grid.Col>
                    </Grid>
                </Card.Section>
                <ActionIcon
                    disabled={deleting}
                    className="remove"
                    sx={{
                        position: 'absolute',
                        top: 5,
                        right: 5,
                        display: 'none',
                    }}
                    variant={'filled'}
                    radius={'xl'}
                    color={'red'}
                    onClick={() => {
                        setDeleting(true);
                        showNotification({
                            id: data.id,
                            title: 'يرجى الانتظار',
                            message: 'جاري حذف الملف',
                            color: 'gray',
                            loading: true,
                        })
                        deleteDocument(data.id).then(() => {
                            showNotification({
                                id: data.id,
                                title: 'تم الحذف',
                                message: 'تم حذف الملف بنجاح',
                                color: 'teal',
                            })
                            setDeleting(false);
                            onDelete && onDelete(data);
                        }).catch((err) => {
                            showNotification({
                                id: data.id,
                                title: 'فشل الحذف',
                                message: parseError(err),
                                color: 'red',
                            })
                            setDeleting(false);
                        }).finally(() => {
                            setDeleting(false);
                        });


                    }}
                >
                    <IconTrash size={15} />
                </ActionIcon>
            </Card>
        </Box>
    )
}