// vendors
import { useState } from "react";
import {
    Button,
    Drawer, Grid, Select,
} from "@mantine/core"
import { useTranslation } from "react-i18next";
import { IconBath, IconBed, IconBooks, IconBrandVercel, IconEyeCheck } from "@tabler/icons";

export default function FilterDrawer(props: {
    loading: boolean,
    opened: boolean,
    onClose: () => void,
    onApply?: (filter: { [key: string]: string }) => void,
}) {

    const { t } = useTranslation();
    const [filter, setFilter] = useState<{ key: string, value: string }[]>([]);

    const addFilter = (key: string, value: any) => {
        setFilter([...filter.filter(f => f.key !== key), { key, value }]);
    }

    const removeFilter = (key: string) => {
        setFilter(filter.filter(f => f.key !== key));
    }

    const { loading, ...drawerProps } = props;

    return (
        <Drawer
            position="left"
            padding="xl"
            size={300}
            withOverlay={false}
            {...drawerProps}
            onClose={() => {
                drawerProps.onClose();
            }}
            title={t("Filter")}
        >
            <Grid>
                <Grid.Col span={12}>
                    <Select
                        icon={<IconBrandVercel size={16} />}
                        size="xs"
                        label={t("Status")}
                        data={[
                            { label: t("All"), value: "all" },
                            { value: 'listed', label: t("Listed") },
                            { value: 'sold', label: t("Sold") },
                            { value: 'reserved', label: t("Reserved") },
                        ]}
                        defaultValue="all"
                        onChange={(value) => {
                            if (value === "all") removeFilter("status");
                            else addFilter("status", value);
                        }}
                    />
                </Grid.Col>
                <Grid.Col span={12}>
                    <Select
                        icon={<IconEyeCheck size={16} />}
                        size="xs"
                        label={t("Status")}
                        data={[
                            { label: t("All"), value: "all" },
                            { value: 'approved', label: t("Approved") },
                            { value: 'refused', label: t("Rejected") },
                            { value: 'pending', label: t("Pending") },
                        ]}
                        defaultValue="all"
                        onChange={(value) => {
                            if (value === "all") removeFilter("publish_state");
                            else addFilter("publish_state", value);
                        }}
                    />
                </Grid.Col>
                <Grid.Col span={12}>
                    <Select
                        icon={<IconEyeCheck size={16} />}
                        size="xs"
                        label={t("Published")}
                        data={[
                            { label: t("All"), value: "all" },
                            { value: '1', label: t("Published") },
                            { value: '0', label: t("Unpublished") },
                        ]}
                        defaultValue="all"
                        onChange={(value) => {
                            if (value === "all") removeFilter("public");
                            else addFilter("public", value);
                        }}
                    />
                </Grid.Col>
                <Grid.Col span={12}>
                    <Select
                        icon={<IconBed size={16} />}
                        size="xs"
                        label={t("Rooms")}
                        data={[
                            { label: t("All"), value: "all" },
                            { value: '0', label: '0' },
                            { value: '1', label: '1' },
                            { value: '2', label: '2' },
                            { value: '3', label: '3' },
                            { value: '4', label: '4' },
                            { value: '5', label: '5' },
                            { value: '6', label: '6' },
                            { value: '7', label: '7' },
                            { value: '8', label: '8' },
                            { value: '9', label: '9' },
                            { value: '10', label: '10' },
                        ]}
                        defaultValue="all"
                        onChange={(value) => {
                            if (value === "all") removeFilter("rooms");
                            else addFilter("rooms", value);
                        }}
                    />
                </Grid.Col>
                <Grid.Col span={12}>
                    <Select
                        icon={<IconBath size={16} />}
                        size="xs"
                        label={t("Bathrooms")}
                        data={[
                            { label: t("All"), value: "all" },
                            { value: '0', label: '0' },
                            { value: '1', label: '1' },
                            { value: '2', label: '2' },
                            { value: '3', label: '3' },
                            { value: '4', label: '4' },
                            { value: '5', label: '5' },
                            { value: '6', label: '6' },
                            { value: '7', label: '7' },
                            { value: '8', label: '8' },
                            { value: '9', label: '9' },
                            { value: '10', label: '10' },
                        ]}
                        defaultValue="all"
                        onChange={(value) => {
                            if (value === "all") removeFilter("bathrooms");
                            else addFilter("bathrooms", value);
                        }}
                    />
                </Grid.Col>
                <Grid.Col span={12}>
                    <Select
                        size="xs"
                        label={t("Furniture")}
                        icon={<IconBooks size={16} />}
                        data={[
                            { label: t("All"), value: "all" },
                            { label: t("Furnished"), value: '1' },
                            { label: t("Unfurnished"), value: '0' },
                        ]}
                        defaultValue="all"
                        onChange={(value) => {
                            if (value === "all") removeFilter("furniture");
                            else addFilter("furniture", value);
                        }}
                    />
                </Grid.Col>
                <Grid.Col span={12}>
                    <Select
                        size="xs"
                        label={t("Property ownership")}
                        data={[
                            { label: t("All"), value: "all" },
                            { value: 'rent', label: t("Rent") },
                            { value: 'sale', label: t("Sell") },
                        ]}
                        defaultValue="all"
                        onChange={(value) => {
                            if (value === "all") removeFilter("property_ownership");
                            else addFilter("property_ownership", value);
                        }}
                    />
                </Grid.Col>
                <Grid.Col span={12}>
                    <Button
                        loading={loading}
                        size="xs"
                        onClick={() => {
                            drawerProps.onApply && drawerProps.onApply(
                                filter.reduce((acc: { [key: string]: string }, f) => {
                                    acc[f.key] = f.value;
                                    return acc;
                                }, {})
                            );
                        }}
                    >
                        {t("Apply")}
                    </Button>
                </Grid.Col>
            </Grid>
        </Drawer>
    )

}