import { User as UserType } from '../../types/response';

// Actions
export const setUser = (user: UserType) => ({
    type: 'SET_USER',
    payload: user,
})

// Reducer
const User = (state: {
    data: any,
    loaded: boolean,
} = {
        data: {},
        loaded: false,
    }, action: any) => {
    switch (action.type) {
        case 'SET_USER':
            return {
                ...state,
                data: action.payload,
                losded: true,
            };

        default:
            return state;
    }
}

export default User;
