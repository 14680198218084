import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { useHasPermission } from '../../../../hooks';
import NoPermisionPage from '../../../../components/NoPermisionPage';

export { default as Map } from './Map';
export { default as Properties } from './Properties';
export { default as Projects } from './Projects';


export default function Sales() {

    const permissionGranted = useHasPermission(["properties.read"]);

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {

        const paths = location.pathname.split('/').filter((item) => item !== '');

        if ( paths[paths.length-1] === 'sales' ) {
            navigate('/app/sales/map');
        }
    }, []);

    if (!permissionGranted) {
        return <NoPermisionPage/>;
    }

    return <Outlet/>;

}