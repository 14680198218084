import dayjs from "dayjs";
import { apiWithInterceptor } from ".";

// get
export const getAds = (page :number = 1, limit: number = -1 ) => apiWithInterceptor.get(`/advertisement/`, { params: {page, limit} })

// post
export const createAd = (data: any) => apiWithInterceptor.post(`/advertisement/`, {...data, expires_at: dayjs(data.expires_at).format('YYYY-MM-DD HH:mm:ss')})

// // delete
export const deleteAd = (id: string) => apiWithInterceptor.delete(`/advertisement/${id}`, { params: {approved: true} })

// // put
export const updateAd = (id: string, data: any) => apiWithInterceptor.put(`/advertisement/${id}`, {...data, expires_at: dayjs(data.expires_at).format('YYYY-MM-DD HH:mm:ss')})