// Vendors
import React from 'react';
import { Carousel } from '@mantine/carousel';
import { useViewportSize } from '@mantine/hooks';
import { Badge, BackgroundImage, Box, Grid, Title, useMantineTheme, Text, ScrollArea } from '@mantine/core';
import dayjs from 'dayjs';

// utils

// types
import { Area as PropertyType, Document, Area } from '../../../../../types/response';
import { imageLink } from '../../../../../functions';
import { IconPhotoOff } from '@tabler/icons';

const Slide = function ({
    data,
    onClick,
}: {
    data: PropertyType
    onClick: (area: Area) => void
}) {

    const theme = useMantineTheme();

    return (
        <Box
            sx={{
                background: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
                height: '100%',
                cursor: 'pointer',
                boxShadow: theme.shadows.xl,
                // minWidth: '300px',
            }}
            onClick={() => {
                // navigate(`/app/sales/map/${data.id}`);
                onClick(data);
            }}
        >
            <Grid sx={{ height: '100%' }} >
                <Grid.Col span={4} p={0} >
                    {
                        (data.documents[0] as Document) ? (
                            <BackgroundImage
                                sx={{
                                    height: '100%',
                                    width: '100%',
                                }}
                                src={imageLink((data.documents[0] as Document)?.path) || "#"}
                            />
                        ) : (
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '100%',
                                    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[2],
                                }}
                            >
                                <IconPhotoOff size={30} />
                            </Box>
                        )
                    }
                </Grid.Col>
                <Grid.Col span={8} p={'md'} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }} >
                    <Text size={'xl'} sx={{ fontWeight: 'bolder' }} color="blue">
                        {`${data.price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} IQD
                    </Text>
                    <Box>
                        <Badge radius={'xs'}>
                            {dayjs(data.created_at).format('D MMM YYYY')}
                        </Badge>
                        <Title order={4}>
                            {
                                data.name
                            }
                        </Title>
                        <ScrollArea sx={{ height: '80px' }}>
                            <Text size={'xs'} mt='xs'>
                                {
                                    data.description
                                }
                            </Text>
                        </ScrollArea>
                    </Box>
                </Grid.Col>
            </Grid>
        </Box>
    )
}

export default function ExploreAreas(
    {
        data,
        onClick,
        ...props
    }: {
        data: PropertyType[],
        onClick: (area: Area) => void,
        [key: string]: any
    }
) {

    const { width } = useViewportSize();
    const theme = useMantineTheme();

    return (
        <Box
            // px={'md'}
            sx={{
                position: 'absolute',
                width: `${width > 769 ? width - 300 : width}px`,
                bottom: 0,
            }}
        >
            <Box {...props}>
                {
                    data.length && (
                        <Carousel
                            // mx={'md'}
                            // mb={'xl'}
                            slideGap="xl"
                            dragFree
                            // withIndicators
                            height={200}
                            // slideSize={(width < 1220 && width > 900) ? '50%' : (width < 900 ? '100%' : '33.333333%')}
                            slideSize={'50%'}
                            loop
                        // align="start"
                        >
                            {
                                data.map((item, index) => (
                                    <Carousel.Slide key={index}>
                                        <Slide data={item} onClick={onClick} />
                                    </Carousel.Slide>
                                ))
                            }
                        </Carousel>
                    )
                }
            </Box>
        </Box>
    );
}