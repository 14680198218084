// vendors
import { useState } from 'react';
import { Drawer } from "@mantine/core"
import { showNotification, updateNotification } from '@mantine/notifications';
import { useForm } from "@mantine/form";
import { IconSend, IconAlertCircle, } from '@tabler/icons';

// api
import { createContract } from "../../../../api/contract";

// utils
import DrawerTitle from "../../../../components/DrawerTitle";
import { parseError, randomString } from "../../../../functions";
import { t } from 'i18next';
import FormFields from './components/FormFields';

export default function AddUser(props: any) {

    const [loading, setLoading] = useState(false);

    const form = useForm({
        initialValues: {
            "subject": "",
            "body": "",
            "issued_at": new Date(),
            "signed_at": new Date(),
            "documents": [],
            "price": '',
            "price_literal": "",
            "contract_type": "",
            "custom_id": "",
            "customer": "",
            "property": ""
        },
    });
    const handleSubmit = (values: any) => {
        const id = `contract_${randomString(10)}`;
        setLoading(true);
        showNotification({
            id: id,
            title: t("Creating"),
            message: t("Please wait..."),
            loading: true,
            autoClose: false,
            disallowClose: true,
        });
        createContract({ ...values, custom_id: id }).then(res => {
            updateNotification({
                id: id,
                title: t("Success"),
                message: t("Contract created successfully"),
                color: "green",
                icon: <IconSend />,
                autoClose: true,
            });
            form.reset();
            props?.refreshData();
            props?.onClose();
        }).catch(err => {
            updateNotification({
                id: id,
                title: t("Error while creating"),
                message: parseError(err),
                color: "red",
                autoClose: false,
                icon: <IconAlertCircle />,
            });
        }).finally(() => {
            setLoading(false);
        });
    }
    return (
        <Drawer
            position="right"
            padding="xl"
            size="xl"
            {...props}
            onClose={() => {
                props.onClose();
                form.reset();
            }}
        >
            <DrawerTitle>
                {t('Add')}
            </DrawerTitle>
            <FormFields
                form={form}
                data={props?.data}
                loading={loading}
                onSubmit={handleSubmit}
                isEdit={false}
                t={t}
            />
        </Drawer>
    )

}