// EditDrawer.tsx
import { useState, useEffect } from 'react';
import { Drawer, LoadingOverlay } from "@mantine/core";
import { showNotification, updateNotification } from '@mantine/notifications';
import { useForm } from "@mantine/form";
import { IconSend, IconAlertCircle } from '@tabler/icons';
import dayjs from "dayjs";
import { useTranslation } from 'react-i18next';
import DrawerTitle from "../../../../../components/DrawerTitle";
import { parseError, randomString } from "../../../../../functions";
import { updateUser, getPermissions, deleteUser } from "../../../../../api/user";
import CommonMenu from '../shared/components/EditMenu';
import FormFields from './components/FormFields';

export default function EditUser(props: any) {
    const [permissions, setPermissions] = useState([]);
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const form = useForm();

    useEffect(() => {
        getPermissions().then((res) => {
            setPermissions(res?.data?.permissions);
        }).catch((err) => {
            showNotification({
                title: "Error",
                message: "Error while fetching permissions",
                color: "red",
                icon: <IconAlertCircle />,
            });
        });
    }, []);

    useEffect(() => {
        if (!props.data) {
            form.reset();
        }
    }, [props.data]);
    const handleSubmit = (values: any) => {
        if (values.birthdate) values.birthdate = dayjs(values.birthdate as string).format('YYYY-MM-DD');
        if (values.employment_date) values.employment_date = dayjs(values.employment_date as string).format('YYYY-MM-DD');
        const id = `user_${randomString(10)}`;
        setLoading(true);
        showNotification({
            id: id,
            title: t("Updating"),
            message: t("Please wait..."),
            loading: true,
            autoClose: false,
            disallowClose: true,
        });
        updateUser(props?.data?.id, values).then(res => {
            updateNotification({
                id: id,
                title: t("Success"),
                message: t("Updated successfully"),
                color: "green",
                icon: <IconSend />,
                autoClose: true,
            });
            form.reset();
            props?.refreshData();
            props?.onClose();
        }).catch(err => {
            updateNotification({
                id: id,
                title: t("Error while updating"),
                message: parseError(err),
                color: "red",
                autoClose: false,
                icon: <IconAlertCircle />,
            });
        }).finally(() => setLoading(false));
    }
    return (
        <Drawer
            position="right"
            padding="xl"
            size={800}
            {...props}
            onClose={() => {
                props.onClose();
                form.reset();
            }}
        >
            <DrawerTitle>
                {t("Edit")}
            </DrawerTitle>
            <CommonMenu
                onDelete={() => deleteUser(props.data.id)}
                buttonLabel="خيارات"
                deleteLabel="حذف"
                refreshData={props.refreshData}
                onClose={props.onClose}
            />

            <LoadingOverlay visible={loading} overlayBlur={2} />
            <FormFields
                form={form}
                props={props}
                permissions={permissions}
                isEdit={true}
                loading={loading}
                onSubmit={handleSubmit}
                t={t}
            />

        </Drawer>
    );
}
