import { Title } from "@mantine/core";

export default function DrawerTitle(props) {

    return (
        <Title
            order={1}
            sx={{
                fontWeight: 'lighter',
                fontSize: '1.5rem',
                ...props.sx,
            }}
            {...props}
        >
            {props.children}
        </Title>
    )
}