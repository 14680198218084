import { Button } from '@mantine/core';
import { useTranslation } from 'react-i18next';

export function PrintButton() {
  const { t } = useTranslation();

  return (
    <Button
      my={20}
      style={{ minWidth: '50%' }}
      size="lg"
      onClick={() => {
        setTimeout(() => {
          window.print();
        }, 0);
      }}
    >
      {t('Print')}
    </Button>
  );
}
