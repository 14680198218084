// vendors
import React, { useState } from 'react';
import {
    Drawer,
    Grid,
    TextInput,
    NumberInput,
    Button,
    Textarea,
    Text,
    Select,
    LoadingOverlay,
    ScrollArea,
} from "@mantine/core"
import { showNotification, updateNotification } from '@mantine/notifications';
import { DatePicker } from '@mantine/dates';
import { useForm } from "@mantine/form";
import {
    IconSend,
    IconX,
} from '@tabler/icons';

// utils
import DrawerTitle from "../../../../../components/DrawerTitle";

// api
import { createBankInvoice, createCashInvoice } from '../../../../../api/invoice';
import { parseError, randomString } from '../../../../../functions';
import { useSetState } from '@mantine/hooks';

// types 
import { useTranslation } from 'react-i18next';
import MediaLibraryField from '../../../../../components/MediaLibraryField';
import tafqeet from 'inc/tafqeet';
import CustomersSelectField from 'components/CustomersSelectField';
import dayjs from 'dayjs';

export default function AddData(props: {
    opened: boolean,
    onClose: () => void,
    refreshData: () => void,
    type: "cash-in" | "cash-out" | "bank-in" | "bank-out",
}) {

    const form = useForm({
        initialValues: {
            amount: '',
            subject: "",
            issued_at: new Date(),
            documents: [],
            description: "",
            custom_id: "",
            from_to: "",
            invoice_type: "",
        },
    });

    const type = props.type.split('-')[0];
    const direction = props.type.split('-')[1];
    const [loading, setLoading] = React.useState(false);
    const [selectedDate, setSelectedDate] = useState<Date>(new Date());

    const { t } = useTranslation();

    const [mediaLibraryState, setMediaLibraryState] = useSetState({
        opened: false,
        onClose: () => setMediaLibraryState({ opened: false }),
        multiple: true,
    });

    return (
        <Drawer
            position="right"
            padding="xl"
            size={'xl'}
            {...props}
            onClose={() => {
                props.onClose();
                form.reset();
            }}
        >
            <DrawerTitle>
                {type === 'bank' ? t('Bank Invoice') : t('Cash Invoice')}
            </DrawerTitle>
            <ScrollArea
                offsetScrollbars
                style={{ height: 'calc(100vh - 117px)' }}
            >
                <form
                    onSubmit={form.onSubmit((values) => {
                        setLoading(true);
                        const id = randomString(10);
                        showNotification({
                            id,
                            title: t('Creating'),
                            message: t('Please wait...'),
                            color: 'blue',
                            loading: true,
                            autoClose: false,
                        })
                        if (type === 'bank') {
                            createBankInvoice({ ...values, custom_id: id, direction }).then(() => {
                                updateNotification({
                                    id,
                                    title: t('Success'),
                                    message: t('Invoice created'),
                                    color: 'teal',
                                    icon: <IconSend />,
                                    loading: false,
                                });
                                form.reset();
                                props.onClose();
                                props.refreshData()
                            }).catch((err) => {
                                updateNotification({
                                    id,
                                    title: 'Error',
                                    message: parseError(err),
                                    color: 'red',
                                    icon: <IconX />,
                                    loading: false,
                                });
                            }).finally(() => {
                                setLoading(false);
                            })
                        } else {
                            createCashInvoice({ ...values, custom_id: id, direction }).then(() => {
                                updateNotification({
                                    id,
                                    title: t('Success'),
                                    message: t('Invoice created'),
                                    color: 'teal',
                                    icon: <IconSend />,
                                    loading: false,
                                });
                                props.onClose();
                                props.refreshData()
                            }).catch((err) => {
                                updateNotification({
                                    id,
                                    title: 'Error',
                                    message: parseError(err),
                                    color: 'red',
                                    icon: <IconX />,
                                    loading: false,
                                });
                            }).finally(() => {
                                setLoading(false);
                            })
                        }
                    })}
                >
                    <LoadingOverlay visible={loading} overlayBlur={2} />
                    <Grid gutter="lg" mt={20}>
                        <Grid.Col span={12}>
                            <TextInput
                                required
                                size="xs"
                                label={t('Subject')}
                                placeholder={t('Subject')}
                                {...form.getInputProps('subject')}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <CustomersSelectField
                                size="xs"
                                label={t("الزبون")}
                                placeholder={t("الزبون")}
                                onSelect={(customer) => {
                                    // form.setFieldValue('from_to', customer.firstname + ' ' + customer.lastname);
                                    if (customer.is_investor) {
                                        form.setFieldValue('invoice_type', 'investment');
                                    } else {
                                        form.setFieldValue('invoice_type', 'sales');
                                    }
                                }}
                                {...form.getInputProps('customer')}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <TextInput
                                required
                                size="xs"
                                label={t('From to')}
                                placeholder={t('From to')}
                                {...form.getInputProps('from_to')}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <Textarea
                                required
                                size="xs"
                                label={t('Description')}
                                placeholder={t('Description')}
                                {...form.getInputProps('description')}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <NumberInput
                                required
                                size="xs"
                                label={t('Amount')}
                                icon={(<Text size='xs'>IQD</Text>)}
                                placeholder={t('Amount')}
                                // {...form.getInputProps('amount')}
                                parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                                onChange={(val: any) => {
                                    form.setFieldValue('amount', val);
                                    form.setFieldValue('amount_literal', tafqeet(val));
                                }}
                                formatter={(value: any) =>
                                    !Number.isNaN(parseFloat(value))
                                        ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                        : ''
                                }
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <TextInput
                                size="xs"
                                label={t("Amount Letiral")}
                                placeholder={t("Amount Letiral")}
                                {...form.getInputProps('amount_literal')}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <DatePicker
                                locale='ar'
                                size="xs"
                                label={t("Issue Date")}
                                placeholder={t("Issue Date")}
                                value={selectedDate}
                                onChange={(date: any) => {
                                    setSelectedDate(date)
                                    form.setFieldValue('issued_at', date);
                                }}
                                dayStyle={(date) => {
                                    const isCurrentDay = dayjs().isSame(date, 'day');
                                    const isSelectedDay = selectedDate && dayjs(selectedDate).isSame(date, 'day');

                                    if (isSelectedDay) {
                                        return {
                                            backgroundColor: '#007bff',
                                            color: 'white',
                                            borderRadius: '4px',
                                            padding: '3px',
                                            border: '2px solid #007bff',
                                        };
                                    }

                                    if (!selectedDate && isCurrentDay) {
                                        return {
                                            backgroundColor: '#007bff',
                                            color: 'white',
                                            borderRadius: '4px',
                                            padding: '3px',
                                        };
                                    }

                                    return {};
                                }}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <Select
                                size="xs"
                                label={t("نوع الفاتورة")}
                                placeholder={t("نوع الفاتورة")}
                                data={[
                                    { label: t("فاتورة مبيعات"), value: "sales" },
                                    { label: t("فاتورة استثمار"), value: "investment" },
                                ]}
                                {...form.getInputProps('invoice_type')}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <MediaLibraryField
                                inputProps={{
                                    withAsterisk: true,
                                    size: "xs",
                                    label: t("Documents"),
                                    placeholder: t("Select documents"),
                                }}
                                multiple={true}
                                {...form.getInputProps('documents')}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <Button
                                size="xs"
                                leftIcon={<IconSend size={16} />}
                                type="submit"
                            >
                                {
                                    t('Create')
                                }
                            </Button>
                        </Grid.Col>
                    </Grid>
                </form>
            </ScrollArea>
        </Drawer>
    )

}