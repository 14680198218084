// vendors
import React from 'react';
import {
    Grid,
    TextInput,
    Button,
    Select,
    LoadingOverlay,
    Textarea,
    ScrollArea,
} from "@mantine/core"
import { DatePicker } from '@mantine/dates';
import {
    IconSend,
    IconPrinter

} from '@tabler/icons';

// api
import MediaLibraryField from '../../../../../components/MediaLibraryField';
import { useNavigate } from 'react-router-dom';

interface FormFieldsProps {
    form: any;
    data?: any;
    loading: boolean;
    onSubmit: (values: any) => void;
    isEdit: boolean;
    t: (key: string) => string;
}

const FormFields: React.FC<FormFieldsProps> = ({ form, data, loading, onSubmit, isEdit, t }) => {
    const navigate = useNavigate();
    return (
        <form onSubmit={form.onSubmit(onSubmit)}>
            <ScrollArea
                style={{ height: "calc(100vh - 210px)" }}
                offsetScrollbars={true}
            >

                <Grid gutter="lg" mt={20}>
                    <LoadingOverlay visible={loading} overlayBlur={2} />
                    <Grid.Col span={12}>
                        <TextInput
                            size="xs"
                            label={t("Subject")}
                            placeholder={t("Subject")}
                            defaultValue={data?.subject}
                            {...form.getInputProps('subject')}
                        />
                    </Grid.Col>
                    {/* <Grid.Col span={12}>
                            <TextInput
                                withAsterisk
                                required
                                size="xs"
                                label={t("العدد")}
                                placeholder={t("العدد")}
                                {...form.getInputProps('custom_id')}
                            />
                        </Grid.Col> */}
                    <Grid.Col span={12}>
                        <Textarea
                            size="xs"
                            label={t("Sender")}
                            placeholder={t("Sender")}
                            defaultValue={data?.sender}
                            {...form.getInputProps('sender')}
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <TextInput
                            size="xs"
                            label={t("Receiver")}
                            placeholder={t("Receiver")}
                            defaultValue={data?.receiver}
                            {...form.getInputProps('receiver')}
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <Textarea
                            size="xs"
                            autosize
                            label={t("Body")}
                            placeholder={t("Body")}
                            defaultValue={data?.body}
                            {...form.getInputProps('body')}
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <Textarea
                            size="xs"
                            autosize
                            label={t("المرفقات")}
                            placeholder={t("المرفقات")}
                            defaultValue={data?.description}
                            {...form.getInputProps('description')}
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <DatePicker
                            locale='ar'
                            size="xs"
                            label={t("Issue Date")}
                            placeholder={t("Issue Date")}
                            defaultValue={data?.issued_at ? new Date(data.issued_at) : null}
                            {...form.getInputProps('issued_at')}
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <Select
                            size="xs"
                            label={t("Direction")}
                            placeholder={t("Direction")}
                            data={[
                                { label: t("In"), value: "in" },
                                { label: t("Out"), value: "out" },
                            ]}
                            defaultValue={data?.direction}
                            {...form.getInputProps('direction')}
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <MediaLibraryField
                            inputProps={{
                                withAsterisk: true,
                                size: "xs",
                                label: t("Documents"),
                                placeholder: t("Select documents"),
                            }}
                            multiple={true}
                            defaultValue={data?.documents}
                            {...form.getInputProps('documents')}
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <Button
                            size="xs"
                            leftIcon={<IconSend size={16} />}
                            type="submit"
                        >
                            {isEdit ?
                                t("Save")
                                : t("Create")}
                        </Button>
                        {isEdit ?
                            <Button
                                size="xs"
                                mx={'xs'}
                                leftIcon={<IconPrinter size={16} />}
                                onClick={() => {
                                    navigate(`/app/letters/print`, {
                                        state: data,
                                    });
                                }}
                            >
                                {t("Print")}
                            </Button>
                            : null}

                    </Grid.Col>
                </Grid>
            </ScrollArea>

        </form>
    );
};

export default FormFields;
