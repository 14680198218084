import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";

import { useHasPermission } from '../../../../hooks';
import NoPermisionPage from '../../../../components/NoPermisionPage';

export { default as Employees } from './Employees';
export { default as Customers } from './Customers';
export { default as Cities } from './Cities';

export default function Users() {

    const permissionGranted = useHasPermission(["admin_users.read", "customers.read"]);

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {

        const paths = location.pathname.split('/').filter((item) => item !== '');

        if ( paths[paths.length-1] === 'users' ) {
            navigate('/app/users/employees');
        }
    }, []);

    if (!permissionGranted) {
        return <NoPermisionPage/>;
    }

    return <Outlet/>;

}