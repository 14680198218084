// vendors
import React from 'react';
import { Drawer } from "@mantine/core"
import { showNotification, updateNotification } from '@mantine/notifications';
import { useForm } from "@mantine/form";
import { IconSend, IconX, } from '@tabler/icons';

// utils
import DrawerTitle from "../../../../../components/DrawerTitle";

// api
import { createInvoice, createBankInvoice, createCashInvoice } from '../../../../../api/invoice';
import { parseError, randomString } from '../../../../../functions';
import { useTranslation } from 'react-i18next';
import FormFields from '../components/FormFields';

export default function AddData(props: {
    opened: boolean,
    onClose: () => void,
    refreshData: () => void,
    type: "cash" | "bank",
}) {

    const form = useForm({
        initialValues: {
            amount: '',
            subject: "",
            issued_at: "",
            documents: {},
            description: "",
            custom_id: "",
            direction: "out",
            from_to: "",
            invoice_type: "investment",
        },
    });

    const [loading, setLoading] = React.useState(false);
    const { t, } = useTranslation();
    const handleSubmit = async (values: any) => {
        setLoading(true);
        const id = randomString(10);
        showNotification({
            id,
            title: t('Creating'),
            message: t('Please wait...'),
            color: 'blue',
            loading: true,
            autoClose: false,
        })
        if (props.type === 'bank') {
            createBankInvoice({ ...values, custom_id: id }).then(() => {
                updateNotification({
                    id,
                    title: t('Success'),
                    message: t('Invoice created'),
                    color: 'teal',
                    icon: <IconSend />,
                    loading: false,
                });
                form.reset();
                props.onClose();
                props.refreshData();
            }).catch((err) => {
                updateNotification({
                    id,
                    title: t('Error'),
                    message: parseError(err),
                    color: 'red',
                    icon: <IconX />,
                    loading: false,
                });
            }).finally(() => {
                setLoading(false);
            })
        } else {
            createCashInvoice({ ...values, custom_id: id }).then(() => {
                updateNotification({
                    id,
                    title: t('Success'),
                    message: t('Created successfully'),
                    color: 'teal',
                    icon: <IconSend />,
                    loading: false,
                });
                props.onClose();
                props.refreshData();
            }).catch((err) => {
                updateNotification({
                    id,
                    title: 'Error',
                    message: parseError(err),
                    color: 'red',
                    icon: <IconX />,
                    loading: false,
                });
            }).finally(() => {
                setLoading(false);
            })
        }
        createInvoice({ ...values, custom_id: id }).then(() => {
            updateNotification({
                id,
                title: t('Success'),
                message: t('Invoice created'),
                color: 'teal',
                icon: <IconSend />,
                loading: false,
            });
            form.reset();
            props.onClose();
            props.refreshData();
        }).catch((err) => {
            updateNotification({
                id,
                title: t('Error'),
                message: parseError(err),
                color: 'red',
                icon: <IconX />,
                loading: false,
            });
        }).finally(() => {
            setLoading(false);
        })
    }

    return (
        <Drawer
            position="right"
            padding="xl"
            size={'xl'}
            {...props}
        >
            <DrawerTitle>
                {props.type === 'bank' ? t('Bank Invoice') : t('Cash Invoice')}
            </DrawerTitle>
            <FormFields
                form={form}
                loading={loading}
                onSubmit={handleSubmit}
                isEdit={false}
                t={t}
            />
        </Drawer>
    )

}