// vendors/FormFields.tsx
import React from 'react';
import {
    Grid,
    TextInput,
    Textarea,
    Button,
} from '@mantine/core';
import { useTranslation } from 'react-i18next';
interface FormFieldsProps {
    form: any;
    onSubmit: (values: any) => void;
}


const FormFields: React.FC<FormFieldsProps> = ({ form, onSubmit }) => {
    const { t } = useTranslation();
    return (
        <form onSubmit={form.onSubmit(onSubmit)}>
            <Grid
                gutter={20}
            >
                <Grid.Col span={12}>
                    <TextInput
                        placeholder={t("عنوان الاشعار")}
                        label={t("عنوان الاشعار")}
                        required
                        style={{ width: '100%' }}
                        {...form.getInputProps('title')}
                    />
                </Grid.Col>
                <Grid.Col span={12}>
                    <TextInput
                        placeholder={t("رابط الاشعار")}
                        label={t("رابط الاشعار")}
                        required
                        style={{ width: '100%' }}
                        {...form.getInputProps('link')}
                    />
                </Grid.Col>
                <Grid.Col span={12}>
                    <Textarea
                        placeholder={t("نص الاشعار")}
                        label={t("نص الاشعار")}
                        required
                        style={{ width: '100%' }}
                        {...form.getInputProps('body')}
                    />
                </Grid.Col>
                <Grid.Col span={12}>
                    <Button
                        color="blue"
                        type="submit"
                    >
                        {t("ارسال")}
                    </Button>
                </Grid.Col>
            </Grid>

        </form>
    );
};

export default FormFields;