// vendors
import {
    Box,
    Button,
    Skeleton,
} from "@mantine/core";
import { useState, useEffect, useMemo } from "react";
import ReactFlow, { useNodesState, useEdgesState, Node } from 'react-flow-renderer';
import {
    IconAlertCircle,
    IconBuildingBank,
    IconCashBanknote,
    IconPlus,
} from '@tabler/icons';
import { useNavigate } from 'react-router-dom';
import { useSetState } from '@mantine/hooks';

// utils
import PageHeading from "../../../../../components/PageHeading";
import AddData from "./_AddDrawer";

import ActionNode from "../../../../../components/reactflow/ActionNode";
import MantineNode from "../../../../../components/reactflow/MantineNode";
import NumericNode from "../../../../../components/reactflow/NumericNode";

// api
import { getUserCompanyAsset } from "../../../../../api/assets";

// types
import { Asset as AssetType } from "../../../../../types/response";
import { showNotification } from '@mantine/notifications';
import { parseError } from '../../../../../functions';
import { useTranslation } from 'react-i18next';

const nodeTypes = {
    actionNode: ActionNode,
    mantineNode: MantineNode,
    numericNode: NumericNode,
};


export default function Users() {

    const navigate = useNavigate();
    const [asset, setAsset] = useState<AssetType | null>(null);

    const [addInvoiceDrawer, setAddInvoiceDrawer] = useSetState({
        open: false,
        type: '',
    });



    const closeDrawer = () => {
        setAddInvoiceDrawer({ open: false, type: '' });
    };

    const openDrawer = (type: any) => {
        setAddInvoiceDrawer({ open: true, type: type });
    };

    const initialNodes: any = useMemo(() => [
        {
            id: 'bank-in',
            type: 'actionNode',
            data: {
                label: 'Bank Receive Invoice',
                hideTarget: true,
                actionIcon: <IconPlus size={16} />,
                onClick: () => {
                    openDrawer('bank-in');
                },
            },
            position: { x: 50, y: 50 },
        },
        {
            id: 'bank',
            type: 'mantineNode',
            sourcePosition: 'bottom',
            data: {
                label: 'Bank',
                value: <Skeleton width={60} height={23} />,
                icon: <IconBuildingBank size={30} />,
                sx: {
                    minWidth: 175,
                }
            },
            position: { x: 150, y: 200 },
        },
        {
            id: 'bank-out',
            type: 'actionNode',
            data: {
                label: 'Bank Pay Invoice',
                hideSource: true,
                actionIcon: <IconPlus size={16} />,
                onClick: () => {
                    openDrawer('bank-out');
                },
            },
            position: { x: 50, y: 350 },
        },
        {
            id: 'cash-in',
            type: 'actionNode',
            data: {
                label: 'Cash Receive Invoice',
                hideTarget: true,
                actionIcon: <IconPlus size={16} />,
                onClick: () => {
                    openDrawer('cash-in');
                },
            },
            position: { x: 550, y: 50 },
        },
        {
            id: 'cash',
            type: 'mantineNode',
            data: {
                label: 'Cash',
                value: <Skeleton width={60} height={23} />,
                icon: <IconCashBanknote size={30} />,
                sx: {
                    minWidth: 175,
                }
            },
            position: { x: 400, y: 200 },
        },
        {
            id: 'cash-out',
            type: 'actionNode',
            sourcePosition: 'top',
            data: {
                label: 'Cash Pay Invoice',
                hideSource: true,
                actionIcon: <IconPlus size={16} />,
                onClick: () => {
                    openDrawer('cash-out');
                },
            },
            position: { x: 550, y: 350 },
        },
        {
            id: 'available',
            type: 'numericNode',
            data: {
                label: 'Available',
                value: <Skeleton width={60} height={23} />,
                hideSource: true,
                sx: {
                    minWidth: 250,
                }
            },
            position: { x: 225, y: 450 },
        },
    ], [asset]);

    const initialEdges: any = useMemo(() => [
        {
            id: 'bank-in',
            source: 'bank-in',
            target: 'bank',
            animated: true,
            style: { stroke: 'green' },
        },
        {
            id: 'bank-out',
            source: 'bank',
            target: 'bank-out',
            animated: true,
            style: { stroke: 'red' },
        },
        {
            id: 'cash-in',
            source: 'cash-in',
            target: 'cash',
            animated: true,
            style: { stroke: 'green' },
        },
        {
            id: 'cash-out',
            source: 'cash',
            target: 'cash-out',
            animated: true,
            style: { stroke: 'red' },
        },
        {
            id: 'available-bank',
            source: 'bank',
            target: 'available',
            animated: true,
            style: { stroke: 'orange' },
        },
        {
            id: 'available-cash',
            source: 'cash',
            target: 'available',
            animated: true,
            style: { stroke: 'orange' },
        },
    ], []);

    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

    const loadData = () => {
        getUserCompanyAsset().then(({ data }: { data: AssetType }) => {
            setAsset(data);
        }).catch(err => {
            console.error(err);
            showNotification({
                title: 'Error',
                message: parseError(err),
                color: 'red',
                icon: <IconAlertCircle />,
            })
        });
    }

    useEffect(() => {
        loadData();
    }, []);

    const refreshData = () => loadData();

    useEffect(() => {
        setNodes(
            nodes.map((node: Node) => {
                if (node.id === 'cash' && asset) {
                    return {
                        ...node,
                        data: {
                            ...node.data,
                            value: `${asset.cash_balance}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' ' + asset.currency,
                        },
                    }
                } else if (node.id === 'bank' && asset) {
                    return {
                        ...node,
                        data: {
                            ...node.data,
                            value: `${asset.bank_balance}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' ' + asset.currency,
                        },
                    }
                } else if (node.id === 'available' && asset) {
                    return {
                        ...node,
                        data: {
                            ...node.data,
                            value: `${Number(asset.bank_balance) + Number(asset.cash_balance)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' ' + asset.currency,
                        },
                    }
                }
                return node
            })
        )
    }, [asset]);

    const { t } = useTranslation();

    return (
        <>
            <PageHeading>
                {t("Finance")}
            </PageHeading>
            <Button
                mx={20}
                mb={20}
                onClick={() => navigate('/app/finance/invoices')}
            >
                {t("See all invoices")}
            </Button>
            {/* <TextInput
                px={20}
                pb={20}
                placeholder="Search"
                value={search}
                onChange={e => setSearch(e.target.value)}
            /> */}
            <Box
                sx={{
                    padding: '0 20px',
                    width: '100%',
                    height: 'calc(100vh - 151.594px)',
                    float: 'right',
                }}
            >
                <ReactFlow
                    style={{
                        direction: 'ltr',
                    }}
                    nodes={nodes}
                    edges={edges}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    defaultZoom={1.2}
                    minZoom={0.2}
                    maxZoom={4}
                    nodeTypes={nodeTypes}
                    attributionPosition="bottom-right"
                >
                    {/* <Background variant="dots" gap={12} size={0.3} /> */}
                </ReactFlow>
                <AddData
                    opened={addInvoiceDrawer.open}
                    onClose={() => closeDrawer()}
                    type={addInvoiceDrawer.type as 'cash-in' | 'cash-out' | 'bank-in' | 'bank-out'}
                    refreshData={refreshData}
                />
                {/* <Table
                    header={[
                        { key: 'name', label: 'Name', value: (account) => account.firstname + account.lastname },
                        { key: 'job_title', label: 'Title' },
                        { key: 'department', label: 'Department' },
                        { key: 'employment_date', label: 'Employment Date', value: (account) => account.employment_date ? dayjs(account.employment_date).format('MMMM D, YYYY h:mm A') : '' },
                    ]}
                    body={users.accounts}
                    search={search}
                    sx={{
                        cursor: 'pointer',
                    }}
                    onRowClick={(row) => {
                        setEditUser(row);
                    }}
                /> */}
            </Box>
        </>
    );
}