import { Box, Image } from "@mantine/core";
import Logo from "../assets/logo/logo-title-bottom.svg";

export default function NoPermisionPage() {

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                width: '100%',
            }}
        >
            <Image src={Logo} alt="Logo" width={300} />
        </Box>
    );

}