import { DragEndEvent } from '@dnd-kit/core';
import { IFieldState, ReactStateSetter } from '../types/printingTypes/types';

export function convertToAbsolute(containerSize: number, containerOffset: number, percent: number) {
  return percent * containerSize - containerOffset;
}

export function handleDragEnd(
  ev: DragEndEvent,
  fields: IFieldState[],
  setFields: ReactStateSetter<IFieldState[]>,
  parentRef: React.RefObject<HTMLElement>
) {
  const updatedField = fields.find((x) => x.dragId === ev.active.id);
  if (!updatedField) throw new Error('Could not find matching field');

  updatedField.transform.x += ev.delta.x;
  updatedField.transform.y += ev.delta.y;
  updatedField.transform.percentX = (updatedField.transform.x - (parentRef.current?.offsetLeft ?? 0)) / (parentRef.current?.scrollWidth ?? 1);
  updatedField.transform.percentY = (updatedField.transform.y - (parentRef.current?.offsetTop ?? 0)) / (parentRef.current?.scrollHeight ?? 1);

  setFields((fields) => fields.map((field) => (field.dragId === updatedField.dragId ? updatedField : field)));
}
