import dayjs from "dayjs";
import { apiWithInterceptor } from ".";

// get
export const getActivity = (since: Date, until: Date, page :number = 1, limit: number = -1) => apiWithInterceptor.get(`/log/activity`, { params: {page, limit, since: dayjs(since).format('YYYY-MM-DD'), until: dayjs(until).format('YYYY-MM-DD')} })

// post
// export const createAd = (data: {}) => apiWithInterceptor.post(`/advertisement/`, {...data})

// // delete
// export const deleteAsset = (id: string) => apiWithInterceptor.delete(`/asset/${id}`, { params: {approved: true} })

// // put
// export const updateAd = (id: string, data: {}) => apiWithInterceptor.put(`/advertisement/${id}`, data)