// vendors
import React from 'react';
import {
    Grid,
    TextInput,
    Button,
    LoadingOverlay,
    Textarea,
    NumberInput,
    ScrollArea,
} from "@mantine/core"
import {
    IconSend,
    IconMapPin,
} from '@tabler/icons';

// api
import MediaLibraryField from '../../../../../../components/MediaLibraryField';
import CitySelectField from 'components/CitySelectField';

interface FormFieldsProps {
    form: any;
    data?: any;
    loading: boolean;
    onSubmit: (values: any) => void;
    isEdit: boolean;
    t: (key: string) => string;
}

const FormFields: React.FC<FormFieldsProps> = ({ form, data, loading, onSubmit, isEdit, t }) => {
    return (
        <form onSubmit={form.onSubmit(onSubmit)}>
            <ScrollArea
                offsetScrollbars
                style={{ height: 'calc(100vh - 205px)' }}
            >
                <Grid gutter="lg" mt={20}>
                    <LoadingOverlay visible={loading} overlayBlur={2} />
                    <Grid.Col span={12}>
                        <TextInput
                            required
                            size="xs"
                            label={t("Name")}
                            placeholder={t("Name")}
                            defaultValue={data?.name}
                            {...form.getInputProps("name")}
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <Textarea
                            size="xs"
                            label={t("Description")}
                            placeholder={t("Description")}
                            defaultValue={data?.description}
                            {...form.getInputProps("description")}
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <TextInput
                            size="xs"
                            label={t("Address")}
                            placeholder={t("Address")}
                            defaultValue={data?.address}
                            {...form.getInputProps("address")}
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <CitySelectField
                            size="xs"
                            label={t("City")}
                            icon={<IconMapPin size={16} />}
                            defaultValue={data?.city?.id}
                            {...form.getInputProps('city')}
                        />
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <NumberInput
                            size="xs"
                            label={t("Area Low")}
                            placeholder={t("Area Low")}
                            parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                            onChange={(val: any) => {
                                form.setFieldValue('price', val);
                            }}
                            formatter={(value: any) =>
                                !Number.isNaN(parseFloat(value))
                                    ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                    : ''
                            }
                            defaultValue={data?.area_low}
                            {...form.getInputProps("area_low")}
                        />
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <NumberInput
                            size="xs"
                            label={t("Area High")}
                            placeholder={t("Area High")}
                            defaultValue={data?.area_high}
                            parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                            onChange={(val: any) => {
                                form.setFieldValue('price', val);
                            }}
                            formatter={(value: any) =>
                                !Number.isNaN(parseFloat(value))
                                    ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                    : ''
                            }
                            {...form.getInputProps("area_high")}
                        />
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <NumberInput
                            size="xs"
                            label={t("Price Low")}
                            placeholder={t("Price Low")}
                            defaultValue={data?.price_low}
                            parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                            onChange={(val: any) => {
                                form.setFieldValue('price', val);
                            }}
                            formatter={(value: any) =>
                                !Number.isNaN(parseFloat(value))
                                    ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                    : ''
                            }
                            {...form.getInputProps("price_low")}
                        />
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <NumberInput
                            size="xs"
                            label={t("Price High")}
                            placeholder={t("Price High")}
                            defaultValue={data?.price_high}
                            parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                            onChange={(val: any) => {
                                form.setFieldValue('price', val);
                            }}
                            formatter={(value: any) =>
                                !Number.isNaN(parseFloat(value))
                                    ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                    : ''
                            }
                            {...form.getInputProps("price_high")}
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <TextInput
                            size="xs"
                            label={t("Email")}
                            placeholder={t("Email")}
                            defaultValue={data?.email}
                            {...form.getInputProps("email")}
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <TextInput
                            size="xs"
                            label={t("Website")}
                            placeholder={t("Website")}
                            defaultValue={data?.website}
                            {...form.getInputProps("website")}
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <TextInput
                            size="xs"
                            label={t("Phone")}
                            placeholder={t("Phone")}
                            defaultValue={data?.phone}
                            {...form.getInputProps("phone")}
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <MediaLibraryField
                            inputProps={{
                                withAsterisk: true,
                                size: "xs",
                                label: t("Image"),
                                placeholder: "Select file",
                            }}
                            multiple={false}
                            defaultValue={data?.documents}
                            {...form.getInputProps('documents')}
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <Button
                            size="xs"
                            leftIcon={<IconSend size={16} />}
                            type="submit"
                        >
                            {isEdit ? t("Save") : t("Create")}
                        </Button>
                    </Grid.Col>
                </Grid>
            </ScrollArea>
        </form>
    );
};

export default FormFields;
