import { TextInput, Container } from "@mantine/core";
import FormInvestment from "../../../../../assets/form-investment.png";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PrintHeader from '../../../../../components/PrintHeader';

// types

import { DndContext } from '@dnd-kit/core';
import { PrintButton } from '../../../../../components/PrintButton';
import { useDetectPrint } from '../../../../../hooks/useDetectPrint';
import { handleDragEnd, convertToAbsolute } from '../../../../../utilty/utils';
import { getDynamicFieldMetadata } from '../../../../../utilty/initalPosition';
import DraggableField from '../../../../../components/DraggableField';
import { useFormFields } from '../../../../../hooks/useFormFields';
import { IFieldMetadata } from 'types/printingTypes/types';
import { tafqeet } from "inc/tafqeet";

export default function PrintForm({ dataA, }: { dataA: any; }) {
  const location = useLocation();
  const { t } = useTranslation();
  const printContainerRef = useRef<HTMLDivElement>(null);
  const printContractRef = useRef<HTMLImageElement>(null);
  const [printContractLoaded, setPrintContractLoaded] = useState(false);
  const isPrinting = useDetectPrint();

  const data: any | null = location.state as any | null;
  const initialValues = {
    day: data?.purchase_date ? t(dayjs(data?.purchase_date).format('dddd')) : '',
    issued_at: data?.purchase_date ? dayjs(data?.purchase_date).format('YYYY / MM / DD') : '',
    investor_name: data?.investorName,
    investor_ratio: `${(data?.investors_ratio) * 100}% ${tafqeet(data?.investors_ratio * data?.purchase_price)}`,
    investor_phone: data?.investorPhone,
    company_ratio: `${Number(data?.company_ratio) * 100}% ${tafqeet(data?.company_ratio * data?.purchase_price)}`,
    investor_nationalId: data?.investorNationalId ? data?.investorNationalId : "",
    investor_address: data?.investorAddress ? data?.investorAddress : "",
    investor_name1: data?.investorName
  };
  const fieldMetadata: IFieldMetadata = getDynamicFieldMetadata(t, 'property');
  const { form, fields, setFields } = useFormFields(initialValues, fieldMetadata, 'default');
  if (form.values.investor_ratio) {
    form.values.investor_ratio = parseFloat(form.values.investor_ratio).toFixed(0) + "%";
  }

  return (
    <DndContext onDragEnd={(ev) => handleDragEnd(ev, fields, setFields, printContractRef)}>
      <Container
        m={0}
        px={0}
        pt={isPrinting ? 0 : 50}
        style={{ display: 'flex', boxSizing: 'border-box', alignItems: 'center', flexDirection: 'column', minHeight: '100vh', width: '100vw', maxWidth: '100%' }}>
        {!isPrinting && <PrintHeader data={t('Print Contract')} />}
        <div style={{ display: 'flex', justifyContent: 'center', flexGrow: 1 }}>
          <Container p={0} m={0} id="print-area" ref={printContainerRef} style={{ position: 'relative', width: '210mm', height: '297mm' }}>
            <img
              ref={printContractRef}
              onLoad={() => setPrintContractLoaded(true)}
              style={{ maxHeight: '100%', width: '100%', position: 'absolute', top: 0, right: 0 }}
              src={FormInvestment}
              alt=""
            />{printContractLoaded &&
              fields.map((field) => (
                <div
                  style={{
                    position: 'absolute',
                    transform: `translate3d(${convertToAbsolute(
                      printContractRef.current?.scrollWidth ?? 0,
                      0,
                      field.transform.percentX
                    )}px, ${convertToAbsolute(
                      printContractRef.current?.scrollHeight ?? 0,
                      0,
                      field.transform.percentY
                    )}px, 0)`,
                  }}
                  key={field.dragId}>
                  {isPrinting ? (
                    <div style={{ transform: 'translate(-20px,-10px)', whiteSpace: 'pre-line', lineHeight: '175%', ...fieldMetadata[field.id].style }}>
                      <p style={{ position: 'relative' }}>{form.values[field.id]}</p>
                    </div>
                  ) : (
                    <DraggableField id={field.dragId}>
                      {fieldMetadata[field.id].type === 'TextInput' ? (
                        <TextInput style={fieldMetadata[field.id].style} size={fieldMetadata[field.id].inputSize} placeholder={fieldMetadata[field.id].placeholder['default']} {...form.getInputProps(field.id)} />

                      ) : (
                        <p>Unhandled Field Type</p>
                      )}
                    </DraggableField>
                  )}
                </div>
              ))}
          </Container>
        </div>
        <PrintButton />

      </Container>
    </DndContext>
  );
}