import React from 'react';
import { Group, Text, useMantineTheme } from '@mantine/core';
import { IconUpload, IconPhoto, IconX } from '@tabler/icons';
import { Dropzone as MantineDropzone } from '@mantine/dropzone';

// api
import { uploadDocument } from "../../api/documents";
import { showNotification } from '@mantine/notifications';
import { parseError } from '../../functions';

// types
import { Document as DocumentType } from '../../types/response';

export default function Dropzone({ onUpload, filename = "", privateFile = false, ...props }: { onUpload: (doc: DocumentType) => void, privateFile: boolean, [key: string]: any }) {

    const theme = useMantineTheme();
    const [loading, setLoading] = React.useState(false);

    return (
        <MantineDropzone
            onDrop={(files: File[]) => {
                console.log('accepted files', files)
                setLoading(true);
                uploadDocument(files[0], filename ? filename : files[0].name, {
                    public: !privateFile,
                }).then(res => {
                    showNotification({
                        title: 'نجاح',
                        message: 'تم رفع الملف بنجاح',
                        color: 'green',
                        icon: <IconUpload />,
                    });
                    onUpload(res.data.documents[files[0].name]);
                }).catch(err => {
                    console.error(err);
                    const error = {
                        title: 'Error',
                        message: parseError(err),
                        color: 'red',
                        icon: <IconPhoto />,
                    }
                    showNotification(error);
                }).finally(() => {
                    setLoading(false);
                })
            }}
            onReject={(files) => {
                showNotification({
                    title: 'Upload failed',
                    message: 'Please note that the maximum file size is 10MB',
                    color: 'red',
                    icon: <IconPhoto />,
                })
            }}
            maxSize={10485760}
            accept={['jpg', 'jpeg', 'png', 'pdf', 'doc', 'docx', 'xls', 'xlsx']}
            loading={loading}
            {...props}
        >
            <Group position="center" spacing="xl" style={{ minHeight: 220, pointerEvents: 'none' }}>
                <MantineDropzone.Accept>
                    <IconUpload
                        size={50}
                        stroke={1.5}
                        color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
                    />
                </MantineDropzone.Accept>
                <MantineDropzone.Reject>
                    <IconX
                        size={50}
                        stroke={1.5}
                        color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]}
                    />
                </MantineDropzone.Reject>
                <MantineDropzone.Idle>
                    <IconPhoto size={50} stroke={1.5} />
                </MantineDropzone.Idle>

                <div>
                    <Text size="xl" inline>
                        اسحب الصور هنا أو انقر لتحديد الملفات
                    </Text>
                    <Text size="sm" color="dimmed" inline mt={7}>
                        يمكنك رفع ملفات بصيغة JPG, JPEG, PNG, PDF, DOC, DOCX, XLS, XLSX
                    </Text>
                </div>
            </Group>
        </MantineDropzone>
    );
}