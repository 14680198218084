// vendors
import { useEffect, useState } from 'react';
import { Drawer } from "@mantine/core"
import { showNotification, updateNotification } from '@mantine/notifications';
import { useForm } from "@mantine/form";
import {
    IconSend,
    IconAlertCircle,
} from '@tabler/icons';
import { useTranslation } from 'react-i18next';

// api
import { deleteInventoryItem, updateInventoryItem } from "../../../../api/inventory";

// utils
import DrawerTitle from "../../../../components/DrawerTitle";
import { parseError, randomString } from "../../../../functions";
import EditMenu from '../Users/shared/components/EditMenu';
import FormFields from './components/FormFields';

export default function AddUser(props: any) {

    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();

    const form = useForm();


    const data = props.data;
    useEffect(() => {
        form.setValues({ ...data });
    }, [data])

    useEffect(() => { !data && form.reset() }, [data]);
    const handleSubmit = (values: any) => {
        const id = `inv_${randomString(10)}`;
        setLoading(true);
        showNotification({
            id: id,
            title: t("Updating"),
            message: t("Please wait..."),
            loading: true,
            autoClose: false,
            disallowClose: true,
        });
        updateInventoryItem(props?.data?.id, values).then(res => {
            updateNotification({
                id: id,
                title: t("Success"),
                message: t("Updated successfully"),
                color: "green",
                icon: <IconSend />,
                autoClose: true,
            });
            form.reset();
            props?.refreshData();
            props?.onClose();
        }).catch(err => {
            updateNotification({
                id: id,
                title: t("Error while updating"),
                message: parseError(err),
                color: "red",
                autoClose: false,
                icon: <IconAlertCircle />,
            });
        }).finally(() => {
            setLoading(false);
        });
    }
    return (
        <Drawer
            position="right"
            padding="xl"
            size={800}
            {...props}
            onClose={() => {
                props.onClose();
                form.reset();
            }}
        >
            <DrawerTitle>{t("Edit")}</DrawerTitle>
            <EditMenu
                onDelete={() => deleteInventoryItem(props.data.id)}
                buttonLabel="خيارات"
                deleteLabel="حذف"
                refreshData={props.refreshData}
                onClose={props.onClose}
            />
            {data && (
                <FormFields
                    form={form}
                    data={data}
                    loading={loading}
                    onSubmit={handleSubmit}
                    isEdit={true}
                    t={t}
                />
            )}


        </Drawer>
    )

}