// vendors
import { useEffect, useState } from 'react';
import {
    Drawer,
    Grid,
    Button,
    LoadingOverlay,
} from "@mantine/core"
import { showNotification, updateNotification } from '@mantine/notifications';
import { useForm } from "@mantine/form";
import {
    IconSend,
    IconAlertCircle,
} from '@tabler/icons';
import { useTranslation } from 'react-i18next';

// api
import { deleteInvestment, updateInvestment } from "../../../../../api/investment";

// utils
import DrawerTitle from "../../../../../components/DrawerTitle";
import { parseError, randomString } from "../../../../../functions";
import PropertiesSelectField from 'components/PropertiesSelectField';
import CustomersSelectField from 'components/CustomersSelectField';
import InvoiceSelectField from 'components/InvoiceSelectField';
import EditMenu from '../../Users/shared/components/EditMenu';

export default function AddUser(props: any) {

    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();

    const form = useForm();


    const data = props.data;
    useEffect(() => {
        form.setValues({
            property: data?.property?.id,
            customer: data?.customer?.id,
            invoice: data?.invoice?.id,
        });
    }, [data])

    useEffect(() => { !data && form.reset() }, [data]);
    const handleSubmit = (values: any) => {

        const id = `inv_${randomString(10)}`;
        setLoading(true);
        showNotification({
            id: id,
            title: t("Updating"),
            message: t("Please wait..."),
            loading: true,
            autoClose: false,
            disallowClose: true,
        });
        updateInvestment(props?.data?.id, {
            ...values,
        }).then(res => {
            updateNotification({
                id: id,
                title: t("Success"),
                message: t("Updated successfully"),
                color: "green",
                icon: <IconSend />,
                autoClose: true,
            });
            form.reset();
            props?.refreshData();
            props?.onClose();
        }).catch(err => {
            updateNotification({
                id: id,
                title: t("Error while updating"),
                message: parseError(err),
                color: "red",
                autoClose: false,
                icon: <IconAlertCircle />,
            });
        }).finally(() => {
            setLoading(false);
        });
    }
    return (
        <Drawer
            position="right"
            padding="xl"
            size={'xl'}
            {...props}
            onClose={() => {
                props.onClose();
                form.reset();
            }}
        >
            <DrawerTitle>{t("Edit")}</DrawerTitle>
            <EditMenu
                onDelete={() => deleteInvestment(props.data.id)}
                buttonLabel="خيارات"
                deleteLabel="حذف"
                refreshData={props.refreshData}
                onClose={props.onClose}
            />
            <form onSubmit={form.onSubmit(handleSubmit)}>
                <Grid gutter="lg" mt={20}>
                    <LoadingOverlay visible={loading} overlayBlur={2} />
                    <Grid gutter="lg" mt={20}>
                        <LoadingOverlay visible={loading} overlayBlur={2} />
                        <Grid.Col span={12}>
                            <PropertiesSelectField
                                size="xs"
                                label={t("Property")}
                                placeholder={t("property")}
                                defaultData={[data?.property]}
                                defaultValue={data?.property?.id}
                                {...form.getInputProps('property')}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <CustomersSelectField
                                size="xs"
                                label={t("المستثمر")}
                                placeholder={t("المستثمر")}
                                defaultData={[data?.investor]}
                                defaultValue={data?.investor?.id}
                                onlyInvestors={true}
                                {...form.getInputProps('investor')}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <InvoiceSelectField
                                size="xs"
                                label={t("الوصل")}
                                placeholder={t("الوصل")}
                                defaultData={[data?.invoice]}
                                defaultValue={data?.invoice?.id}
                                {...form.getInputProps('invoice')}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <Button
                                size="xs"
                                leftIcon={<IconSend size={16} />}
                                type="submit"
                            >
                                {t('Save')}
                            </Button>
                        </Grid.Col>
                    </Grid>
                </Grid>
            </form>
        </Drawer>
    )

}