// vendors
import {
    Box,
    Button,

} from "@mantine/core";
import dayjs from 'dayjs';
import {
    dateFilterFn,
    highlightFilterValue,

    stringFilterFn,
} from 'mantine-data-grid';

// utils
import PageHeading from "../../../../components/PageHeading";
import AddData from "./_AddDrawer";
import EditData from "./_EditDrawer";
import SkeletonRows from "../../../../components/SkeletonRows";

// api
import { getLetters } from "../../../../api/letter";

// types
import { Inventory as InventoryType } from "../../../../types/response";
import NoDataFound from '../../../../components/NoDataFound';
import { useTranslation } from 'react-i18next';
import { useHasPermission } from '../../../../hooks';
import NoPermisionPage from '../../../../components/NoPermisionPage';
import { useData } from '../../../../hooks/useUsersData';
import DataGridComponent from '../../../../components/DataGridComponent';

export default function Letters() {

    const permissionGranted = useHasPermission(["invoices.read", "properties.read"]);
    const { t } = useTranslation();
    const {
        loading,
        data,
        noData,
        total,
        editData,
        setEditData,
        addDataDrawerState,
        setAddDataDrawerState,
        load,
        search,
        refreshData,
    } = useData<InventoryType>({
        fetchData: (page, limit, search) => getLetters(page, limit, search).then(res => ({
            data: res.data.letters,
            total: res.data.total,
        })),
        errorMessage: t('Error while fetching user data'),
    });

    if (!permissionGranted) {
        return <NoPermisionPage />;
    }
    const column = [
        {
            accessorKey: 'subject',
            header: t('Subject') as string,
            filterFn: stringFilterFn,
            size: 300,
            cell: highlightFilterValue,
        },
        {
            header: t('العدد') as string,
            accessorKey: 'num_id',
            filterFn: stringFilterFn,
        },
        {
            header: t('Sender') as string,
            accessorKey: 'sender',
            filterFn: stringFilterFn,
        },
        {
            header: t("Receiver") as string,
            accessorKey: 'receiver',
            filterFn: stringFilterFn,
        },
        {
            header: t('Date') as string,
            accessorKey: 'created_at',
            filterFn: dateFilterFn,
            cell: (props: any): string => dayjs(props.row.original.created_at).format('DD/MM/YYYY'),
        },
    ];
    return (
        <>
            <PageHeading>{t("Letters")}</PageHeading>
            <Button
                mx={20}
                mb={20}
                onClick={() => setAddDataDrawerState(true)}
            >
                {t("Add")}
            </Button>
            {/* <TextInput
                px={20}
                pb={20}
                placeholder="Search"
                value={search}
                onChange={e => setSearch(e.target.value)}
            /> */}
            <Box
                sx={{
                    padding: '0 20px',
                }}
            >
                {
                    data && data.length > 0 ? (
                        <DataGridComponent
                            data={data}
                            total={total}
                            loading={loading}
                            onPageChange={load}
                            onSearch={search}
                            onRowClick={(row) => setEditData(row)}
                            columns={column}
                        />
                    ) : noData ? (
                        <NoDataFound />
                    ) : (
                        <SkeletonRows count={10} height={20} />
                    )
                }
            </Box>

            <AddData
                opened={addDataDrawerState}
                refreshData={refreshData}
                onClose={() => setAddDataDrawerState(false)}
            />

            <EditData
                data={editData}
                opened={editData !== null}
                refreshData={refreshData}
                onClose={() => setEditData(null)}
            />
        </>
    );
}