import axios, { CancelTokenSource } from 'axios';
import { apiWithInterceptor, apiWithoutInterceptor } from '.';
import dayjs from 'dayjs';

let cancelTokens: { [key: string]: CancelTokenSource } = {};

// get
export const getCustomers = (page :number = 1, limit: number = -1, search: string = '' ) => {
    cancelTokens.getCustomers && cancelTokens.getCustomers.cancel();
    cancelTokens.getCustomers = axios.CancelToken.source();
    return apiWithInterceptor.get(`/customer/`, { params: {page, limit, search} })
}
export const getInvestorsCustomers = (page :number = 1, limit: number = -1, search: string = '' ) => {
    cancelTokens.getCustomers && cancelTokens.getCustomers.cancel();
    cancelTokens.getCustomers = axios.CancelToken.source();
    return apiWithInterceptor.get(`/customer/`, { params: {page, limit, search, is_investor: 1} })
}
export const getCustomer = (id: string) => apiWithInterceptor.get(`/customer/${id}`)

// post 
export const createCustomer = (data: any) => apiWithInterceptor.post(`/customer/`, {...data, birthdate: dayjs(data.birthdate).format('YYYY-MM-DD')})
export const resetPassword = (token: string, password: string) => apiWithoutInterceptor.post(`/customer/password/reset/`, {token, password})

// delete
export const deleteCustomer = (id: string) => apiWithInterceptor.delete(`/customer/${id}`, { params: {approved: true} })

// put
export const updateCustomer = (id: string, data: {}) => apiWithInterceptor.put(`/customer/${id}`, data)
export const setAsInvestor = (id: string) => apiWithInterceptor.put(`/customer/${id}/set_investor`, {is_investor: true})
export const setAsNotInvestor = (id: string) => apiWithInterceptor.put(`/customer/${id}/set_investor`, {is_investor: false})