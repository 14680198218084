import React from 'react';
import { Box, Card, Chip, Divider, Skeleton, Text } from '@mantine/core';
import { IconHomeCheck, IconHomeDollar } from '@tabler/icons';

import { useTranslation } from 'react-i18next';
interface ReportCardProps {
    title: string;
    reportData: any;
    propertiesRemainingReport: any;
    iconType: string;
    color: string;
    revenueField: string;
    countField: string;
    remainingCountField: string;
    totalLabel: string;
}

const ReportCard: React.FC<ReportCardProps> = ({ title, reportData, propertiesRemainingReport, iconType, color, revenueField, countField, remainingCountField, totalLabel }) => {
    const { t } = useTranslation();
    return (
        <Card
            shadow="sm"
            radius="sm"
            p="xl"
            py={50}
            sx={theme => ({
                backgroundColor: (color === "blue" ? theme.colors.blue[5] : theme.colors.teal[5]),
                color: '#fff'
            })}
        >
            <Text size={'xl'} align='center' mb={'xl'}>
                {title}
            </Text>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Box>
                    {(iconType === "home"
                        ? <IconHomeCheck size={90} /> : <IconHomeDollar size={90} />)}
                </Box>
                <Divider orientation="vertical" mx={'xl'} />
                <Box>
                    <Text size={'md'} weight={'lighter'}>
                        {title}
                    </Text>
                    <Text size={40} weight={'bold'}>
                        {
                            reportData ? revenueField.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : (
                                <Skeleton width={200} height={40} my={'xs'} />
                            )
                        }
                    </Text>
                    <Text size={'md'} weight={'lighter'}>
                        IQD
                    </Text>
                </Box>
            </Box>
            <Divider my={'xl'} />
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Text size={'xl'} weight={'bold'}>
                        {
                            reportData ? countField : (
                                <Skeleton width={50} height={20} my={'xs'} />
                            )
                        }
                    </Text>
                    <Box>
                        {
                            reportData && propertiesRemainingReport && (
                                <Chip color={'teal'} size={'xs'} mx={'xs'} my={'xs'} variant={'outline'} checked={false}>
                                    متبقي {remainingCountField}
                                </Chip>
                            )
                        }
                    </Box>
                </Box>
                <Text size={'sm'} weight={'lighter'}>
                    {t(totalLabel)}
                </Text>
            </Box>
        </Card>
    );
};

export default ReportCard;
