// vendors
import { useState } from 'react';
import {
    Drawer,
    ScrollArea,

} from "@mantine/core"
import { showNotification, updateNotification } from '@mantine/notifications';
import { useForm } from "@mantine/form";
import {
    IconSend,
    IconAlertCircle,
} from '@tabler/icons';
import { useSelector } from "react-redux";

// api
import { createProperty } from "../../../../../api/property";

// utils
import DrawerTitle from "../../../../../components/DrawerTitle";
import { latLngToGeo, latLngAverage, randomString, parseError } from "../../../../../functions";

// types
import { User as UserType } from "../../../../../types/response";
import { useTranslation } from 'react-i18next';
import FormFields from './components/FormFields';

export default function AddDrawer(props: {
    opened: boolean,
    onClose: () => void,
    refreshData: () => void,
    data: google.maps.Polygon
}) {

    const [loading, setLoading] = useState(false);
    const user: UserType = useSelector((state: any) => state.user.data);
    const { t } = useTranslation();

    const form = useForm({
        initialValues: {
            name: "",
            description: "",
            documents: [],
            property_legitimacy: 'tabu',
            price: 0,
            price_literal: "",
            status: "listed",
            property_type: "land",
            address: "",
            area: 0,
            unit: "m",
            geometry: [],
            geocode: "",
            rooms: '',
            bathrooms: '',
            furniture: false,
            cost: null,
            property_ownership: "sale",
            company_ratio: 0,
        },
        validate: {
            // name: (value: string) => value.length <= 0 ? 'Name is ' : null,
            // description: (value: string) => value.length <= 0 ? 'Description is ' : null,
            // price: (value: number) => value <= 0 ? 'Price is ' : null,
            // price_literal: (value: string) => value.length <= 0 ? 'Price literal is ' : null,
            // status: (value: string) => value.length <= 0 ? 'Status is ' : null,
            // property_type: (value: string) => value.length <= 0 ? 'Property type is ' : null,
            // address: (value: string) => value.length <= 0 ? 'Address is ' : null,
            // area: (value: number) => value <= 0 ? 'Area is ' : null,
            // unit: (value: string) => value.length <= 0 ? 'Unit is ' : null,
            // geometry: (value: Geometry) => value.length <= 2 ? 'Geometry is ' : null,
            // geocode: (value: string) => value.length <= 0 ? 'Geocode is ' : null,
        }
    });
    const handleSubmit = (values: any) => {
        const latLng = props.data.getPaths().getArray()[0].getArray()
        const geometry = latLngToGeo(latLng)
        const avg = latLngAverage(latLng)
        const geocode = avg.lng() + "," + avg.lat()

        const id = `inv_${randomString(10)}`;
        setLoading(true);
        showNotification({
            id: id,
            title: t("Creating"),
            message: t("Please wait..."),
            loading: true,
            autoClose: false,
            disallowClose: true,
        });
        createProperty({ ...values, custom_id: id, geometry, geocode }).then(res => {
            updateNotification({
                id: id,
                title: t("Success"),
                message: t("Created successfully"),
                color: "green",
                icon: <IconSend />,
                autoClose: true,
            });
            form.reset();
            props?.refreshData();
            props?.onClose();
        }).catch(err => {
            console.log(err);
            updateNotification({
                id: id,
                title: t("Error while creating"),
                message: parseError(err),
                color: "red",
                autoClose: false,
                icon: <IconAlertCircle />,
            });
        }).finally(() => {
            setLoading(false);
        });

    }
    return (
        <Drawer
            position="right"
            padding="xl"
            size={800}
            {...props}
        >
            <DrawerTitle>
                {t('Add Property')}
            </DrawerTitle>
            <ScrollArea
                offsetScrollbars={true}
                style={{
                    height: "calc(100vh - 100px)",
                    width: "100%",
                }}
            >
                <FormFields
                    form={form}
                    loading={loading}
                    onSubmit={handleSubmit}
                    isEdit={false}
                    t={t}
                />
            </ScrollArea>
        </Drawer>
    )

}

