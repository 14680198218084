import * as React from "react";
import * as ReactDOM from "react-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider as StoreProvider } from "react-redux";

import store from "./redux/store";
import './i18n';

ReactDOM.render(
    (<React.StrictMode>
        <BrowserRouter>
            <StoreProvider store={store}>
                <App />
            </StoreProvider>
        </BrowserRouter>
    </React.StrictMode>),
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
