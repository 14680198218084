// vendors
import { useEffect, useState } from 'react';
import {
    Drawer,
} from "@mantine/core"
import { showNotification, updateNotification } from '@mantine/notifications';
import { useForm } from "@mantine/form";
import {
    IconSend,
    IconAlertCircle,
} from '@tabler/icons';
import { useTranslation } from 'react-i18next';

// api
import { deleteAd, updateAd } from "../../../../api/ads";

// utils
import DrawerTitle from "../../../../components/DrawerTitle";
import { parseError, randomString } from "../../../../functions";
import { Ad } from 'types/response';
import FormFields from './components/FormFileds';
import EditMenu from '../Users/shared/components/EditMenu';

export default function AddUser(props: any) {

    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();

    const form = useForm();


    const data: Ad = props.data;
    useEffect(() => {
        form.setValues({ ...data });
        data && form.setFieldValue('expires_at', data.expires_at ? new Date(data.expires_at) : '');
        // form.setFieldValue('expires_at', data.expires_at ? new Date(data.expires_at) : '');
    }, [data])

    useEffect(() => { !data && form.reset() }, [data]);
    const handleSubmit = (values: any) => {
        const id = `inv_${randomString(10)}`;
        setLoading(true);
        showNotification({
            id: id,
            title: t("Updating"),
            message: t("Please wait..."),
            loading: true,
            autoClose: false,
            disallowClose: true,
        });
        updateAd(props?.data?.id, values).then(res => {
            updateNotification({
                id: id,
                title: t("Success"),
                message: t("updated successfully"),
                color: "green",
                icon: <IconSend />,
                autoClose: true,
            });
            form.reset();
            props?.refreshData();
            props?.onClose();
        }).catch(err => {
            updateNotification({
                id: id,
                title: t("Error while updating"),
                message: parseError(err),
                color: "red",
                autoClose: false,
                icon: <IconAlertCircle />,
            });
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <Drawer
            position="right"
            padding="xl"
            size={800}
            {...props}
            onClose={() => {
                props.onClose();
                form.reset();
            }}
        >
            <DrawerTitle>{t('Edit')}</DrawerTitle>
            <EditMenu
                onDelete={() => deleteAd(props.data.id)}
                buttonLabel="خيارات"
                deleteLabel="حذف"
                refreshData={props.refreshData}
                onClose={props.onClose}

            />
            {
                data && (
                    <FormFields
                        form={form}
                        data={data}
                        loading={loading}
                        onSubmit={handleSubmit}
                        isEdit={true}
                        t={t}
                    />
                )
            }
        </Drawer>
    )

}