// vendors
import React from 'react';
import {
    Grid,
    TextInput,
    Button,
    Select,
    LoadingOverlay,
    NumberInput,
    ScrollArea,
} from "@mantine/core"
import {
    IconSend,
} from '@tabler/icons';

// api
import MediaLibraryField from '../../../../../components/MediaLibraryField';

interface FormFieldsProps {
    form: any;
    data?: any;
    loading: boolean;
    onSubmit: (values: any) => void;
    isEdit: boolean;
    t: (key: string) => string;
}

const FormFields: React.FC<FormFieldsProps> = ({ form, data, loading, onSubmit, isEdit, t }) => {
    return (
        <form onSubmit={form.onSubmit(onSubmit)}>
            <ScrollArea
                offsetScrollbars
                style={{ height: 'calc(100vh - 170px)' }}
            >
                <Grid gutter="lg" mt={20}>
                    <LoadingOverlay visible={loading} overlayBlur={2} />
                    <Grid gutter="lg" mt={20}>
                        <LoadingOverlay visible={loading} overlayBlur={2} />
                        <Grid.Col span={12}>
                            <TextInput
                                size="xs"
                                style={{ paddingRight: '10px' }}
                                label={t('Name')}
                                placeholder={t('Name')}
                                defaultValue={data?.name}
                                {...form.getInputProps('name')}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <TextInput
                                size="xs"
                                label={t('Description')}
                                style={{ paddingRight: '10px' }}
                                placeholder={t('Description')}
                                defaultValue={data?.description}
                                {...form.getInputProps('description')}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <Select
                                size="xs"
                                style={{ paddingRight: '10px' }}
                                data={[
                                    { label: t('Good'), value: 'Good' },
                                    { label: t('Broken'), value: 'Bad' },
                                    { label: t('Lost'), value: 'Lost' },
                                    { label: t('Unknown'), value: 'Unknown' },
                                ]}
                                label={t("Status")}
                                placeholder={t('Status')}
                                defaultValue={data?.status}
                                {...form.getInputProps('status')}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <NumberInput
                                size="xs"
                                style={{ paddingRight: '10px' }}
                                label={t('Quantity')}
                                placeholder={t('Quantity')}
                                {...form.getInputProps('count')}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            {/* <FileInput 
                            size="xs"
                            label="Images"
                            placeholder="Select files" 
                            accept="image/png,image/jpeg,image/jpg"
                            multiple
                            {...form.getInputProps('documents')}
                        /> */}
                            <MediaLibraryField
                                inputProps={{
                                    withAsterisk: true,
                                    size: "xs",
                                    label: t('Images'),
                                    placeholder: t("Select files"),

                                }}
                                multiple={true}
                                defaultValue={data?.documents}
                                {...form.getInputProps('documents')}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <Button
                                size="xs"
                                leftIcon={<IconSend size={16} />}
                                type="submit"
                                style={{ right: "10px" }}
                            >
                                {isEdit ? t("Save") : t("Create")}
                            </Button>
                        </Grid.Col>
                    </Grid>

                </Grid>
            </ScrollArea>
        </form>
    );
};

export default FormFields;
