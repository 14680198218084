// vendors
import { useEffect, useState } from 'react';
import {
    AppShell,
    Box,
    useMantineTheme,
} from '@mantine/core';
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// utils
import Header from './_Header';
import Navbar from './_Navbar';

import CoverImage from '../../assets/cover.svg';
import { useLocalStorage } from '@mantine/hooks';

export default function App() {

    const theme = useMantineTheme();
    const navigate = useNavigate();
    const token: string = useSelector((state: any) => state.auth.token);

    const [isIdle, setIsIdle] = useState(false);
    const [lastActive, setLastActive] = useState(new Date().getTime());

    const [screenTimeout, setScreenTimeout] = useLocalStorage({ key: 'screen-timeout', defaultValue: 30 });

    useEffect(() => {
        if (!token) navigate(`/?redirect=${window.location.pathname}`);
    }, [token])

    useEffect(() => {
        document.addEventListener('mousemove', () => {
            const current = new Date().getTime();
            setIsIdle(false);
            setLastActive(current);
        });
        document.addEventListener('keydown', () => {
            const current = new Date().getTime();
            setIsIdle(false);
            setLastActive(current);
        });
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            const current = new Date().getTime();
            const diff = current - lastActive;
            if (diff > screenTimeout * 1000) {
                setIsIdle(true);
            } else {
                setIsIdle(false);
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [lastActive]);

    return (
        <>
            <AppShell
                padding={0}
                styles={{
                    main: {
                        background: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
                    },
                }}
                navbarOffsetBreakpoint="sm"
                asideOffsetBreakpoint="sm"
                navbar={<Navbar />}
                header={<Header />}
            >
                <Box>
                    <Outlet />
                </Box>
            </AppShell>
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100vw',
                    height: '100vh',
                    zIndex: 999,
                    backgroundImage: `url(${CoverImage})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundSize: '70%',
                    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
                    opacity: isIdle ? 1 : 0,
                    pointerEvents: isIdle ? 'all' : 'none',
                }}
            />
        </>
    );

}