// vendors
import { useState } from 'react';
import {
    Drawer,
} from "@mantine/core"
import { showNotification, updateNotification } from '@mantine/notifications';
import { useForm } from "@mantine/form";
import {
    IconSend,
    IconAlertCircle,
} from '@tabler/icons';
import { useTranslation } from 'react-i18next';

// api
import { createAd } from "../../../../api/ads";

// utils
import DrawerTitle from "../../../../components/DrawerTitle";
import { parseError, randomString } from "../../../../functions";
import FormFields from './components/FormFileds';

export default function AddUser(props: any) {

    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();

    const form = useForm({
        initialValues: {
            name: '',
            description: '',
            documents: [],
        },
        validate: {
            name: (value) => value.length <= 0 ? 'Name is required' : null,
            description: (value) => value.length <= 0 ? 'Description is required' : null,
        }
    });
    const handleSubmit = (values: any) => {
        const id = `inv_${randomString(10)}`;
        setLoading(true);
        showNotification({
            id: id,
            title: t("Creating"),
            message: t("Please wait..."),
            loading: true,
            autoClose: false,
            disallowClose: true,
        });
        createAd({ ...values, custom_id: id }).then(res => {
            updateNotification({
                id: id,
                title: t("Success"),
                message: t("Created successfully"),
                color: "green",
                icon: <IconSend />,
                autoClose: true,
            });
            form.reset();
            props?.refreshData();
            props?.onClose();
        }).catch(err => {
            updateNotification({
                id: id,
                title: t("Error while creating"),
                message: parseError(err),
                color: "red",
                autoClose: false,
                icon: <IconAlertCircle />,
            });
        }).finally(() => {
            setLoading(false);
        });
    }
    return (
        <Drawer
            position="right"
            padding="xl"
            size={800}
            {...props}
            onClose={() => {
                props.onClose();
                form.reset();
            }}
        >
            <DrawerTitle>
                {t('Add')}
            </DrawerTitle>
            <FormFields
                form={form}
                data={props?.data}
                loading={loading}
                onSubmit={handleSubmit}
                isEdit={false}
                t={t}
            />

        </Drawer>
    )

}