import React from 'react';
import {
    Grid,
    TextInput,
    Button,
    NumberInput,
    LoadingOverlay,
    Textarea,
    Text,
    ScrollArea,
} from "@mantine/core"
import {
    IconSend,
} from '@tabler/icons';

import AssetSelectField from 'components/AssetSelectField';
import MediaLibraryField from '../../../../../components/MediaLibraryField';
import CustomersSelectField from 'components/CustomersSelectField';
import { DatePicker } from '@mantine/dates';
import tafqeet from 'inc/tafqeet';
import dayjs from 'dayjs';
import { useState } from 'react';
interface FormFieldsProps {
    form: any;
    data?: any;
    loading: boolean;
    onSubmit: (values: any) => void;
    isEdit: boolean;
    t: (key: string) => string;
}

const FormFields: React.FC<FormFieldsProps> = ({ form, data, loading, onSubmit, isEdit, t }) => {
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    return (
        <form onSubmit={form.onSubmit(onSubmit)} >
            <ScrollArea
                offsetScrollbars
                style={{ height: 'calc(100vh - 100px)' }}
            >
                <LoadingOverlay visible={loading} overlayBlur={2} />
                <Grid gutter="lg" mt={20}>
                    <Grid.Col span={12}>
                        <AssetSelectField
                            size="xs"
                            label={t("الحساب")}
                            placeholder={t("الحساب")}
                            {...form.getInputProps('asset')}
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <TextInput
                            size="xs"
                            label={t('Subject')}
                            placeholder={t('Subject')}
                            {...form.getInputProps('subject')}
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <CustomersSelectField
                            size="xs"
                            label={t("الزبون")}
                            placeholder={t("الزبون")}
                            onSelect={(customer) => {
                                form.setFieldValue('from_to', customer.firstname + ' ' + customer.lastname);
                                if (customer.is_investor) {
                                    form.setFieldValue('invoice_type', 'investment');
                                } else {
                                    form.setFieldValue('invoice_type', 'sales');
                                }
                            }}
                            {...form.getInputProps('customer')}
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <TextInput
                            size="xs"
                            label={t("Customer")}
                            placeholder={t("Customer")}
                            {...form.getInputProps('from_to')}
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <Textarea
                            size="xs"
                            label={t("Description")}
                            placeholder={t("Description")}
                            {...form.getInputProps('description')}
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <NumberInput
                            size="xs"
                            label={t("Amount")}
                            placeholder={t("Amount")}
                            icon={(<Text size='xs'>IQD</Text>)}
                            // {...form.getInputProps('amount')}
                            parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}
                            onChange={(val: any) => {
                                form.setFieldValue('amount', val);
                                form.setFieldValue('amount_literal', tafqeet(val));
                            }}
                            formatter={(value: any) =>
                                !Number.isNaN(parseFloat(value))
                                    ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                    : ''
                            }
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <TextInput
                            size="xs"
                            label={t("Amount Letiral")}
                            placeholder={t("Amount Letiral")}
                            {...form.getInputProps('amount_literal')}
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <DatePicker
                            locale='ar'
                            size="xs"
                            label={t("Issue Date")}
                            placeholder={t("Issue Date")}
                            {...form.getInputProps('issued_at')}
                            value={selectedDate}
                            onChange={(date) => {
                                setSelectedDate(date);
                                form.setFieldValue('issued_at', date);
                            }}
                            dayStyle={(date) => {
                                const isCurrentDay = dayjs().isSame(date, 'day');
                                const isSelectedDay = selectedDate && dayjs(selectedDate).isSame(date, 'day');

                                if (isSelectedDay) {
                                    return {
                                        backgroundColor: '#007bff',
                                        color: 'white',
                                        borderRadius: '4px',
                                        padding: '3px',
                                        border: '2px solid #007bff',
                                    };
                                }

                                if (!selectedDate && isCurrentDay) {
                                    return {
                                        backgroundColor: '#007bff',
                                        color: 'white',
                                        borderRadius: '4px',
                                        padding: '3px',
                                    };
                                }

                                return {};
                            }}
                        />

                    </Grid.Col>
                    {/* <Grid.Col span={12}>
                        <Select
                            size="xs"
                            label="Property"
                            searchable
                            data={['Property 1', 'Property 2', 'Property 3']}
                        />
                    </Grid.Col> */}
                    <Grid.Col span={12}>
                        <MediaLibraryField
                            inputProps={{
                                withAsterisk: true,
                                size: "xs",
                                label: t("Documents"),
                                placeholder: t("Select documents"),
                            }}
                            multiple={true}
                            {...form.getInputProps('documents')}
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <Button
                            size="xs"
                            leftIcon={<IconSend size={16} />}
                            type="submit"
                            style={{ bottom: "10px", right: "10px" }}
                        >

                            {t("Create")}
                        </Button>


                    </Grid.Col>
                </Grid>
            </ScrollArea>

        </form>
    );
};

export default FormFields;
