const initial = {

    // used in "container/app" navbar in mobile size and sidebar in desktop size
    navListOpen: false, 

}

// Actions
export const toggleNavListState = () => ({
    type: 'TOGGLE_NAV_LIST_STATE',
})

// Reducer
const Theme = (state = initial, action) => {
    switch (action.type) {
        case 'TOGGLE_NAV_LIST_STATE':
            return {
                ...state,
                navListOpen: !state.navListOpen,
            }

        default:
            return state;
    }
}

export default Theme;
