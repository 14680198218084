import axios from "axios";

const apiWithoutInterceptor = axios.create({
  baseURL: `${
    process.env.NODE_ENV === "production"
      ? "https://dev.soft-y.org/api"
      : "https://dev.soft-y.org/api"
  }`,
  headers: {
    "Content-Type": "application/json",
  },
});

const apiWithInterceptor = axios.create({
  baseURL: `${
    process.env.NODE_ENV === "production"
      ? "https://dev.soft-y.org/api"
      : "https://dev.soft-y.org/api"
  }`,
  headers: {
    "Content-Type": "application/json",
  },
});

const apiFormDataWithInterceptor = axios.create({
  baseURL: `${
    process.env.NODE_ENV === "production"
      ? "https://dev.soft-y.org/api"
      : "https://dev.soft-y.org/api"
  }`,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

apiWithInterceptor.interceptors.request.use((config) => {
  const token = localStorage.getItem("token").replace(/['"]+/g, "");
  if (token) {
    if (!config.headers) config.headers = {};
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

apiWithInterceptor.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }
    return Promise.reject(error);
  }
);

apiFormDataWithInterceptor.interceptors.request.use((config) => {
  const token = localStorage.getItem("token").replace(/['"]+/g, "");
  if (token) {
    if (!config.headers) config.headers = {};
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export {
  apiWithInterceptor,
  apiWithoutInterceptor,
  apiFormDataWithInterceptor,
};
