import { apiWithInterceptor } from ".";

// get
export const getContracts = (page: number = 1, limit: number = -1, search: string = '') => apiWithInterceptor.get(`/contract/`, { params: { page, limit, search } });
export const getContract = (id: string) => apiWithInterceptor.get(`/contract/${id}`);

// post
export const createContract = (data: {}) => apiWithInterceptor.post(`/contract/`, { ...data });

// delete
export const deleteContract = (id: string) => apiWithInterceptor.delete(`/contract/${id}`, { params: {approved: true} })

// put
export const updateContract = (id: string, data: {}) => apiWithInterceptor.put(`/contract/${id}`, data);