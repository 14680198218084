import React from 'react';
import { Group, Text, useMantineTheme } from '@mantine/core';
import { IconUpload, IconPhoto, IconX } from '@tabler/icons';
import { Dropzone as MantineDropzone, DropzoneProps, IMAGE_MIME_TYPE } from '@mantine/dropzone';

// api
import { uploadDocument } from "../../../../api/documents";
import { showNotification } from '@mantine/notifications';
import { parseError } from '../../../../functions';

export default function Dropzone({ onUpload, ...props }: { onUpload: () => void, [key: string]: any }) {

    const theme = useMantineTheme();
    const [loading, setLoading] = React.useState(false);

    return (
        <MantineDropzone
            onDrop={(files: File[]) => {
                console.log('accepted files', files)
                setLoading(true);
                uploadDocument(files[0], files[0].name).then(res => {
                    showNotification({
                        title: 'Success',
                        message: 'File uploaded successfully',
                        color: 'green',
                        icon: <IconUpload />,
                    });
                    onUpload();
                }).catch(err => {
                    console.error(err);
                    const error = {
                        title: 'Error',
                        message: parseError(err),
                        color: 'red',
                        icon: <IconPhoto />,
                    }
                    showNotification(error);
                }).finally(() => {
                    setLoading(false);
                })
            }}
            onReject={(files) => {
                showNotification({
                    title: 'Upload failed',
                    message: 'Please note that the maximum file size is 10MB',
                    color: 'red',
                    icon: <IconPhoto />,
                })
            }}
            maxSize={10485760}
            accept={IMAGE_MIME_TYPE}
            loading={loading}
            {...props}
        >
            <Group position="center" spacing="xl" style={{ minHeight: 220, pointerEvents: 'none' }}>
                <MantineDropzone.Accept>
                    <IconUpload
                        size={50}
                        stroke={1.5}
                        color={theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]}
                    />
                </MantineDropzone.Accept>
                <MantineDropzone.Reject>
                    <IconX
                        size={50}
                        stroke={1.5}
                        color={theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]}
                    />
                </MantineDropzone.Reject>
                <MantineDropzone.Idle>
                    <IconPhoto size={50} stroke={1.5} />
                </MantineDropzone.Idle>

                <div>
                    <Text size="xl" inline>
                        Drag images here or click to select files
                    </Text>
                    <Text size="sm" color="dimmed" inline mt={7}>
                        Attach as many files as you like, each file should not exceed 5mb
                    </Text>
                </div>
            </Group>
        </MantineDropzone>
    );
}