// AddDrawer.tsx
import React, { useState } from 'react';
import { Drawer, ScrollArea } from "@mantine/core";
import { showNotification, updateNotification } from '@mantine/notifications';
import { useForm } from "@mantine/form";
import { IconSend, IconAlertCircle } from '@tabler/icons';
import { useTranslation } from 'react-i18next';

// utils
import DrawerTitle from "../../../../../components/DrawerTitle";
import { parseError, randomString } from "../../../../../functions";
import { createCustomer as addUser } from "../../../../../api/customer";
import dayjs from 'dayjs';
// components
import UserForm from './components/CustomerForm';

export default function AddDrawer(props: any) {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const form = useForm({
        initialValues: {
            accountname: "",
            password: "",
            email: "",
            phone_number: "",
            firstname: "",
            lastname: "",
            birthdate: new Date(),
        },
    });

    const handleSubmit = (values: any) => {
        if (values.birthdate) values.birthdate = dayjs(values.birthdate as string).format('YYYY-MM-DD');
        const id = `user_${randomString(10)}`;
        setLoading(true);
        showNotification({
            id: id,
            title: t("Adding"),
            message: t("Please wait..."),
            loading: true,
            autoClose: false,
            disallowClose: true,
        });
        addUser(values).then(res => {
            updateNotification({
                id: id,
                title: t("Success"),
                message: t("Added successfully"),
                color: "green",
                icon: <IconSend />,
                autoClose: true,
            });
            form.reset();
            props?.refreshData();
            props?.onClose();
        }).catch(err => {
            console.log(err);
            updateNotification({
                id: id,
                title: t("Error while adding"),
                message: parseError(err),
                color: "red",
                autoClose: false,
                icon: <IconAlertCircle />,
            });
        }).finally(() => {
            setLoading(false);
        });
    };

    return (
        <Drawer
            position="right"
            padding="xl"
            size={800}
            {...props}
            onClose={() => {
                props.onClose();
                form.reset();
            }}
        >
            <DrawerTitle>
                {t("Add")}
            </DrawerTitle>
            <ScrollArea
                offsetScrollbars
                style={{ height: 'calc(100vh - 117px)' }}
            >
                <UserForm
                    form={form}
                    data={props?.data}
                    onSubmit={handleSubmit}
                    loading={loading}
                    isEdit={false}
                />
            </ScrollArea>
        </Drawer>
    );
}
