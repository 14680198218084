// modules/investor/index.tsx

import React, { useState, useEffect } from 'react';
import { Box } from "@mantine/core";
import { showNotification } from '@mantine/notifications';
import { IconAlertCircle } from '@tabler/icons';
import PageHeading from "../../../../../components/PageHeading";
import DataGridComponent from '../../../../../components/DataGridComponent';
import NoDataFound from '../../../../../components/NoDataFound';
import SkeletonRows from "../../../../../components/SkeletonRows";
import NoPermisionPage from '../../../../../components/NoPermisionPage';
import { useHasPermission } from '../../../../../hooks';
import { useData } from '../../../../../hooks/useUsersData';
import { getInvestorsCustomers } from 'api/customer';
import { getInvestorReport } from "../../../../../api/investment";
import InvestorDrawer from './components/InvestorDrawer';
import InvestorActions from './components/InvestorActions';
import { parseError } from 'functions';
import { Customer as CustomerType, InvestorReport } from "../../../../../types/response";
import {
    dateFilterFn,
    stringFilterFn,
} from 'mantine-data-grid';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
const Investment: React.FC = () => {
    const permissionGranted = useHasPermission([]);
    const { t } = useTranslation();
    const { loading, data, noData, total, load, search, refreshData } = useData({
        fetchData: (page, limit) => getInvestorsCustomers(page, limit).then(res => ({
            data: res.data.customers,
            total: res.data.total,
        })),
        errorMessage: 'Error while fetching user data',
    });
    const [selectedInvestor, setSelectedInvestor] = useState<CustomerType | null>(null);
    const [investorReport, setInvestorReport] = useState<InvestorReport | null>(null);
    useEffect(() => {
        if (selectedInvestor) {
            getInvestorReport(selectedInvestor.id).then(res => {
                setInvestorReport(res.data);
            }).catch(err => {
                showNotification({
                    title: 'Error',
                    message: parseError(err),
                    color: 'red',
                    icon: <IconAlertCircle />,
                });
            });
        }
    }, [selectedInvestor]);
    if (!permissionGranted) {
        return <NoPermisionPage />;
    }
    const column = [
        {
            accessorKey: 'name',
            header: t('اسم المستثمر') as string,
            filterFn: stringFilterFn,
            size: 300,
            cell: (props: any): string => {
                const customer = (props.row.original as CustomerType);
                return `${customer.accountname} (${customer.firstname} ${customer.lastname})`;
            },
        },
        {
            accessorKey: 'email',
            header: t('البريد الالكتروني') as string,
            filterFn: stringFilterFn,
        },
        {
            accessorKey: 'created_at',
            header: t('تاريخ التسجيل') as string,
            filterFn: dateFilterFn,
            cell: (props: any): string => dayjs(props.row.original.created_at).format('DD/MM/YYYY'),
        }
    ];
    return (
        <>
            <PageHeading>{t("الاستثمارات")}</PageHeading>
            <InvestorActions refreshData={refreshData} />
            <Box sx={{ padding: '0 20px' }}>
                {data && data.length > 0 ? (
                    <DataGridComponent
                        data={data}
                        total={total}
                        loading={loading}
                        onPageChange={load}
                        onSearch={search}
                        onRowClick={(row) => setSelectedInvestor(row)}
                        columns={column}
                    />
                ) : noData ? (
                    <NoDataFound />
                ) : (
                    <SkeletonRows count={10} height={20} />
                )}
            </Box>
            <InvestorDrawer
                selectedInvestor={selectedInvestor}
                investorReport={investorReport}
                onClose={() => {
                    setSelectedInvestor(null);
                    setInvestorReport(null);
                }}
            />
        </>
    );
};

export default Investment;
