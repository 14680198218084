import { Box, Button, CopyButton, Grid, Modal, TextInput, Text, Image } from "@mantine/core";
import { IconCheckbox, IconCopy, IconDownload, IconFileText } from "@tabler/icons";

// urils
import { imageLink } from "../../functions";

// types
import { Document as DocumentType } from "../../types/response";

export default function FileViewer({ data, onClose, ...props }: { data: DocumentType | null, onClose: () => void, [key: string]: any }) {
    return (
        <>
            <Modal
                opened={data !== null}
                onClose={() => onClose()}
                size="xl"
                title={data?.name}
                centered
                {...props}
            >
                {
                    data && (
                        <Box>
                            <Grid sx={{ width: '100%' }}>
                                <Grid.Col>
                                    {
                                        ['jpg', 'jpeg', 'png'].filter(t => t === data.filename.split('.').pop()).length ? (
                                            <Image width={'100%'} height={'100%'} src={imageLink(data.path)} />
                                        ) : (
                                            <Box
                                                sx={{
                                                    width: '100%',
                                                    height: 200,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        width: '100%',
                                                        height: '100%',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        flexDirection: 'column',
                                                    }}
                                                >
                                                    <IconFileText size={60} />
                                                    <Text size={'xs'} mt={'xs'}>preview not available</Text>
                                                </Box>
                                            </Box>
                                        )
                                    }
                                </Grid.Col>
                                <Grid.Col>
                                    <TextInput
                                        value={imageLink(data.path)}
                                        rightSection={(
                                            <Box
                                                mr={66}
                                                sx={{
                                                    display: 'flex',
                                                }}
                                            >
                                                <Button color={'blue'} size={'xs'} mr={3} onClick={() => {
                                                    var a = document.createElement('a');
                                                    a.href = imageLink(data.path);
                                                    a.target = '_blank';
                                                    a.download = 'download';
                                                    a.click();
                                                }}>
                                                    <IconDownload size={16} />
                                                </Button>
                                                <CopyButton value={imageLink(data.path)}>
                                                    {({ copied, copy }) => (
                                                        <Button color={copied ? 'teal' : 'blue'} onClick={copy} size={'xs'}>
                                                            {copied ? <IconCheckbox size={16} /> : <IconCopy size={16} />}
                                                        </Button>
                                                    )}
                                                </CopyButton>
                                            </Box>
                                        )}
                                    />
                                </Grid.Col>
                            </Grid>
                        </Box>
                    )
                }
            </Modal>
        </>
    )
}