// vendors
import { useState } from 'react';
import { Drawer } from "@mantine/core"
import { showNotification, updateNotification } from '@mantine/notifications';
import { useForm } from "@mantine/form";
import { IconSend, IconAlertCircle, } from '@tabler/icons';
import { useSelector } from 'react-redux';
// utils
import DrawerTitle from "../../../../../components/DrawerTitle";
import { parseError, randomString } from "../../../../../functions";

// api
import { createProject } from "../../../../../api/property";
import { useTranslation } from 'react-i18next';
import FormFields from './components/FormFields';

export default function AddUser(props: any) {

    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const user = useSelector((state: any) => state.user.data);

    const form = useForm({
        initialValues: {
            public: true,
            currency: 'IQD',
        },
    });
    const handleSubmit = (values: any) => {
        const id = `user_${randomString(10)}`;
        setLoading(true);
        showNotification({
            id: id,
            title: t("Creating"),
            message: t("Please wait..."),
            loading: true,
            autoClose: false,
            disallowClose: true,
        });
        createProject({ ...values, account_id: user.id }).then((res) => {
            updateNotification({
                id: id,
                title: t("Success"),
                message: t("Created successfully"),
                color: "green",
                icon: <IconSend />,
                autoClose: true,
            });
            form.reset();
            props?.refreshData();
            props?.onClose();
        }).catch((err) => {
            console.log(err);
            updateNotification({
                id: id,
                title: t("Error while creating"),
                message: parseError(err),
                color: "red",
                autoClose: false,
                icon: <IconAlertCircle />,
            });
        }).finally(() => {
            setLoading(false);
        })

    }
    return (
        <Drawer
            position="right"
            padding="xl"
            size={800}
            {...props}
            onClose={() => {
                props.onClose();
                form.reset();
            }}
        >
            <DrawerTitle>{t("Add")}</DrawerTitle>
            <FormFields
                form={form}
                loading={loading}
                onSubmit={handleSubmit}
                isEdit={false}
                t={t}
            />
        </Drawer>
    )

}