import { combineReducers } from 'redux'

import auth from './reducers/auth'
import theme from './reducers/theme'
import user from './reducers/user'


const rootReducer = combineReducers({
    auth,
    theme,
    user,
})

export default rootReducer
