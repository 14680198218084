const initial = {
    token: '',
}

// Actions
export const setToken = (token) => ({
    type: 'SET_TOKEN',
    payload: token,
})

// Reducer
const Tokens = (state = initial, action) => {
    switch (action.type) {
        case 'SET_TOKEN':
            return {
                token: action.payload,
            };

        default:
            return state;
    }
}

export default Tokens;
