import React from 'react';
import { Button } from "@mantine/core";
import { openModal, closeAllModals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { useTranslation } from 'react-i18next';
import { IconAlertCircle, IconMoodEmpty } from '@tabler/icons';
import { setAsInvestor, setAsNotInvestor } from 'api/customer';
import CustomersSelectField from 'components/CustomersSelectField';
import { parseError } from 'functions';

interface InvestorActionsProps {
    refreshData: () => void;
}

export default function InvestorActions({ refreshData }: InvestorActionsProps) {
    const { t } = useTranslation();

    const handleAddInvestor = () => {
        openModal({
            title: 'تعيين مستثمر',
            children: (
                <CustomersSelectField
                    onlyInvestors={false}
                    label="العضو"
                    placeholder="اختر عضو"
                    onSelect={(customer) => {
                        setAsInvestor(customer.id).then(res => {
                            closeAllModals();
                            refreshData();
                            showNotification({
                                title: t('Success'),
                                message: t('تم تعيين المستثمر بنجاح'),
                                color: 'green',
                                icon: <IconMoodEmpty />,
                            });
                        }).catch(err => {
                            const error = {
                                title: t('Error'),
                                message: parseError(err),
                                color: 'red',
                                icon: <IconAlertCircle />,
                            };
                            showNotification(error);
                            closeAllModals();
                        });
                    }}
                />
            ),
        });
    };

    const handleRemoveInvestor = () => {
        openModal({
            title: 'إلغاء مستثمر',
            children: (
                <CustomersSelectField
                    onlyInvestors={true}
                    label="المستثمر"
                    placeholder="اختر المستثمر"
                    onSelect={(customer) => {
                        setAsNotInvestor(customer.id).then(res => {
                            closeAllModals();
                            refreshData();
                            showNotification({
                                title: t('Success'),
                                message: t('تم إلغاء تعيين المستثمر بنجاح'),
                                color: 'green',
                                icon: <IconMoodEmpty />,
                            });
                        }).catch(err => {
                            const error = {
                                title: t('Error'),
                                message: parseError(err),
                                color: 'red',
                                icon: <IconAlertCircle />,
                            };
                            showNotification(error);
                            closeAllModals();
                        });
                    }}
                />
            ),
        });
    };

    return (
        <>
            <Button mx={20} mb={20} onClick={handleAddInvestor}>
                {t("Add")}
            </Button>
            <Button mb={20} color="red" onClick={handleRemoveInvestor}>
                {t("إلغاء مستثمر")}
            </Button>
        </>
    );
}
