// vendors
import React from 'react';
import {
    Grid,
    TextInput,
    Button,
    Select,
    NumberInput,
    LoadingOverlay,
    Text,
    Textarea,
    ScrollArea,
} from "@mantine/core"
import {
    IconSend,
    IconPrinter,
} from '@tabler/icons';
import { DatePicker } from '@mantine/dates';

// utils
import MediaLibraryField from '../../../../../components/MediaLibraryField';
import CustomersSelectField from '../../../../../components/CustomersSelectField';
import PropertiesSelectField from '../../../../../components/PropertiesSelectField';
import { useNavigate } from 'react-router-dom';
import tafqeet from 'inc/tafqeet';


interface FormFieldsProps {
    form: any;
    data?: any;
    loading: boolean;
    onSubmit: (values: any) => void;
    isEdit: boolean;
    t: (key: string) => string;
}

const FormFields: React.FC<FormFieldsProps> = ({ form, data, loading, onSubmit, isEdit, t }) => {
    const navigate = useNavigate();

    return (
        <form onSubmit={form.onSubmit(onSubmit)}>
            <ScrollArea
                offsetScrollbars
                style={{ height: 'calc(100vh - 170px)' }}
            >
                <Grid gutter="lg" mt={20}>
                    <LoadingOverlay visible={loading} overlayBlur={2} />
                    <Grid.Col span={12}>
                        <TextInput
                            size="xs"
                            label={t("Subject")}
                            placeholder={t("Subject")}
                            defaultValue={data?.subject}
                            {...form.getInputProps('subject')}
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <Textarea
                            size="xs"
                            label={t("Body")}
                            placeholder={t("Body")}
                            defaultValue={data?.body}
                            {...form.getInputProps('body')}
                        />
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <DatePicker
                            locale='ar'
                            size="xs"
                            label={t("Issue Date")}
                            placeholder={t("Issue Date")}
                            defaultValue={data?.issue_date}
                            {...form.getInputProps('issued_at')}
                        />
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <DatePicker
                            locale='ar'
                            size="xs"
                            label={t("Sign Date")}
                            placeholder={t("Sign Date")}
                            defaultValue={data?.sign_date}
                            {...form.getInputProps('signed_at')}
                        />
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <NumberInput
                            size="xs"
                            label={t("Price")}
                            placeholder={t("Price")}
                            defaultValue={data?.price}
                            icon={(<Text size='xs'>IQD</Text>)}
                            // {...form.getInputProps('price')}
                            parser={(value: any) => value.replace(/[^\d]/g, '')}
                            onChange={(val: any) => {
                                form.setFieldValue('price', val);
                                form.setFieldValue('price_literal', tafqeet(val));
                            }}
                            formatter={(value: any) =>
                                !Number.isNaN(parseFloat(value))
                                    ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                    : ''
                            }
                        />
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <TextInput
                            size="xs"
                            label={t("Price Literal")}
                            placeholder={t("Price Literal")}
                            defaultValue={data?.price_literal}
                            {...form.getInputProps('price_literal')}
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <Select
                            size="xs"
                            label={t("نوع العقد")}
                            placeholder={t("نوع العقد")}
                            data={[
                                { label: t("إيجار"), value: "rent" },
                                { label: t("بيع أو شراء"), value: "sale" },
                            ]}
                            defaultValue={data?.contract_type}
                            {...form.getInputProps('contract_type')}
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <CustomersSelectField
                            size="xs"
                            label={t("Customer")}
                            placeholder="customer"
                            defaultValue={data?.customer?.id}
                            {...form.getInputProps('customer')}
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <PropertiesSelectField
                            size="xs"
                            label={t("Property")}
                            placeholder={t("property")}
                            defaultValue={data?.property?.id}
                            {...form.getInputProps('property')}
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <MediaLibraryField
                            inputProps={{
                                withAsterisk: true,
                                size: "xs",
                                label: t("Documents"),
                                placeholder: t("Documents"),
                            }}
                            multiple={true}
                            defaultValue={data?.documents}
                            {...form.getInputProps('documents')}
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <Button
                            size="xs"
                            leftIcon={<IconSend size={16} />}
                            type="submit"
                        >
                            {isEdit ? t("Save") : t("Create")}
                        </Button>
                        {isEdit ?
                            <Button
                                size="xs"
                                mx={'xs'}
                                leftIcon={<IconPrinter size={16} />}
                                onClick={() => {
                                    navigate(`/app/contracts/print`, {
                                        state: data,
                                    });
                                }}
                            >
                                {t("Print")}
                            </Button> : null}

                    </Grid.Col>
                </Grid>

            </ScrollArea>
        </form>
    );
};

export default FormFields;
