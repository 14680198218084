// vendors
import { useState } from "react";
import {
    Button,
    Drawer, Grid, Select,
} from "@mantine/core"
import { useTranslation } from "react-i18next";

export default function FilterDrawer(props: {
    opened: boolean,
    onClose: () => void,
    onApply?: (filter: { [key: string]: string }) => void,
}) {

    const { t } = useTranslation();
    const [filter, setFilter] = useState<{ key: string, value: string }[]>([]);

    const addFilter = (key: string, value: any) => {
        setFilter([...filter.filter(f => f.key !== key), { key, value }]);
    }

    const removeFilter = (key: string) => {
        setFilter(filter.filter(f => f.key !== key));
    }

    return (
        <Drawer
            position="left"
            padding="xl"
            size={300}
            withOverlay={false}
            {...props}
            onClose={() => {
                props.onClose();
            }}
            title={t("Filter")}
        >
            <Grid>
                <Grid.Col span={12}>
                    <Select
                        size="xs"
                        label={t("نوع الفاتورة")}
                        data={[
                            { label: t("All"), value: "all" },
                            { value: 'sales', label: t("مبيعات") },
                            { value: 'investment', label: t("استثمارات") },
                        ]}
                        defaultValue="all"
                        onChange={(value) => {
                            if (value === "all") removeFilter("invoice_type");
                            else addFilter("invoice_type", value);
                        }}
                    />
                </Grid.Col>
                <Grid.Col span={12}>
                    <Select
                        size="xs"
                        label={t("إتجاه الفاتورة")}
                        data={[
                            { label: t("All"), value: "all" },
                            { value: 'in', label: t("إيداع") },
                            { value: 'out', label: t("سحب") },
                        ]}
                        defaultValue="all"
                        onChange={(value) => {
                            if (value === "all") removeFilter("direction");
                            else addFilter("direction", value);
                        }}
                    />
                </Grid.Col>
                <Grid.Col span={12}>
                    <Button
                        size="xs"
                        onClick={() => {
                            props.onApply && props.onApply(
                                filter.reduce((acc: { [key: string]: string }, f) => {
                                    acc[f.key] = f.value;
                                    return acc;
                                }, {})
                            );
                        }}
                    >
                        {t("Apply")}
                    </Button>
                </Grid.Col>
            </Grid>
        </Drawer>
    )

}