import { apiWithInterceptor, apiWithoutInterceptor } from '.';

// get
export const getMe = () => apiWithInterceptor.get(`/account/me`)
export const getUsers = (page: number = 1, limit: number = -1, search: string = '') => apiWithInterceptor.get(`/account/`, { params: { page, limit, search } })
export const requestPasswordReset = () => apiWithInterceptor.get(`/account/password/reset`)
export const passwordReset = (token: string, password: string) => apiWithInterceptor.post(`/account/password/reset/`, { token, password })
export const getUser = (id: string) => apiWithInterceptor.get(`/account/${id}`)
export const getPermissions = () => apiWithInterceptor.get(`/account/permissions`, { params: { limit: -1, page: 1 } })

// post 
export const createUser = (data: any) => apiWithInterceptor.post(`/account/`, { ...data, company: '0' })
export const forgotPassword = (email: string) => apiWithoutInterceptor.post(`/account/password/forgot`, { email })
export const changePassword = (password: string, old_password: string) => apiWithInterceptor.post(`/account/password/change`, { password, old_password })

// delete
export const deleteUser = (id: string) => apiWithInterceptor.delete(`/account/${id}`, { params: { approved: true } })

// put
export const updateUser = (id: string, data: any) => apiWithInterceptor.put(`/account/${id}`, data)
export const updateMe = (data: {}) => apiWithInterceptor.put(`/account/me`, data)