import React from 'react';
import { DateRangePicker } from '@mantine/dates';

interface DateRangeSelectorProps {
    dateRange: [Date, Date];
    setDateRange: (range: [Date, Date]) => void;
}

const DateRangeSelector: React.FC<DateRangeSelectorProps> = ({ dateRange, setDateRange }) => {
    return (
        <DateRangePicker
            value={dateRange}
            onChange={setDateRange}
            placeholder="date range"
        />
    );
};

export default DateRangeSelector;
