import React from 'react';
import { useDraggable } from '@dnd-kit/core';
import { IconGripVertical } from '@tabler/icons';

interface DraggableProps {
  id: string;
  children: React.ReactNode;
}

const Draggable: React.FC<DraggableProps> = ({ id, children }) => {
  const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform } = useDraggable({
    id,
  });
  
  const style = transform ? {transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,} : "";
  return (
    <div ref={setNodeRef} style={{ display: 'flex', ...style }}>
      <button
        ref={setActivatorNodeRef}
        style={{
          background: 'none',
          padding: '0',
          border: 'none',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        {...listeners}
        {...attributes}
      >
        <IconGripVertical />
      </button>
      {children}
    </div>
  );
};

export default Draggable;
