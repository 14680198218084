// vendors
import { useState, useEffect } from 'react';
import {
    Drawer,
    Text,
} from "@mantine/core"
import { showNotification, updateNotification } from '@mantine/notifications';
import { useForm } from "@mantine/form";
import {
    IconAlertCircle,
    IconSend,
}
    from '@tabler/icons';
import dayjs from "dayjs";
import { useTranslation } from 'react-i18next';

// utils
import DrawerTitle from "../../../../../components/DrawerTitle";
import { parseError, randomString } from "../../../../../functions";

// api
import { deleteProject, updateProject, publishProject, unpublishProject } from "../../../../../api/property";
import EditMenu from '../../Users/shared/components/EditMenu';
import FormFields from './components/FormFields';
export default function EditUser(props: any) {

    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();

    const form = useForm();
    const data = props?.data;
    useEffect(() => {
        if (data) {
            form.setValues({ documents: data?.documents });
        } else {
            form.reset();
        }
    }, [data]);
    const handleSubmit = (values: any) => {
        if (values.birthdate) values.birthdate = (new Date(values.birthdate as string)).toISOString().split('T')[0];
        const id = `user_${randomString(10)}`;
        setLoading(true);
        showNotification({
            id: id,
            title: t("Updating"),
            message: t("Please wait..."),
            loading: true,
            autoClose: false,
            disallowClose: true,
        });
        updateProject(props?.data?.id, values).then(res => {
            updateNotification({
                id: id,
                title: t("Success"),
                message: t("User updated successfully"),
                color: "green",
                icon: <IconSend />,
                autoClose: true,
            });
            form.reset();
            props?.refreshData();
            props?.onClose();
        }).catch(err => {
            console.log(err);
            updateNotification({
                id: id,
                title: t("Error while updating"),
                message: parseError(err),
                color: "red",
                autoClose: false,
                icon: <IconAlertCircle />,
            });
        }).finally(() => {
            setLoading(false);
        });
    }


    const handleDelete = async () => {
        return deleteProject(props.data.id);
    };

    const handleAction = async () => {
        if (props.data?.public) {
            return unpublishProject(props.data.id);
        } else {
            return publishProject(props.data.id);
        }
    };

    return (
        <Drawer
            position="right"
            padding="xl"
            size={800}
            {...props}
            onClose={() => {
                props.onClose();
                form.reset();
            }}
        >
            <DrawerTitle>{t("Edit")}</DrawerTitle>
            {props.data && (
                <EditMenu
                    onDelete={handleDelete}
                    onAction={handleAction}
                    isPublic={props.data?.public}
                    buttonLabel={t('خيارات')}
                    actionLabel={props.data?.public ? t("Unpublish") : t("Publish")}
                    deleteLabel={t("Delete")}
                    isActionable={true}
                    refreshData={props.refreshData}
                    onClose={props.onClose}
                />
            )}

            <Text size={'sm'} mt={'lg'}>
                {dayjs(props?.data?.created_at).format('MMMM D, YYYY h:mm A')}
            </Text>
            {data && (
                <FormFields
                    form={form}
                    data={data}
                    loading={loading}
                    onSubmit={handleSubmit}
                    isEdit={true}
                    t={t}
                />
            )}

        </Drawer >
    )

}