// AddDrawer.tsx

import { useState } from 'react';
import { Drawer } from '@mantine/core';
import DrawerTitle from '../../../../../components/DrawerTitle';
import CityForm from './components/CityForm';
import { showNotification, updateNotification } from '@mantine/notifications';
import { IconAlertCircle, IconSend } from '@tabler/icons';
import { useTranslation } from 'react-i18next';

// utils
import { createCity } from 'api/city';
import { parseError, randomString } from "../../../../../functions";

export default function AddDrawer(props: any) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const handleAddCity = (values: any, resetForm: () => void) => {
    const id = `city_${randomString(10)}`;
    setLoading(true);
    showNotification({
      id: id,
      title: t("Adding city..."),
      message: t("Please wait..."),
      loading: true,
      autoClose: false,
      disallowClose: true,
    });

    createCity(values.name).then(() => {
      updateNotification({
        id: id,
        title: t('Success'),
        message: t("Successfully added city"),
        color: "green",
        icon: <IconSend />,
        autoClose: true,
      });
      resetForm();
      props?.refreshData();
      props?.onClose();
    }).catch((err) => {
      updateNotification({
        id: id,
        title: t("Failed to add city"),
        message: parseError(err),
        color: "red",
        autoClose: false,
        icon: <IconAlertCircle />,
      });
    }).finally(() => {
      setLoading(false);
    });
  };

  return (
    <Drawer
      position="right"
      padding="xl"
      size={800}
      {...props}
      onClose={() => {
        props.onClose();
      }}
    >
      <DrawerTitle>
        {t('Add')}
      </DrawerTitle>
      <CityForm
        initialValues={{ name: "" }}
        onSubmit={handleAddCity}
        loading={loading}
        setLoading={setLoading}
        submitLabel={t("Create")}
      />
    </Drawer>
  );
}
