import { useState, useEffect } from 'react';

export function useDetectPrint() {
  const [isPrinting, setIsPrinting] = useState(false);

  const handleBeforePrint = () => setIsPrinting(true);
  const handleAfterPrint = () => setIsPrinting(false);

  useEffect(() => {
    window.addEventListener('beforeprint', handleBeforePrint);
    window.addEventListener('afterprint', handleAfterPrint);
    return () => {
      window.removeEventListener('beforeprint', handleBeforePrint);
      window.removeEventListener('afterprint', handleAfterPrint);
    };
  }, []);

  return isPrinting;
}
