import { useEffect, useState } from "react";
import { Container, Grid, LoadingOverlay } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import { useTranslation } from "react-i18next";
import { getMe, updateMe } from "../../../../api/user";
import { User } from "../../../../types/response";
import ProfileHeader from "./components/ProfileHeader";
import ProfileForm from "./components/ProfileForm";
import ScreenTimeoutInput from "./components/ScreenTimeoutInput";
import LogoutButton from "./components/LogoutButton";

export default function Profile() {
    const [user, setUser] = useState<User | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const form = useForm({});
    const [screenTimeout, setScreenTimeout] = useLocalStorage({ key: 'screen-timeout', defaultValue: 30 });

    const loadUser = () => {
        setLoading(true);
        getMe().then((res) => {
            setUser(res.data.account);
            form.setValues({
                firstname: res.data.account.firstname,
                lastname: res.data.account.lastname,
                firstname_ar: res.data.account.firstname_ar,
                lastname_ar: res.data.account.lastname_ar,
                email: res.data.account.email,
                phone_number: res.data.account.phone_number,
                birthdate: new Date(res.data.account.birthdate),
                address: res.data.account.address,
                marital_status: res.data.account.marital_status,
                job_title: res.data.account.job_title,
                education: res.data.account.education,
                salary: res.data.account.salary,
            });
            setLoading(false);
        }).catch(() => setLoading(false));
    };

    useEffect(() => loadUser(), []);

    const handleSubmit = (values: any) => {
        setLoading(true);
        updateMe(values).then(() => {
            form.reset();
            loadUser();
        }).catch(err => {
            setLoading(false);
            showNotification({
                title: t('Failed to update profile'),
                message: err.response?.data?.message || t('Unknown error'),
                color: 'red',
                autoClose: false,
            });
        });
    };

    return (
        <Container my={100}>
            <LoadingOverlay visible={loading} overlayBlur={1} />
            <Grid>
                <Grid.Col>
                    <ProfileHeader user={user} />
                </Grid.Col>
                <Grid.Col>
                    <ProfileForm form={form} onSubmit={handleSubmit} />
                </Grid.Col>
                <Grid.Col>
                    <ScreenTimeoutInput screenTimeout={screenTimeout} setScreenTimeout={setScreenTimeout} />
                </Grid.Col>
                <Grid.Col>
                    <LogoutButton navigate={navigate} />
                </Grid.Col>
            </Grid>
        </Container>
    );
}
