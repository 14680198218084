import { Box } from '@mantine/core';
import React, { useEffect, useRef } from 'react';
import { elementIsVisibile } from '../functions';

export default function Visible({
    onVisibilityChange,
}: {
    onVisibilityChange: (status: boolean) => void;
}) {
    const loadMoreRef = React.useRef(null);
    const [offset, setOffset] = React.useState(0);
    const isVisibile = useRef(false);

    useEffect(() => {
        document.addEventListener('scroll', () =>
            setOffset(window.pageYOffset)
        );
        return () =>
            document.removeEventListener('scroll', () => {
                console.log('remove');
            });
    }, []);

    useEffect(() => {
        if (loadMoreRef.current) {
            // if (!elementIsVisibile(loadMoreRef.current)) return;
            if (elementIsVisibile(loadMoreRef.current)) {
                if (!isVisibile.current) {
                    onVisibilityChange(true);
                    isVisibile.current = true;
                }
            } else {
                if (isVisibile) {
                    onVisibilityChange(false);
                    isVisibile.current = false;
                }
            }
        }
    }, [offset]);

    return <Box ref={loadMoreRef} />;
}
