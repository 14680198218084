import React, { useEffect } from "react";
import { Select } from "@mantine/core";

// api
import { getAssets } from "api/assets";

// types
import { Asset as AssetType } from "../types/response";

export default function AssetSelectField({
    defaultData = [],
    ...props
}: {
    defaultData?: AssetType[];
    [key: string]: any;
}) {

    const [data, setData] = React.useState<AssetType[]>(defaultData);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [search, setSearch] = React.useState<string>('');
    const [value, setValue] = React.useState<string | null>(props.defaultValue ? props.defaultValue : null);

    useEffect(() => {
        if (defaultData.length > 0 && search === '') {
            setData(defaultData);
            return;
        }
        setLoading(true);
        getAssets(1, 10).then((res) => {
            setData(res.data.assets);
        }).catch((err) => {

        }).finally(() => {
            setLoading(false);
        });
    }, [search]);

    return (
        <Select
            label="الحساب"
            placeholder={loading ? 'جار التحميل...' : 'اختر الحساب'}
            {...props}
            data={[
                ...data?.map((asset) => ({
                    label: `${asset.name}`,
                    value: asset.id,
                }))
            ]}
            value={value}
            onChange={(v) => {
                props.onChange && props.onChange(v);
                setValue(v);
            }}
        />
    )

}