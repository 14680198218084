// vendors
import React, { useEffect } from 'react';
import {
    Drawer,
} from "@mantine/core"
import { showNotification, updateNotification } from '@mantine/notifications';
import { useForm } from "@mantine/form";
import {
    IconSend,
    IconX,
} from '@tabler/icons';

// utils
import DrawerTitle from "../../../../../components/DrawerTitle";


// api
import { deleteInvoice, updateInvoice } from '../../../../../api/invoice';
import { parseError, } from '../../../../../functions';
import { useTranslation } from 'react-i18next';
import EditMenu from '../../Users/shared/components/EditMenu';
import FormFields from './components/FormFields';

export default function AddData(props: {
    opened: boolean,
    onClose: () => void,
    refreshData: () => void,
    invoice: any,
}) {

    const [loading, setLoading] = React.useState(false);
    const { t } = useTranslation();
    const form = useForm({
        initialValues: {},
    });

    useEffect(() => {
        !props.invoice && form.reset();
        props.invoice && form.setFieldValue('issued_at', new Date(props.invoice.issued_at));
    }, [props.invoice]);
    const handleSubmit = (values: any) => {
        setLoading(true);
        showNotification({
            id: props.invoice.id,
            title: t('Updating'),
            message: t('Please wait...'),
            color: 'blue',
            loading: true,
            autoClose: false,
        })
        updateInvoice(props.invoice.id, values).then(() => {
            updateNotification({
                id: props.invoice.id,
                title: t('Success'),
                message: t('Updated successfully'),
                color: 'teal',
                icon: <IconSend />,
                loading: false,
            });
            props.onClose();
            form.reset();
            props.refreshData();
        }).catch((err) => {
            updateNotification({
                id: props.invoice.id,
                title: t('Error'),
                message: parseError(err),
                color: 'red',
                icon: <IconX />,
                loading: false,
            });
        }).finally(() => {
            setLoading(false);
        });


    }

    return (
        <Drawer
            position="right"
            padding="xl"
            size={'xl'}
            {...props}
            onClose={() => {
                props.onClose();
                form.reset();
            }}
        >
            <EditMenu
                onDelete={() => deleteInvoice(props.invoice.id)}
                buttonLabel="خيارات"
                deleteLabel="حذف"
                refreshData={props.refreshData}
                onClose={props.onClose}
            />
            {
                !props.invoice ? null : (
                    <>
                        <DrawerTitle>
                            {t("Edit")}
                        </DrawerTitle>
                        <FormFields
                            form={form}
                            data={props}
                            loading={loading}
                            onSubmit={handleSubmit}
                            isEdit={true}
                            t={t}
                        />
                    </>
                )
            }
        </Drawer>
    )

}