import { Box, MultiSelect, MultiSelectProps, UnstyledButton } from "@mantine/core";
import { useSetState } from "@mantine/hooks";
import React, { useEffect, useState } from "react";

// urils
import MediaLibrary from "./MediaLibrary";
import FileViewer from "./FileViewer";
import FieldValue from "./FieldValue";

// types
import { Document as DocumentType } from "../../types/response";
import { IconCloudUpload } from "@tabler/icons";

export default function MediaLibraryField(
    {
        inputProps = {
            data: [],
        },
        multiple = false,
        filename = "",
        name = "document",
        onChange = (data: DocumentType[]) => { },
        defaultValue = [],
    }: {
        inputProps?: MultiSelectProps,
        multiple?: boolean | undefined,
        filename?: string,
        name?: string,
        onChange?: (data: DocumentType[]) => void,
        defaultValue?: DocumentType[],
    }
) {

    const [mediaLibraryState, setMediaLibraryState] = useSetState({
        opened: false,
        onClose: () => setMediaLibraryState({ opened: false }),
    });

    const [viewImage, setViewImage] = useState<number>(-1);

    const [files, setFiles] = React.useState<DocumentType[]>(defaultValue);

    useEffect(() => {
        onChange(files.map(file => ({
            id: file.id,
            name: name,
        })) as never);
    }, [files]);

    return (
        <Box>
            <MediaLibrary
                opened={mediaLibraryState.opened}
                onClose={() => setMediaLibraryState({ opened: false })}
                filename={filename}
                onSelect={(newFiles) => {
                    if (multiple) {
                        setFiles([
                            ...files,
                            ...newFiles.filter(file => files.filter(f => f.id === file.id).length === 0) as never,
                        ]);
                    } else {
                        setFiles([
                            ...newFiles
                        ]);
                    }
                }}
                onDeselect={(file) => {
                    setFiles(files.filter(f => f.id !== file.id) as never);
                }}
                defaultSelected={files}
                multiple={multiple}
            />
            <UnstyledButton
                                    style={{ paddingRight: '10px' }} 
                onClick={() => setMediaLibraryState({ opened: true })}
                sx={{ width: '100%' }}
            >
                <MultiSelect
                
                    {...inputProps}
                    onClick={event => {
                        event.preventDefault();

                    }}
                    data={files.map(file => ({
                        label: file.filename,
                        value: file.id,
                        data: file,
                        onClick: (data: DocumentType) => {
                            setViewImage(files.findIndex(file => file.id === data.id))
                        },
                    }))}
                    value={files.map(file => file.id)}
                    dropdownComponent={() => null}
                    icon={<IconCloudUpload size={16} />}
                    rightSection={<></>}
                    valueComponent={FieldValue}
                    defaultValue={defaultValue?.map((file: DocumentType) => file.id)}
                    onChange={(value) => {
                        setFiles(files.filter(file => value.includes(file.id)));
                    }}
                />
            </UnstyledButton>
            <FileViewer
                data={viewImage !== -1 ? files[viewImage] : null}
                onClose={() => setViewImage(-1)}
            />
        </Box>
    )

}