import dayjs from 'dayjs';
import { apiWithInterceptor } from '.';

import { Letter as LetterType } from '../types/payload';

// get
export const getLetters = (page :number = 1, limit: number = -1, search: string = '' ) => apiWithInterceptor.get(`/letter/`, { params: {page, limit, search} })
export const getLetter = (id: string) => apiWithInterceptor.get(`/letter/${id}`)

// post
export const createLetter = (data: LetterType) => apiWithInterceptor.post(`/letter/`, {...data, issued_at: dayjs(data.issued_at).format('YYYY-MM-DD')})

// delete
export const deleteLetter = (id: string) => apiWithInterceptor.delete(`/letter/${id}`, { params: {approved: true} })

// put
export const updateLetter = (id: string, data: any) => apiWithInterceptor.put(`/letter/${id}`, {...data, issued_at: dayjs(data.issued_at).format('YYYY-MM-DD')})