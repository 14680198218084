import axios, { CancelTokenSource } from 'axios';
import dayjs from 'dayjs';
import { apiWithInterceptor, apiWithoutInterceptor } from '.';

let cancelTokens: { [key: string]: CancelTokenSource } = {};

// get
export const getProperties = ( page :number = 1, limit: number = -1, search: string = '', within_window = '', params?: {[key: string]: string} ) => {
    cancelTokens.getProperties && cancelTokens.getProperties.cancel();
    cancelTokens.getProperties = axios.CancelToken.source();
    return apiWithInterceptor.get(`/property/`, { params: {...params, page, limit, search, ...(within_window ? {within_window} : {}) } });
}
export const getAllPublic = ( page :number = 1, limit: number = -1 ) => apiWithoutInterceptor.get(`/property/`, { params: {page, limit} })
export const getProperty = (id: string) => apiWithInterceptor.get(`/property/${id}`)
export const getProjects = (page :number = 1, limit: number = -1, search: string = '') => apiWithInterceptor.get(`/project/`)
export const getPropertiesReport = (since: Date, until: Date) => apiWithInterceptor.get(`/property/report`, { params: {
    empty_array: 1,
    since: dayjs(since).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
    until: dayjs(until).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
} })
export const getPropertiesRemainingReport = (since: Date, until: Date) => apiWithInterceptor.get(`/property/report/remaining`, { params: {
    since: dayjs(since).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
    until: dayjs(until).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
} })


// post
export const createProperty = (data: {}) => apiWithInterceptor.post(`/property/`, {...data})
export const createProject = (data: {}) => apiWithInterceptor.post(`/project/`, {...data})

// delete
export const deleteProperty = (id: string) => apiWithInterceptor.delete(`/property/${id}`, { params: {approved: true} })
export const deleteProject = (id: string) => apiWithInterceptor.delete(`/project/${id}`, { params: {approved: true} })

// put
export const updateProperty = (id: string, data: {}) => apiWithInterceptor.put(`/property/${id}`, data)
export const updateProject = (id: string, data: {}) => apiWithInterceptor.put(`/project/${id}`, data)
export const publishProject = (id: string) => apiWithInterceptor.put(`/project/${id}/publish`)
export const unpublishProject = (id: string) => apiWithInterceptor.put(`/project/${id}/unpublish`)
export const publishProperty = (id: string) => apiWithInterceptor.put(`/property/${id}/publish`)
export const unpublishProperty = (id: string) => apiWithInterceptor.put(`/property/${id}/unpublish`)
export const approvedProperty = (id: string) => apiWithInterceptor.put(`/property/${id}/approve`)
export const rejectProperty = (id: string) => apiWithInterceptor.put(`/property/${id}/refuse`)
export const sellProperty = (id: string, invoice_id: string) => apiWithInterceptor.put(`/property/${id}/sell`, {invoice: invoice_id})
export const unSellProperty = (id: string) => apiWithInterceptor.put(`/property/${id}/unsell`)
export const reserveProperty = (id: string) => apiWithInterceptor.put(`/property/${id}/reserve`)
export const unReserveProperty = (id: string) => apiWithInterceptor.put(`/property/${id}/unreserve`)
