// vendors
import { useEffect, useRef, useState } from "react";
import {
    Drawer,
    Select,
    ScrollArea,
    Button,
    Menu,
} from "@mantine/core"
import { showNotification, updateNotification } from '@mantine/notifications';
import { useForm } from "@mantine/form";
import {
    IconSend,
    IconAlertCircle,
    IconTrash,
} from '@tabler/icons';

// utils
import DrawerTitle from "../../../../../components/DrawerTitle";

// types
import { Area, Invoice, User as UserType } from "../../../../../types/response";
import { geoToLatLng, latLngAverage, parseError, randomString } from "../../../../../functions";

// api
import {
    updateProperty,
    publishProperty,
    unpublishProperty,
    approvedProperty,
    rejectProperty,
    sellProperty,
    unSellProperty,
    reserveProperty,
    unReserveProperty,
    deleteProperty,
} from "../../../../../api/property";
import { getInvoices } from "../../../../../api/invoice";
import { useSelector } from "react-redux";
import { t } from "i18next";
import { closeAllModals, openModal } from "@mantine/modals";
import { useDebouncedValue } from "@mantine/hooks";
import FormFields from "./components/FormFields";
export default function EditDrawer(props: {
    id: string,
    opened: boolean,
    onClose: () => void,
    data: Area,
    refreshData: () => void,
    updateAreaPoints: (area: Area) => void,
}) {

    const [loading, setLoading] = useState(false);
    const [invoices, setInvoices] = useState<Invoice[]>([]);
    const user: UserType = useSelector((state: any) => state.user.data);
    const [invoiceSearchTerm, setInvoiceSearchTerm] = useState('');
    const [debouncedInvoiceSearchTerm] = useDebouncedValue(invoiceSearchTerm, 200);

    const form = useForm();

    const data = props.data;
    useEffect(() => {
        form.setValues({ documents: data?.documents });
    }, [data])

    useEffect(() => { !data && form.reset() }, [data]);

    useEffect(() => {
        getInvoices(1, 20, "", {
            direction: "in",
            empty_array: "true",
        }).then(res => {
            setInvoices(res.data.invoices);
        });
    }, [debouncedInvoiceSearchTerm]);
    const handleSubmit = (vals: any) => {

        const geometry = props.data.geometry;
        const avg = latLngAverage(geoToLatLng(geometry));
        const geocode = avg.lng() + "," + avg.lat()

        const id = `update_prop_${randomString(10)}`;
        setLoading(true);
        showNotification({
            id: id,
            title: t("Updating"),
            message: t("Please wait..."),
            loading: true,
            autoClose: false,
            disallowClose: true,
        });
        updateProperty(props.data.id, { ...vals, account_id: user.id, geometry, geocode }).then(res => {
            updateNotification({
                id: id,
                title: t("Success"),
                message: t("Created successfully"),
                color: "green",
                icon: <IconSend />,
                autoClose: true,
            });
            props?.refreshData();
            props?.onClose();
        }).catch(err => {
            console.log(err);
            updateNotification({
                id: id,
                title: t("Error while creating"),
                message: parseError(err),
                color: "red",
                autoClose: false,
                icon: <IconAlertCircle />,
            });
        }).finally(() => {
            setLoading(false);

        });

    }
    const [selectedInvoice, setSelectedInvoice] = useState<Invoice | undefined>(undefined);
    const selectedInvoiceRef = useRef<Invoice | undefined>(selectedInvoice);

    const HandleInvoiceFinding = (value: any) => {
        const foundInvoice = invoices.find((invoice) => invoice.id === value);
        setSelectedInvoice(foundInvoice);
        selectedInvoiceRef.current = foundInvoice; // Update the ref to the latest selected invoice
    };
    return (
        <Drawer
            position="right"
            padding="xl"
            size={800}
            {...props}
            onClose={() => {
                props.onClose();
                form.reset();
            }}
        >
            {
                !props.opened ? null : (
                    <>
                        <DrawerTitle>{t("Edit")}</DrawerTitle>
                        <Menu shadow="md" width={200}>
                            <Menu.Target>
                                <Button size="xs" my={20}>{t('خيارات')}</Button>
                            </Menu.Target>

                            <Menu.Dropdown>
                                <Menu.Item
                                    onClick={() => {
                                        if (data?.status === "sold") {
                                            // unsell
                                            const id = randomString(10);
                                            showNotification({
                                                id: id,
                                                title: t("جار الغاء البيع"),
                                                message: t("يرجى الإنتظار..."),
                                                loading: true,
                                                autoClose: false,
                                                disallowClose: true,
                                            });
                                            unSellProperty(props.data.id).then(res => {
                                                updateNotification({
                                                    id: id,
                                                    title: t("نجاح"),
                                                    message: t("تم الغاء البيع بنجاح"),
                                                    color: "green",
                                                    icon: <IconSend />,
                                                    autoClose: true,
                                                });
                                                props?.refreshData();
                                                props?.onClose();
                                            }).catch(err => {
                                                console.log(err);
                                                updateNotification({
                                                    id: id,
                                                    title: t("فشل"),
                                                    message: parseError(err),
                                                    color: "red",
                                                    autoClose: false,
                                                    disallowClose: false,
                                                });
                                            });
                                        } else {
                                            // open sell dialog to select invoice
                                            // sell
                                            // setLoadingInvoice(true);
                                            getInvoices(1, 20, "", {
                                                direction: "in",
                                                empty_array: "true",
                                            }).then(res => {
                                                // props?.refreshData();
                                                // setLoadingInvoice(false);
                                                setInvoices(res.data.invoices);
                                                setTimeout(() => {
                                                    openModal({
                                                        title: 'اختر الفاتورة',
                                                        children: (
                                                            <>
                                                                <Select
                                                                    label="اختر الفاتورة"
                                                                    onChange={HandleInvoiceFinding}

                                                                    data={[
                                                                        ...invoices.map((invoice) => ({
                                                                            label: `${invoice.from_to} - ${invoice.amount} (${t(invoice.subject)}) - ${invoice.created_at}`,
                                                                            value: invoice.id,
                                                                        }))
                                                                    ]}
                                                                    searchable
                                                                    clearable
                                                                    onSearchChange={(value) => {
                                                                        setInvoiceSearchTerm(value);
                                                                    }}
                                                                />
                                                                <Button
                                                                    fullWidth
                                                                    onClick={() => {
                                                                        const currentInvoice = selectedInvoiceRef.current
                                                                        if (!currentInvoice) {
                                                                            showNotification({
                                                                                title: t("فشل"),
                                                                                message: t("يرجى اختيار الفاتورة"),
                                                                                color: "red",
                                                                                autoClose: true,
                                                                            });
                                                                            return;
                                                                        }
                                                                        const id = randomString(10);
                                                                        showNotification({
                                                                            id: id,
                                                                            title: t("جار البيع"),
                                                                            message: t("يرجى الإنتظار..."),
                                                                            loading: true,
                                                                            autoClose: false,
                                                                            disallowClose: true,
                                                                        });
                                                                        sellProperty(props.data.id, currentInvoice.id).then(res => {
                                                                            props?.refreshData();
                                                                            updateNotification({
                                                                                id: id,
                                                                                title: t("نجاح"),
                                                                                message: t("تم البيع بنجاح"),
                                                                                color: "green",
                                                                                icon: <IconSend />,
                                                                                autoClose: true,
                                                                            });
                                                                            props?.refreshData();
                                                                            props?.onClose();
                                                                            closeAllModals();
                                                                        }).catch(err => {
                                                                            console.log(err);
                                                                            updateNotification({
                                                                                id: id,
                                                                                title: t("فشل"),
                                                                                message: parseError(err),
                                                                                color: "red",
                                                                                autoClose: false,
                                                                                disallowClose: false,
                                                                            });
                                                                        });
                                                                    }}
                                                                    mt="md"
                                                                >
                                                                    موافق
                                                                </Button>
                                                            </>
                                                        ),
                                                        zIndex: 1000,
                                                    });
                                                }, 100);
                                            }).catch(err => {
                                                showNotification({
                                                    title: t("فشل"),
                                                    message: parseError(err),
                                                    color: "red",
                                                    autoClose: false,
                                                    disallowClose: false,
                                                });
                                            });
                                        }
                                    }}
                                >
                                    {
                                        data?.status === "sold" ? 'إلغاء البيع' : 'بيع'
                                    }
                                </Menu.Item>
                                <Menu.Item
                                    disabled={data?.status === "sold"}
                                    onClick={() => {
                                        const id = randomString(10);
                                        const method = data?.status === "reserved" ? unReserveProperty : reserveProperty;
                                        showNotification({
                                            id: id,
                                            title: data?.status === "reserved" ? t("جار الغاء الحجز") : t("جار الحجز"),
                                            message: t("يرجى الإنتظار..."),
                                            loading: true,
                                            autoClose: false,
                                            disallowClose: true,
                                        });
                                        method(props.data.id).then(res => {
                                            updateNotification({
                                                id: id,
                                                title: t("نجاح"),
                                                message: data?.status === "reserved" ? t("تم الغاء الحجز بنجاح") : t("تم الحجز بنجاح"),
                                                color: "green",
                                                icon: <IconSend />,
                                                autoClose: true,
                                            });
                                            props?.refreshData();
                                            props?.onClose();
                                        }).catch(err => {
                                            console.log(err);
                                            updateNotification({
                                                id: id,
                                                title: t("فشل"),
                                                message: parseError(err),
                                                color: "red",
                                                autoClose: false,
                                                disallowClose: false,
                                            });
                                        });
                                    }}>
                                    {
                                        data?.status === "reserved" ? 'إلغاء الحجز' : 'حجز'
                                    }
                                </Menu.Item>
                                {
                                    data?.public === false ? (
                                        <Menu.Item
                                            onClick={() => {
                                                const id = randomString(10);
                                                showNotification({
                                                    id: id,
                                                    title: t("Publishing"),
                                                    message: t("Please wait..."),
                                                    loading: true,
                                                    autoClose: false,
                                                    disallowClose: true,
                                                });
                                                publishProperty(props.data.id).then(res => {
                                                    updateNotification({
                                                        id: id,
                                                        title: t("Success"),
                                                        message: t("Published successfully"),
                                                        color: "green",
                                                        icon: <IconSend />,
                                                        autoClose: true,
                                                    });
                                                    props?.refreshData();
                                                    props?.onClose();
                                                }).catch(err => {
                                                    console.log(err);
                                                    updateNotification({
                                                        id: id,
                                                        title: t("Error while publishing"),
                                                        message: parseError(err),
                                                        color: "red",
                                                        autoClose: false,
                                                        icon: <IconAlertCircle />,
                                                    });
                                                }).finally(() => {
                                                    setLoading(false);
                                                });
                                            }}
                                        >
                                            {t("Publish")}
                                        </Menu.Item>
                                    ) : (
                                        <Menu.Item
                                            // variant="light"
                                            // color="red"
                                            // mt={'xs'}
                                            // px={'xl'}
                                            // mx={'xs'}
                                            // mb={'md'}
                                            // size="xs"
                                            onClick={() => {
                                                const id = randomString(10);
                                                showNotification({
                                                    id: id,
                                                    title: t("Unpublishing"),
                                                    message: t("Please wait..."),
                                                    loading: true,
                                                    autoClose: false,
                                                    disallowClose: true,
                                                });
                                                unpublishProperty(props.data.id).then(res => {
                                                    updateNotification({
                                                        id: id,
                                                        title: t("Success"),
                                                        message: t("Unpublished successfully"),
                                                        color: "green",
                                                        icon: <IconSend />,
                                                        autoClose: true,
                                                    });
                                                    props?.refreshData();
                                                    props?.onClose();
                                                }).catch(err => {
                                                    console.log(err);
                                                    updateNotification({
                                                        id: id,
                                                        title: t("Error while unpublishing"),
                                                        message: parseError(err),
                                                        color: "red",
                                                        autoClose: false,
                                                        icon: <IconAlertCircle />,
                                                    });
                                                }).finally(() => {
                                                    setLoading(false);
                                                });
                                            }}
                                        >
                                            {t("Unpublish")}
                                        </Menu.Item>
                                    )
                                }
                                <Menu.Item
                                    color="red"
                                    icon={<IconTrash size={16} />}
                                    onClick={() => {
                                        const id = randomString(10);
                                        showNotification({
                                            id: id,
                                            title: t("Unpublishing"),
                                            message: t("Please wait..."),
                                            loading: true,
                                            autoClose: false,
                                            disallowClose: true,
                                        });
                                        deleteProperty(props.data.id).then(res => {
                                            updateNotification({
                                                id: id,
                                                title: t("Success"),
                                                message: t("تم الحذف بنجاح"),
                                                color: "green",
                                                icon: <IconSend />,
                                                autoClose: true,
                                            });
                                            props?.refreshData();
                                            props?.onClose();
                                        }).catch(err => {
                                            console.log(err);
                                            updateNotification({
                                                id: id,
                                                title: t("حدث خطأ أثناء الحذف"),
                                                message: parseError(err),
                                                color: "red",
                                                autoClose: false,
                                                icon: <IconAlertCircle />,
                                            });
                                        }).finally(() => {
                                            setLoading(false);
                                        });
                                    }}
                                >
                                    {t("Delete")}
                                </Menu.Item>
                            </Menu.Dropdown>
                        </Menu>
                        <ScrollArea
                            offsetScrollbars={true}
                            style={{ height: 'calc(100vh - 156px)', width: '100%' }}
                        >
                            <Select
                                size={'xs'}
                                label={t("Status")}
                                defaultValue={data?.publish_state}
                                data={[
                                    { label: t("Approved"), value: "approved" },
                                    { label: t("Pending"), value: "pending" },
                                    { label: t("Rejected"), value: "refused" },
                                ]}
                                onChange={(value) => {
                                    if (value === "approved") {
                                        const id = randomString(10);
                                        showNotification({
                                            id: id,
                                            title: t("Approving"),
                                            message: t("Please wait..."),
                                            loading: true,
                                            autoClose: false,
                                            disallowClose: true,
                                        });
                                        approvedProperty(props.data.id).then(res => {
                                            updateNotification({
                                                id: id,
                                                title: t("Success"),
                                                message: t("Approved successfully"),
                                                color: "green",
                                                icon: <IconSend />,
                                                autoClose: true,
                                            });
                                            props?.refreshData();
                                            props?.onClose();
                                        }).catch(err => {
                                            console.log(err);
                                            updateNotification({
                                                id: id,
                                                title: t("Error"),
                                                message: parseError(err),
                                                color: "red",
                                                autoClose: false,
                                                icon: <IconAlertCircle />,
                                            });
                                        }).finally(() => {
                                            setLoading(false);
                                        });
                                    } else {
                                        const id = randomString(10);
                                        showNotification({
                                            id: id,
                                            title: t("Rejecting"),
                                            message: t("Please wait..."),
                                            loading: true,
                                            autoClose: false,
                                            disallowClose: true,
                                        });
                                        rejectProperty(props.data.id).then(res => {
                                            updateNotification({
                                                id: id,
                                                title: t("Success"),
                                                message: t("Rejected successfully"),
                                                color: "green",
                                                icon: <IconSend />,
                                                autoClose: true,
                                            });
                                            props?.refreshData();
                                            props?.onClose();
                                        }).catch(err => {
                                            console.log(err);
                                            updateNotification({
                                                id: id,
                                                title: t("Error"),
                                                message: parseError(err),
                                                color: "red",
                                                autoClose: false,
                                                icon: <IconAlertCircle />,
                                            });
                                        }).finally(() => {
                                            setLoading(false);
                                        });
                                    }
                                }}
                            />

                            <FormFields
                                form={form}
                                data={data}
                                loading={loading}
                                onSubmit={handleSubmit}
                                isEdit={true}
                                t={t}
                            />
                        </ScrollArea>
                    </>
                )
            }
        </Drawer >
    )

}