import { memo } from 'react';
import { Box, Card, Text, ActionIcon } from '@mantine/core';
import { Handle } from 'react-flow-renderer';
import { useTranslation } from 'react-i18next';

export default memo((props) => {

    const { t } = useTranslation();

    return (
        <>
            <Box>
                <Card withBorder p={0}>
                    <Card.Section px={20} py={10}>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <ActionIcon 
                                variant={props.data?.actionVarient ? props.data.actionVarient : 'default' } mr={'xs'}
                                onClick={() => {
                                    props.data?.onClick();
                                }}
                            >
                                {props.data?.actionIcon}
                            </ActionIcon>
                            <Text size={'xs'}>{t(props.data?.label)}</Text>
                        </Box>
                    </Card.Section>
                </Card>
                <Handle
                    type="target"
                    position={props.targetPosition ? props.targetPosition : 'top'}
                    id={props.id}
                    style={{
                        display: props.data.hideTarget ? 'none' : 'block',
                    }}
                    isConnectable={props.isConnectable}
                />
                <Handle
                    type="source"
                    position={props.sourcePosition ? props.sourcePosition : 'bottom'}
                    id={props.id}
                    style={{
                        display: props.data.hideSource ? 'none' : 'block',
                    }}
                    isConnectable={props.isConnectable}
                />
            </Box>
        </>
    );
});