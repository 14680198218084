import React, { useEffect } from "react";
import { Select } from "@mantine/core";

// api
import { createCategory, getCategories } from "../api/vendor";

// types
import { Category as CategoryType } from "../types/response";
import { parseError, randomString } from "../functions";
import { showNotification, updateNotification } from "@mantine/notifications";
import { IconAlertCircle, IconSend } from "@tabler/icons";

export default function VendorCategorySelectField({
    defaultData = [],
    ...props
}: {
    defaultData?: any;
    [key: string]: any;
}) {

    const [data, setData] = React.useState<CategoryType[]>(defaultData);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [creatingCategory, setCreatingCategory] = React.useState<boolean>(false);
    const [search, setSearch] = React.useState<string>('');
    const [value, setValue] = React.useState<string | null>(props.defaultValue ? props.defaultValue : null);

    useEffect(() => {
        if (defaultData.length > 0 && search === '') {
            setData(defaultData);
            return;
        }
        setLoading(true);
        getCategories(1, 10, search).then((res) => {
            setData(res.data.categories);
        }).catch((err) => {

        }).finally(() => {
            setLoading(false);
        });
    }, [search]);

    return (
        <Select
            label="Property"
            placeholder={loading ? 'Loading...' : 'Select category'}
            {...props}
            searchable
            creatable={true}
            disabled={creatingCategory}
            data={[
                ...data.map((property: CategoryType) => ({
                    label: `${property.name}`,
                    value: property.name,
                }))
            ]}
            getCreateLabel={(query) => `+ Create ${query}`}
            value={value}
            onChange={(v) => {
                props.onChange && props.onChange(v);
                setValue(v);
            }}
            onCreate={(query) => {
                setCreatingCategory(true);
                const id = `category_${randomString(10)}`;
                showNotification({
                    id: id,
                    title: "Creating category",
                    message: "Please wait...",
                    loading: true,
                    autoClose: false,
                    disallowClose: true,
                });
                const item = { value: query, label: query };
                createCategory({ name: query, category: query, category_ar: query }).then((res) => {
                    updateNotification({
                        id: id,
                        title: "Success",
                        message: "Category created successfully",
                        color: "green",
                        icon: <IconSend />,
                        autoClose: true,
                    });
                    setData([res.data.category, ...data])
                }).catch((err) => {
                    updateNotification({
                        id: id,
                        title: "Error while creating category",
                        message: parseError(err),
                        color: "red",
                        autoClose: false,
                        icon: <IconAlertCircle />,
                    });
                }).finally(() => {
                    setCreatingCategory(false);
                });
                return item;
            }}
            onSearchChange={(value) => {
                setSearch(value);
            }}
        />
    )

}