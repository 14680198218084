import React from "react";
import { Box, Button, Divider, Image, PasswordInput, Text } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import Logo from "../../assets/logo/logo-title-bottom.svg";
import { useTranslation } from "react-i18next";
import { parseError } from "../../functions";
import { resetPassword } from "../../api/customer";

export default function ResetPassword() {

    const { t, i18n } = useTranslation();
    const [password, setPassword] = React.useState("");
    const [passwordHasBeenReset, setPasswordHasBeenReset] = React.useState(false);
    const params = new URLSearchParams(window.location.search);
    const token = params.get("token");

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
                width: "100vw",
            }}
        >
            <Image src={Logo} alt="Logo" width={100} />
            {
                passwordHasBeenReset ? (
                    <Box mt={'xs'}>
                        <Text>{t("Password has been reset successfuly")}</Text>
                    </Box>
                ) : (
                    <Box mt={20}>
                        <Text size="xl" weight={700}>
                            {t("Reset Password")}
                        </Text>
                        <PasswordInput
                            mt={'xs'}
                            placeholder={t("New Password")}
                            value={password}
                            onChange={(e) => setPassword(e.currentTarget.value)}
                        />
                        {/* <PasswordInput
                    mt={'xs'}
                    placeholder={t("Confirm Password")}
                /> */}
                        <Button
                            mt={'xs'}
                            onClick={() => {
                                resetPassword(token as string, password).then((res) => {
                                    setPasswordHasBeenReset(true);
                                }).catch((err) => {
                                    showNotification({
                                        title: t("Error"),
                                        message: parseError(err),
                                        color: "red",
                                        autoClose: false,
                                    });
                                });
                            }}
                        >
                            {t("Save")}
                        </Button>
                    </Box>
                )
            }
        </Box>
    )
}