import { useLocalStorage } from '@mantine/hooks';
import {
    Navbar as NavbarMantine,
    Grid,
    Box,
    Image,
    MediaQuery,
    useMantineTheme,
    ScrollArea,
} from '@mantine/core';
import {
    IconSun,
    IconMoonStars,
} from '@tabler/icons';
import { useSelector } from 'react-redux';

// utils
import Logo from '../../assets/logo/logo-title-right-filled.svg';
import User from './_UserNavbar';
import MainLinks from './_MainLinks';

export default function Navbar() {

    const [colorScheme, setColorScheme] = useLocalStorage({ key: 'color-scheme' });
    const theme = useMantineTheme();
    const toggleColorScheme = () => {
        setColorScheme(colorScheme === 'light' ? 'dark' : 'light')
    }
    const navListOpen: boolean = useSelector((state: any) => state.theme.navListOpen);

    return (
        <NavbarMantine hiddenBreakpoint="sm" hidden={!navListOpen} width={{ sm: 300, lg: 300 }}>
            <MediaQuery smallerThan="sm" styles={{ display: 'none' }}>
                <NavbarMantine.Section py={15} >
                    <Grid sx={{ margin: '20px 0' }} gutter={30}>
                        <Grid.Col span={10}>
                            <Image src={Logo} />
                        </Grid.Col>
                        <Grid.Col span={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }} p={0}>
                            <Grid gutter={5}>
                                <Grid.Col span={12}>
                                    <Box
                                        sx={{
                                            background: colorScheme === 'light' ? theme.colors.gray[2] : theme.colors.dark[5],
                                            color: colorScheme === 'light' ? theme.colors.gray[7] : theme.colors.dark[0],
                                            padding: '10px',
                                            marginRight: '-1px',
                                            borderRadius: '50px 0 0 50px',
                                            cursor: 'pointer',
                                            display: 'flex',
                                            alignItems: 'center',
                                            overflow: 'hidden',
                                            height: '40px',
                                        }}
                                        onClick={toggleColorScheme}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                transition: 'all 0.3s ease',
                                                transform: `translateY(${colorScheme === 'light' ? 20 : -15}px)`,
                                            }}
                                        >
                                            <Box my={4}><IconSun size={20} /></Box>
                                            <Box my={4}><IconMoonStars size={20} /></Box>
                                        </Box>
                                    </Box>
                                </Grid.Col>
                            </Grid>
                        </Grid.Col>
                    </Grid>
                </NavbarMantine.Section>
            </MediaQuery>
            <NavbarMantine.Section grow component={ScrollArea} mx="-xs" px="xs">
                <MainLinks />
            </NavbarMantine.Section>
            <NavbarMantine.Section>
                <User />
            </NavbarMantine.Section>
        </NavbarMantine>
    )

}