// vendors
import React from 'react';
import {
    Grid,
    TextInput,
    Button,
    Select,
    LoadingOverlay,
    ScrollArea,
} from "@mantine/core"
import {
    IconSend,
} from '@tabler/icons';
import { DatePicker } from '@mantine/dates';

// utils
import MediaLibraryField from '../../../../../components/MediaLibraryField';

import dayjs from 'dayjs';
import { useState } from 'react';
interface FormFieldsProps {
    form: any;
    data?: any;
    loading: boolean;
    onSubmit: (values: any) => void;
    isEdit: boolean;
    t: (key: string) => string;
}

const FormFields: React.FC<FormFieldsProps> = ({ form, data, loading, onSubmit, isEdit, t }) => {
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    return (
        <form onSubmit={form.onSubmit(onSubmit)}>
            <ScrollArea
                offsetScrollbars
                style={{ height: 'calc(100vh - 117px)' }}
            >
                <Grid gutter="lg" mt={20}>
                    <LoadingOverlay visible={loading} overlayBlur={2} />
                    <Grid.Col span={12}>
                        <TextInput
                            size="xs"
                            label={t("Name")}
                            placeholder={t("Name")}
                            defaultValue={data?.name}
                            {...form.getInputProps('name')}
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <TextInput
                            size="xs"
                            label={t("Description")}
                            placeholder={t("Description")}
                            defaultValue={data?.description}
                            {...form.getInputProps('description')}
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <Select
                            size="xs"
                            label={t("الحالة")}
                            placeholder={t("الحالة")}
                            defaultValue={data?.enabled}
                            data={[
                                { label: t("فعال"), value: true },
                                { label: t("معطل"), value: false },
                            ]}
                            {...form.getInputProps('enabled')}
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        {isEdit ? (
                            <DatePicker
                                locale='ar'
                                size="xs"
                                label={t("Expires at")}
                                placeholder={t("Expires at")}
                                {...form.getInputProps('expires_at')}
                                defaultValue={data?.expires_at ? new Date(data?.expires_at) : undefined}

                            />
                        ) : (

                            <DatePicker
                                locale='ar'
                                size="xs"
                                label={t("Expires at")}
                                placeholder={t("Expires at")}
                                {...form.getInputProps('expires_at')}
                                value={selectedDate}
                                onChange={(date) => setSelectedDate(date)}
                                dayStyle={(date) => {
                                    const isCurrentDay = dayjs().isSame(date, 'day');
                                    const isSelectedDay = selectedDate && dayjs(selectedDate).isSame(date, 'day');

                                    if (isSelectedDay) {
                                        return {
                                            backgroundColor: '#007bff',
                                            color: 'white',
                                            borderRadius: '4px',
                                            padding: '3px',
                                            border: '2px solid #007bff',
                                        };
                                    }

                                    if (!selectedDate && isCurrentDay) {
                                        return {
                                            backgroundColor: '#007bff',
                                            color: 'white',
                                            borderRadius: '4px',
                                            padding: '3px',
                                        };
                                    }

                                    return {};
                                }}
                            />
                        )}

                    </Grid.Col>
                    <Grid.Col span={12}>
                        <TextInput
                            size="xs"
                            label={t("URL")}
                            placeholder={t("URL")}
                            defaultValue={data?.url}
                            {...form.getInputProps('url')}
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <MediaLibraryField
                            inputProps={{
                                withAsterisk: true,
                                size: "xs",
                                label: t("Image"),
                                placeholder: t("Select file"),
                            }}
                            name="image"
                            multiple={true}
                            defaultValue={data?.documents}
                            {...form.getInputProps('documents')}
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <Button
                            size="xs"
                            leftIcon={<IconSend size={16} />}
                            type="submit"
                        >
                            {isEdit ? t("Save") : t("Create")}
                        </Button>
                    </Grid.Col>
                </Grid>

            </ScrollArea>
        </form>
    );
};

export default FormFields;
