import { apiWithInterceptor } from ".";

// get
export const getInventory = (page :number = 1, limit: number = -1, search: string = '' ) => apiWithInterceptor.get(`/inventory/`, { params: {page, limit, search} })
export const getInventoryItem = (id: string) => apiWithInterceptor.get(`/inventory/${id}`)

// post
export const createInventoryItem = (data: {}) => apiWithInterceptor.post(`/inventory/`, {...data})

// delete
export const deleteInventoryItem = (id: string) => apiWithInterceptor.delete(`/inventory/${id}`, { params: {approved: true} })

// put
export const updateInventoryItem = (id: string, data: {}) => apiWithInterceptor.put(`/inventory/${id}`, data)