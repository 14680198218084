// vendors
import * as React from 'react'
import { useEffect } from 'react';
import { createEmotionCache, MantineProvider } from '@mantine/core';
import { NotificationsProvider } from '@mantine/notifications';
import { useLocalStorage, useSetState } from '@mantine/hooks';
import i18next from 'i18next';
import rtlPlugin from 'stylis-plugin-rtl';
import 'dayjs/locale/ar';

// utils
import Router from './routes';
import './App.css';
import { ModalsProvider } from '@mantine/modals';

const rtlCache = createEmotionCache({
    key: 'mantine-rtl',
    stylisPlugins: [rtlPlugin],
});

const defaultTheme: object = {
    // defaultRadius: 0,
    colors: {
        brand: ['#2c7af7', '#2c7af7', '#2c7af7', '#2c7af7', '#2c7af7', '#2c7af7', '#2c7af7', '#2c7af7', '#2c7af7', '#2c7af7'],
    },
    // primaryColor: 'brand',
    colorScheme: 'light',
    headings: {
        sizes: {
            // h1: { fontWeight: 1 },
        }
    },
    dir: 'rtl',
}

function App(): JSX.Element {

    const [rtl, setRtl] = React.useState(true);
    const [theme, setTheme] = useSetState(defaultTheme);
    const [colorScheme, setColorScheme] = useLocalStorage({ key: 'color-scheme', defaultValue: 'light' });

    useEffect(() => {
        setTheme({ colorScheme: colorScheme });
    }, [colorScheme])

    useEffect(() => {
        document.dir = rtl ? 'rtl' : 'ltr';
        i18next.on('languageChanged', function (lng) {
            document.dir = i18next.dir(lng);
            setTheme({ dir: i18next.dir(lng) });
            if (lng === 'ar') {
                setRtl(true);
            } else {
                setRtl(false);
            }
            i18next.reloadResources();
        })
    }, [])

    return (
        <MantineProvider
            theme={theme}
            withGlobalStyles
            withNormalizeCSS
            emotionCache={rtl ? rtlCache : undefined}
        >
            <NotificationsProvider>
                <ModalsProvider>
                    <Router />
                </ModalsProvider>
            </NotificationsProvider>
        </MantineProvider>
    );
}

export default App;
