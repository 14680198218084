// AddDrawer.tsx
import { useState, useEffect } from 'react';
import { Drawer } from "@mantine/core";
import { showNotification, updateNotification } from '@mantine/notifications';
import { useForm } from "@mantine/form";
import { IconSend, IconAlertCircle } from '@tabler/icons';
import dayjs from "dayjs";
import { useTranslation } from 'react-i18next';
import DrawerTitle from "../../../../../components/DrawerTitle";
import { parseError, randomString } from "../../../../../functions";
import { createUser as addUser, getPermissions } from "../../../../../api/user";
import FormFields from './components/FormFields';

export default function AddUser(props: any) {
    const [permissions, setPermissions] = useState([]);
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const form = useForm({
        initialValues: {
            accountname: "",
            password: "",
            email: "",
            phone_number: "",
            firstname: "",
            lastname: "",
            birthdate: new Date(),
            employment_date: new Date(),
            permissions: "",
            company: "",
        },
    });

    useEffect(() => {
        getPermissions().then((res) => {
            setPermissions(res?.data?.permissions);
        }).catch((err) => {
            showNotification({
                title: "Error",
                message: "Error while fetching permissions",
                color: "red",
                icon: <IconAlertCircle />,
            });
        });
    }, []);
    const handleSubmit = (values: any) => {
        if (values.birthdate) values.birthdate = dayjs(values.birthdate as string).format('YYYY-MM-DD');
        if (values.employment_date) values.employment_date = dayjs(values.employment_date as string).format('YYYY-MM-DD');
        const id = `user_${randomString(10)}`;
        setLoading(true);
        showNotification({
            id: id,
            title: t("Adding"),
            message: t("Please wait..."),
            loading: true,
            autoClose: false,
            disallowClose: true,
        });
        addUser(values).then(res => {
            updateNotification({
                id: id,
                title: t("Success"),
                message: t("User added successfully"),
                color: "green",
                icon: <IconSend />,
                autoClose: true,
            });
            form.reset();
            props?.refreshData();
            props?.onClose();
        }).catch(err => {
            updateNotification({
                id: id,
                title: t("Error while adding"),
                message: parseError(err),
                color: "red",
                autoClose: false,
                icon: <IconAlertCircle />,
            });
        }).finally(() => setLoading(false));
    }
    return (
        <Drawer
            position="right"
            padding="xl"
            size={800}
            {...props}
            onClose={() => {
                props.onClose();
                form.reset();
            }}
        >
            <DrawerTitle>
                {t("اضافة مستخدم")}
            </DrawerTitle>
            <FormFields
                form={form}
                props={props}
                permissions={permissions}
                isEdit={false}
                loading={loading}
                onSubmit={handleSubmit}
                t={t}
            />


        </Drawer >
    );
}
