import React, { useEffect } from "react";
import { Select } from "@mantine/core";

// api
import { getProperties } from "../api/property";

// types
import { Area as PropertyType } from "../types/response";

export default function PropertiesSelectField({
    defaultData = [],
    ...props
}: {
    defaultData?: PropertyType[];
    [key: string]: any;
}) {

    const [data, setData] = React.useState<PropertyType[]>(defaultData);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [search, setSearch] = React.useState<string>('');
    const [value, setValue] = React.useState<string | null>(props.defaultValue ? props.defaultValue : null);

    useEffect(() => {
        if (defaultData.length > 0 && search === '') {
            setData(defaultData);
            return;
        }
        setLoading(true);
        getProperties(1, 10, search).then((res) => {
            setData(res.data.properties);
        }).catch((err) => {

        }).finally(() => {
            setLoading(false);
        });
    }, [search]);

    return (
        <Select
            label="Property"
            placeholder={loading ? 'Loading...' : 'Select property'}
            {...props}
            searchable
            data={[
                ...data.map((property: PropertyType) => ({
                    label: `${property.name} (${property.area}m2 - ${property.address} - ${property.price})`,
                    value: property.id,
                }))
            ]}
            onSearchChange={(value) => {
                setSearch(value);
            }}
            value={value}
            onChange={(v) => {
                props.onChange && props.onChange(v);
                setValue(v);
            }}
        />
    )

}