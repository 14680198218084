import React, { forwardRef, useEffect } from "react";
import { ActionIcon, Box, Select, Text } from "@mantine/core";
import { openConfirmModal } from "@mantine/modals";

// api
import { getCities, createCity, deleteCity } from "../api/city";

// types
import { City } from "../types/response";
import { parseError, randomString } from "../functions";
import { showNotification, updateNotification } from "@mantine/notifications";
import { IconAlertCircle, IconSend, IconTrash } from "@tabler/icons";

export default function CitySelectField({
    defaultData = [],
    ...props
}: {
    defaultData?: any;
    [key: string]: any;
}) {

    const [data, setData] = React.useState<City[]>(defaultData);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [creatingCategory, setCreatingCategory] = React.useState<boolean>(false);
    const [search, setSearch] = React.useState<string>('');
    const [value, setValue] = React.useState<string | null>(props.defaultValue ? props.defaultValue : null);

    useEffect(() => {
        if (defaultData.length > 0 && search === '') {
            setData(defaultData);
            return;
        }
        setLoading(true);
        getCities(1, -1, search).then((res) => {
            setData(res.data.cities);
        }).catch((err) => {

        }).finally(() => {
            setLoading(false);
        });
    }, [search]);

    return (
        <Select
            label="Property"
            placeholder={loading ? 'Loading...' : 'اختر مدينة'}
            {...props}
            searchable
            creatable={true}
            disabled={creatingCategory}
            data={[
                ...data.map((property: City) => ({
                    label: `${property.name}`,
                    value: property.id,
                    city: property,
                    onSelected: (city: City) => {
                        props.onSelected && props.onSelected(city);
                    },
                    onDelete: (city: City) => {
                        // show confirm modal
                        openConfirmModal({
                            title: "تأكيد الحذف",
                            children: `هل أنت متأكد من حذف المدينة ${city.name}؟ سيتم حذف جميع العقارات المرتبطة بهذه المدينة`,
                            labels: { confirm: 'حذف', cancel: "إلغاء" },
                            confirmProps: { color: 'red' },
                            onConfirm: () => {
                                deleteCity(city.id).then((res) => {
                                    setData(data.filter((item) => item.id !== city.id));
                                }).catch((err) => {
                                    showNotification({
                                        title: "حدث خطأ أثناء حذف المدينة",
                                        message: parseError(err),
                                        color: "red",
                                        autoClose: false,
                                        icon: <IconAlertCircle />,
                                    });
                                });
                            }
                        });
                    }
                }))
            ]}
            // data={data as any}
            getCreateLabel={(query) => `+ إضافة ${query}`}
            value={value}
            onChange={(v) => {
                props.onChange && props.onChange(v);
                setValue(v);
            }}
            onCreate={(query) => {
                setCreatingCategory(true);
                const id = `city_${randomString(10)}`;
                showNotification({
                    id: id,
                    title: "جار إضافة المدينة",
                    message: "Please wait...",
                    loading: true,
                    autoClose: false,
                    disallowClose: true,
                });
                const item = { value: query, label: query };
                createCity(query).then((res) => {
                    updateNotification({
                        id: id,
                        title: "نجاح",
                        message: "تمت إضافة المدينة بنجاح",
                        color: "green",
                        icon: <IconSend />,
                        autoClose: true,
                    });
                    setData([res.data.city, ...data])
                }).catch((err) => {
                    updateNotification({
                        id: id,
                        title: "حدث خطأ أثناء إضافة المدينة",
                        message: parseError(err),
                        color: "red",
                        autoClose: false,
                        icon: <IconAlertCircle />,
                    });
                }).finally(() => {
                    setCreatingCategory(false);
                });
                return item;
            }}
            onSearchChange={(value) => {
                setSearch(value);
            }}
            // custom item rendering with delete button
            itemComponent={SelectItem}
        />
    )

}

interface SelectItemProps {
    label: string;
    value: string;
    city: City;
    onSelected: (city: City) => void;
    onDelete: (city: City) => void;
}

const SelectItem = forwardRef<HTMLDivElement, SelectItemProps>(
    ({ onSelected, onDelete, label, city, ...others }: SelectItemProps, ref) => (
        <div ref={ref} {...others}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    cursor: 'pointer',
                }}
                px={'xs'}
                onClick={() => {
                    onSelected && onSelected(city);
                }}
            >
                <Text size="sm">{label}</Text>
                <ActionIcon color="red" onClick={(e: any) => {
                    e.stopPropagation();
                    onDelete && onDelete(city);
                }}>
                    <IconTrash />
                </ActionIcon>
            </Box>
        </div>
    )
);