import React from 'react';
import { DataGrid, stringFilterFn, dateFilterFn, OnChangeCallback } from 'mantine-data-grid';
import dayjs from 'dayjs';
import { Box } from '@mantine/core';
import SkeletonRows from "../../../../../../components/SkeletonRows";
import NoDataFound from '../../../../../../components/NoDataFound';
import { useTranslation } from 'react-i18next';

type CustomDataGridProps = {
    data: any[];
    total: number;
    loading: boolean;
    noData: boolean;
    isEmployee: boolean;
    load: OnChangeCallback<any>;
    search: OnChangeCallback<string>;
    setEditData: (data: any) => void;
};

const CustomDataGrid: React.FC<CustomDataGridProps> = ({
    data,
    total,
    loading,
    noData,
    load,
    search,
    setEditData,
    isEmployee
}) => {
    const { t } = useTranslation();

    return (
        <Box
            sx={{
                padding: '0 20px',
            }}
        >
            {data && data.length > 0 ? (
                <DataGrid
                    data={data}
                    total={total}
                    onPageChange={load}
                    onSearch={search}
                    withPagination
                    verticalSpacing="md"
                    paginationMode="compact"
                    withSorting={true}
                    withGlobalFilter
                    loading={loading}
                    styles={(theme) => ({
                        thead: {
                            '::after': {
                                backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[4],
                                height: 1,
                            },
                        },
                    })}
                    mb={'lg'}
                    onRow={(row: any) => ({
                        onClick: () => setEditData(row.original),
                    })}
                    columns={[
                        {
                            accessorKey: 'accountname',
                            header: t('accountname') as string,
                            filterFn: stringFilterFn,
                            size: 300,
                        },
                        {
                            header: t('Email') as string,
                            accessorKey: 'email',
                            filterFn: stringFilterFn,
                        },
                        {
                            header: t('Birthdate') as string,
                            accessorKey: 'birthdate',
                            filterFn: dateFilterFn,
                            cell: (props: any): string => dayjs(props.row.original.birthdate).format('DD/MM/YYYY')

                        },
                        {
                            header: (isEmployee ? t("Employment Date") : t("Registration Date")) as string,
                            accessorKey: 'employment_date',
                            filterFn: dateFilterFn,
                            cell: (props: any): string => dayjs(props.row.original.created_at).format('DD/MM/YYYY'),
                        },
                    ]}
                />
            ) : noData ? (
                <NoDataFound />
            ) : (
                <SkeletonRows count={10} height={20} />
            )}
        </Box>
    );
};

export default CustomDataGrid;
