import React from 'react';
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useHasPermission } from '../../../../hooks';
import NoPermisionPage from '../../../../components/NoPermisionPage';

export { default as Overview } from './Overview';
export { default as Invoices } from './Invoices';

export default function Finance() {

    const permissionGranted = useHasPermission(["invoices.read", "assets.read"]);

    const location = useLocation();
    const navigate = useNavigate();

    
    useEffect(() => {
        
        const paths = location.pathname.split('/').filter((item) => item !== '');
        
        if ( paths[paths.length-1] === 'finance' ) {
            navigate('/app/finance/overview');
        }
    }, []);
    
    if (!permissionGranted) {
        return <NoPermisionPage/>;
    }

    return <Outlet/>;

}