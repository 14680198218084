// vendors
import { 
    Box, 
    Title,
} from '@mantine/core';

// utils
import Breadcrumbs from './Breadcrumbs';

export default function PageHeading({ children }) {

    return (
        <>
            <Box
                sx={{
                    padding: '20px',
                }}
            >
                <Breadcrumbs />
                <Title 
                    order={1}
                    sx={{
                        textTransform: 'uppercase',
                        fontSize: '2rem',
                    }}
                >
                    {children}
                </Title>
            </Box>
            {/* <Divider /> */}
        </>
    )

}