// vendors
import { useState, useCallback, useEffect } from 'react'
import { GoogleMap, useJsApiLoader, DrawingManager } from '@react-google-maps/api';
import { useMantineTheme } from '@mantine/core';
import { useSetState, useViewportSize } from '@mantine/hooks';
import {
    IconAlertCircle,
    IconFilter,
    IconLayoutGridAdd,
    IconMap,
    IconRadar,
} from '@tabler/icons';
import { showNotification } from '@mantine/notifications';

// utils
import styles from './_mapDarkModeStyles';
import SideButton from "../../../../../components/SideButton";
import { geoToLatLng, latLngAverage, latLngToGeo, parseError } from "../../../../../functions";

//// Drawers
import EditDrawer from './_EditDrawer';
import ExploreAreas from './_ExploreAreas_Drawer';
import AddDrawer from './_AddDrawer';

// types
import { Area } from "../../../../../types/response";

// api
import { getProperties } from "../../../../../api/property";
import { useParams } from 'react-router-dom';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import FilterDrawer from './_FilterDrawer';



const libraries: ("drawing" | "geometry" | "localContext" | "places" | "visualization")[] = ["drawing"];

const Map = function () {

    const theme = useMantineTheme();
    const { height, width } = useViewportSize();
    const [center, setCenter] = useSetState<google.maps.LatLngLiteral>({
        lat: 36.349745036939886,
        lng: 43.15649426602125
    });
    const [zoom, setZoom] = useState<number>(13);
    const [drawingMode, setDrawingMode] = useState<google.maps.drawing.OverlayType | null | undefined>(null);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [showExploreAreas, setShowExploreAreas] = useState<boolean>(false);
    const [data, setData] = useState<Area[]>([]);
    const [withinWindow, setWithinWindow] = useState<number[]>([]);
    const [filter, setFilter] = useState<{ [key: string]: string }>({});
    const { id } = useParams();
    const [paramId, setParamId] = useState<string | null | undefined>(id);
    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);
    const [polygons, setPolygons] = useState<google.maps.Polygon[]>([]);

    useEffect(() => {
        setParamId(id);
    }, [id])

    const load = () => {
        setLoading(true);
        getProperties(1, -1, '', withinWindow.join(','), filter).then(res => {
            setData(res.data?.properties);
        }).catch((err: AxiosError) => {
            if (err.response?.status === 404) {
                setData([]);
                return;
            }
            console.error(err);
            const error = {
                title: 'Error',
                message: parseError(err),
                color: 'red',
                icon: <IconAlertCircle />,
            }
            showNotification(error);
        }).finally(() => {
            setLoading(false);
        });
    }

    useEffect(() => {



        if (!map) return;

        // remove old polygons
        polygons.forEach((polygon: google.maps.Polygon) => {
            polygon.setMap(null);
        });

        // draw polygons
        const newPolygons = data.map((area: Area) => {

            const colorProps = area.editable ? {
                fillColor: theme.colors.gray[6],
                fillOpacity: 0.35,
                strokeColor: theme.colors.gray[6],
                strokeOpacity: 0.8,
                strokeWeight: 2,
            } : area.id === paramId ? {
                fillColor: theme.colors.yellow[6],
                fillOpacity: 0.35,
                strokeColor: theme.colors.yellow[6],
                strokeOpacity: 0.8,
                strokeWeight: 2,
            } : {
                fillColor: !area.public ? theme.colors.gray[6] : (
                    area.status === 'sold' ? theme.colors.red[6] : (
                        area.status === 'reserved' ? theme.colors.yellow[6] : (
                            area.property_ownership === 'sale' ? theme.colors.green[6] : (
                                area.property_ownership === 'rent' ? theme.colors.blue[6] : (
                                    theme.colors.gray[6]
                                )
                            )
                        )
                    )
                ),
                fillOpacity: 0.35,
                strokeColor: !area.public ? theme.colors.gray[6] : (
                    area.status === 'sold' ? theme.colors.red[6] : (
                        area.status === 'reserved' ? theme.colors.yellow[6] : (
                            area.property_ownership === 'sale' ? theme.colors.green[6] : (
                                area.property_ownership === 'rent' ? theme.colors.blue[6] : (
                                    theme.colors.gray[6]
                                )
                            )
                        )
                    )
                ),
                strokeOpacity: 0.8,
                strokeWeight: 2,
            };


            // draw polygon
            const polygon = new window.google.maps.Polygon({
                paths: geoToLatLng(area.geometry),
                ...colorProps,
            });

            polygon.setMap(map);
            polygon.addListener('click', () => {
                setSelectedArea(area);
            });
            return polygon;
        });

        setPolygons(newPolygons);
    }, [data])

    useEffect(() => {
        if (!withinWindow.length) return;
        load();
    }, [withinWindow, filter])


    const mapApi = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyDBcVmDrMAEhffVzUPCUxbosgVOfAFN-DM",
        libraries: libraries,
    })

    useEffect(() => {
        if (mapApi.isLoaded) {
            setIsLoaded(true);
        }
    }, [mapApi.isLoaded])

    const [filterDrawerOpened, setFilterDrawerOpened] = useState<boolean>(false);
    const [selectedArea, setSelectedArea] = useState<Area | null>(null);
    const [editingArea, setEditingArea] = useState<Area | null>(null);
    const [newArea, setNewArea] = useState<google.maps.Polygon | null>(null);
    const [mapTypeId, setMapTypeId] = useState<'roadmap' | 'satellite'>('roadmap');
    const [map, setMap] = useState<google.maps.Map | null>(null)
    const [avgs, setAvgs] = useState<number[]>([]);

    const updateAreaPoints = function (area: Area) {
        // setSelectedArea(null);
        setEditingArea(area);
        setDrawingMode(window.google.maps.drawing.OverlayType.POLYGON);
        setData([
            ...data.map((item: Area) => ({
                ...item,
                editable: area.id === item.id ? true : false,
            })),
        ])
    }

    const onLoad = useCallback(function callback(map: google.maps.Map) {
        if (!map.mapTypes)
            return

        // const ne = new window.google.maps.LatLng(36.350519134539965, 43.16209891867681);
        // map.setCenter(pos);
        // const bounds = new window.google.maps.LatLngBounds();
        // map.fitBounds(bounds);
        setMap(map);

        const int = setInterval(() => {
            const bounds = map.getBounds();
            if (bounds) {
                setWithinWindow([
                    bounds?.getSouthWest().lng(),
                    bounds?.getSouthWest().lat(),
                    bounds?.getNorthEast().lng(),
                    bounds?.getNorthEast().lat(),
                ]);
                clearInterval(int);
            }
        }, 100);
    }, [])

    useEffect(() => {
        // setZoom(17);
        if (paramId) {
            const area = data.find(item => item.id === paramId);
            if (area) {
                setSelectedArea(area);
                const avg = latLngAverage(
                    geoToLatLng(
                        area.geometry
                    )
                )
                setCenter({
                    lat: avg.lat(),
                    lng: avg.lng()
                });
                map?.setZoom(20);
            }
        }
    }, [paramId, data])

    const onUnmount = useCallback(function callback(map: google.maps.Map) {
        setMap(null)
    }, [])

    return isLoaded && (
        <GoogleMap
            mapContainerStyle={{
                width: 'auto',
                height: width > 800 ? '100vh' : 'calc(100vh - 70px)',
            }}
            center={center}
            zoom={zoom}
            onLoad={onLoad}
            onUnmount={onUnmount}
            options={{
                styles: theme.colorScheme === 'dark' ? styles : null,
                disableDefaultUI: true,
                clickableIcons: false,
                mapTypeControl: true,
                mapTypeId: mapTypeId,
                // controlPosition: window.google ? window.google.maps.ControlPosition.TOP_LEFT : undefined,
                // controls: [],
                // drawingMode: "Polygon", //  "LineString" or "Polygon".
            }}
            onClick={(e) => {
                // console.log(e?.latLng?.lat(), e?.latLng?.lng());
            }}
            onDragEnd={() => {
                if (map) {
                    const bounds = map.getBounds();
                    if (bounds) {
                        setWithinWindow([
                            bounds?.getSouthWest().lng(),
                            bounds?.getSouthWest().lat(),
                            bounds?.getNorthEast().lng(),
                            bounds?.getNorthEast().lat(),
                        ])

                    }
                }
            }}
            onZoomChanged={() => {
                if (map) {
                    const bounds = map.getBounds();
                    if (bounds) {
                        setWithinWindow([
                            bounds?.getSouthWest().lng(),
                            bounds?.getSouthWest().lat(),
                            bounds?.getNorthEast().lng(),
                            bounds?.getNorthEast().lat(),
                        ])

                    }
                }
            }}
        >
            <DrawingManager
                // setMap={GoogleMap}
                options={{
                    drawingMode: drawingMode,
                    drawingControl: true,
                    drawingControlOptions: {
                        position: window.google.maps.ControlPosition.TOP_LEFT,
                        drawingModes: [
                            // window.google.maps.drawing.OverlayType.MARKER,
                            // window.google.maps.drawing.OverlayType.CIRCLE,
                            window.google.maps.drawing.OverlayType.POLYGON,
                            // window.google.maps.drawing.OverlayType.POLYLINE,
                            // window.google.maps.drawing.OverlayType.RECTANGLE,
                        ],
                    },
                }}
                // onLoad={onLoad}
                onPolygonComplete={(polygon) => {
                    if (polygon.getPath().getArray().length <= 2) {
                        polygon.setMap(null);
                        return
                    }
                    if (editingArea) {
                        setSelectedArea({
                            ...editingArea,
                            geometry: latLngToGeo(polygon.getPath().getArray()),
                        });
                        setEditingArea(null);
                        setDrawingMode(null);
                        polygon.setMap(null);
                    } else {
                        polygon.setEditable(true);
                        polygon.setDraggable(true);
                        setNewArea(polygon);
                    }
                }}
            />
            <FilterDrawer
                opened={filterDrawerOpened}
                loading={loading}
                onClose={() => setFilterDrawerOpened(false)}
                onApply={(f) => {
                    setFilter(f);
                }}
            />
            <EditDrawer
                id="area-about"
                opened={selectedArea !== null && editingArea === null}
                onClose={() => {
                    setSelectedArea(null);
                    setParamId(null);
                    // const url = new URL(window.location.href);
                    // // remove the last part from url
                    // url.pathname = url.pathname.split('/').slice(0, -1).join('/');
                    // // push
                    // window.history.pushState({}, '', url.href);
                }}
                data={selectedArea as Area}
                updateAreaPoints={updateAreaPoints}
                refreshData={() => load()}
            />
            <SideButton
                buttons={[
                    {
                        label: t("Filter"),
                        icon: <IconFilter size={30} />,
                        onClick: () => {
                            setFilterDrawerOpened((prev) => !prev);
                        }
                    },
                    {
                        label: t("Map type"),
                        icon: <IconMap size={30} />,
                        onClick: () => {
                            setMapTypeId(mapTypeId === "satellite" ? "roadmap" : "satellite");
                        }
                    },
                    {
                        label: t("Explore"),
                        icon: <IconRadar size={30} />,
                        onClick: () => setShowExploreAreas(!showExploreAreas)
                    },
                    {
                        label: t("Add Property"),
                        activeLabel: t("Done"),
                        active: drawingMode !== null || editingArea !== null,
                        icon: <IconLayoutGridAdd size={30} />,
                        onClick: () => {
                            if (editingArea === null) {
                                drawingMode === null ? setDrawingMode(window.google.maps.drawing.OverlayType.POLYGON) : setDrawingMode(null);
                                if (newArea)
                                    newArea.setMap(null);
                            } else {
                                // setSelectedArea({
                                //     ...editingArea,
                                //     // geometry: latLngToGeo(newArea?.getPath().getArray() as google.maps.LatLng[])
                                // });
                                // setEditingArea(null);
                            }
                        },
                        bgColorActive: '#7cd60a',
                    },
                ]}
            />
            <AddDrawer
                opened={newArea !== null}
                onClose={() => {
                    if (newArea)
                        newArea.setMap(null);
                    setNewArea(null);
                    setDrawingMode(null);
                }}
                refreshData={() => load()}
                data={newArea as google.maps.Polygon}
            />
            <ExploreAreas
                sx={{
                    transition: ".3s",
                    transform: `${showExploreAreas ? 'translateY(0)' : 'translateY(100%)'}`,
                    display: `${showExploreAreas ? 'block' : 'none'}`,
                }}
                data={data}
                onClick={(area: Area) => {
                    setParamId(area.id);
                    setSelectedArea(area);
                    setTimeout(() => {
                        if (map) {
                            const bounds = map.getBounds();
                            if (bounds) {
                                setWithinWindow([
                                    bounds?.getSouthWest().lng(),
                                    bounds?.getSouthWest().lat(),
                                    bounds?.getNorthEast().lng(),
                                    bounds?.getNorthEast().lat(),
                                ])

                            }
                        }
                    }, 300);
                }}
            />

        </GoogleMap>
    )

}

export default Map

const areaWithinWindow = (area: Area, bounds: number[]) => {
    const areaBounds = area.geometry;
    return true
}