import { useEffect, useState } from 'react';
import { showNotification } from '@mantine/notifications';
import { getPropertiesRemainingReport, getPropertiesReport } from '../../../../../api/property';
import { getInvoiceReport } from '../../../../../api/invoice';
import { parseError } from '../../../../../functions';
import { useTranslation } from 'react-i18next';

export function usePropertyReports(dateRange: [Date, Date], permissionGranted: boolean) {
    const { t } = useTranslation();
    const [propertiesReport, setPropertiesReport] = useState<any>(null);
    const [propertiesRemainingReport, setPropertiesRemainingReport] = useState<any>(null);
    const [invoicesReport, setInvoicesReport] = useState<any>(null);
    const [invoices, setInvoices] = useState<any[]>([]);

    useEffect(() => {
        if (!permissionGranted) return;

        if (dateRange[0] && dateRange[1]) {
            setPropertiesReport(null);
            setInvoices([]);
            setInvoicesReport(null);

            getPropertiesReport(dateRange[0], dateRange[1]).then((res) => {
                setPropertiesReport(res.data);
            }).catch((err) => {
                showNotification({
                    title: t('Error'),
                    message: parseError(err),
                    color: 'red',
                    autoClose: false,
                });
            });

            getPropertiesRemainingReport(dateRange[0], dateRange[1]).then((res) => {
                setPropertiesRemainingReport(res.data);
            }).catch((err) => {
                showNotification({
                    title: t('Error'),
                    message: parseError(err),
                    color: 'red',
                    autoClose: false,
                });
            });

            getInvoiceReport(dateRange[0], dateRange[1]).then((res) => {
                setInvoicesReport(res.data);
            }).catch((err) => {
                showNotification({
                    title: t('Error'),
                    message: parseError(err),
                    color: 'red',
                    autoClose: false,
                });
            });
        }
    }, [dateRange, permissionGranted, t]);

    return {
        propertiesReport,
        propertiesRemainingReport,
        invoicesReport,
        invoices,
    };
}