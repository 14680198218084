import { Box, MultiSelectValueProps, Image, Text, CloseButton } from "@mantine/core";
import { IconFileText } from "@tabler/icons";

// utils
import { imageLink } from "../../functions";

// types
import { Document as DocumentType } from "../../types/response";

export default function FieldValue({
    value,
    label,
    data,
    onClick,
    onRemove,
    classNames,
    ...others
}: MultiSelectValueProps & { value: string, data: DocumentType, onClick: (data: DocumentType) => void }) {
    // const Flag = flags[value];
    return (
        <div {...others}>
            <Box
                sx={(theme) => ({
                    display: 'flex',
                    cursor: 'pointer',
                    alignItems: 'center',
                    overflow: 'hidden',
                    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
                    '&:hover': {
                        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
                    },
                    border: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[4]}`,
                    borderRadius: theme.radius.sm,
                    zIndex: 99,
                })}
                onClick={(event: any) => {
                    event.stopPropagation()
                    onClick(data)
                }}
            >
                <Box mr={10}>
                    {
                        ['jpg', 'jpeg', 'png'].filter(t => t === data.filename.split('.').pop()).length ? (
                            <Image width={40} height={40} src={imageLink(data.path)} />
                        ) : (
                            <IconFileText size={40} />
                        )
                    }
                </Box>
                <Box>
                    <Text size={'xs'}>
                        {label}
                    </Text>
                </Box>
                <CloseButton
                    onMouseDown={onRemove}
                    variant="transparent"
                    size={22}
                    iconSize={14}
                    tabIndex={-1}
                />
            </Box>
        </div>
    );
}