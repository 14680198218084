import React, { useEffect } from "react";
import { Select } from "@mantine/core";

// api
import { getInvoices } from "../api/invoice";

// types
import { Invoice as InvoiceType } from "../types/response";

export default function CustomersSelectField({
    defaultData = [],
    ...props
}: {
    defaultData?: InvoiceType[];
    [key: string]: any;
}) {

    const [data, setData] = React.useState<InvoiceType[]>(defaultData);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [search, setSearch] = React.useState<string>('');
    const [value, setValue] = React.useState<string | null>(props.defaultValue ? props.defaultValue : null);

    useEffect(() => {
        if (defaultData.length > 0 && search === '') {
            setData(defaultData);
            return;
        }
        setLoading(true);
        getInvoices(1, 10, search).then((res) => {
            setData(res.data.invoices);
        }).catch((err) => {

        }).finally(() => {
            setLoading(false);
        });
    }, [search]);

    return (
        <Select
            label="الفاتورة"
            placeholder={loading ? 'جار التحميل...' : 'اختر الفاتورة'}
            {...props}
            searchable
            data={[
                ...data?.map((invoice: InvoiceType) => ({
                    label: `${invoice.subject} (${invoice.direction === 'in' ? 'إيداع' : 'سحب'}) - ${invoice.amount}`,
                    value: invoice.id,
                }))
            ]}
            onSearchChange={(value) => {
                setSearch(value);
            }}
            value={value}
            onChange={(v) => {
                props.onChange && props.onChange(v);
                setValue(v);
            }}
        />
    )

}