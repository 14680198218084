import React from 'react';
import { Menu, Button } from '@mantine/core';
import { IconTrash, IconSend, IconAlertCircle } from '@tabler/icons';
import { showNotification, updateNotification } from '@mantine/notifications';
import { AxiosResponse } from 'axios';
import { parseError, randomString } from "../../../../../../functions";

interface EditMenuProps {
  onDelete: () => Promise<AxiosResponse<any, any>>;
  onAction?: () => Promise<AxiosResponse<any, any>>;
  isPublic?: boolean;
  buttonLabel: string;
  actionLabel?: string;
  deleteLabel: string;
  isActionable?: boolean;
  refreshData?: () => void;
  onClose?: () => void;
}

const EditMenu: React.FC<EditMenuProps> = ({
  onDelete,
  onAction,
  isPublic,
  buttonLabel,
  actionLabel,
  deleteLabel,
  isActionable = false,
  refreshData,
  onClose,
}) => {
  const handleDelete = () => {
    const id = randomString(10);
    showNotification({
      id: id,
      title: "جار الحذف",
      message: "يرجى الانتظار...",
      loading: true,
      autoClose: false,
      disallowClose: true,
    });

    onDelete().then(() => {
      updateNotification({
        id: id,
        title: "نجاح",
        message: "تم الحذف بنجاح",
        color: "green",
        icon: <IconSend />,
        autoClose: true,
      });
      refreshData?.();
      onClose?.();
    }).catch((err) => {
      console.log(err);
      updateNotification({
        id: id,
        title: "خطأ أثناء الحذف",
        message: parseError(err),
        color: "red",
        autoClose: false,
        icon: <IconAlertCircle />,
      });
    });
  };

  const handleAction = () => {
    if (!onAction) return;

    const id = randomString(10);
    showNotification({
      id: id,
      title: isPublic ? "Unpublishing" : "Publishing",
      message: "يرجى الانتظار...",
      loading: true,
      autoClose: false,
      disallowClose: true,
    });

    onAction().then(() => {
      updateNotification({
        id: id,
        title: "نجاح",
        message: isPublic ? "Unpublished successfully" : "Published successfully",
        color: "green",
        icon: <IconSend />,
        autoClose: true,
      });
      refreshData?.();
      onClose?.();
    }).catch((err) => {
      console.log(err);
      updateNotification({
        id: id,
        title: `خطأ أثناء ${isPublic ? "إلغاء النشر" : "النشر"}`,
        message: parseError(err),
        color: "red",
        autoClose: false,
        icon: <IconAlertCircle />,
      });
    });
  };

  return (
    <Menu shadow="md" width={200}>
      <Menu.Target>
        <Button size="xs" my={20}>{buttonLabel}</Button>
      </Menu.Target>
      <Menu.Dropdown>
        {isActionable && (
          <Menu.Item onClick={handleAction}>
            {actionLabel}
          </Menu.Item>
        )}
        <Menu.Item
          color="red"
          icon={<IconTrash size={16} />}
          onClick={handleDelete}
        >
          {deleteLabel}
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

export default EditMenu;
