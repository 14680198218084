import { Text, Image, Box, Card, Grid } from "@mantine/core";
import React from "react";

// utils
import { imageLink } from "../../../../functions";

// types
import { Document as DocumentType } from "../../../../types/response";

// api
// import { deleteDocument } from "../../../../api/documents";

export default function ImageBox(
    {
        data,
        onClick,
        ...props
    }: {
        data: DocumentType,
        onClick?: (data: DocumentType) => void,
        [key: string]: any
    }
) {


    return (
        <Box sx={{ width: 200, '&:hover .remove': { display: 'flex' } }} ml={'md'} mt={'md'} {...props} onClick={() => onClick && onClick(data)}>
            <Card withBorder m={0}>
                <Card.Section>
                    <Grid>
                        <Grid.Col span={12} pt={0}>
                            <Image src={imageLink(data.path)} alt={data.name} width={'100%'} height={200} />
                        </Grid.Col>
                        <Grid.Col span={12} mt={-5} px={'sm'}>
                            <Text size={'xs'} align={'center'}>{data.name}</Text>
                        </Grid.Col>
                    </Grid>
                </Card.Section>
                {/* <ActionIcon
                    disabled={deleting}
                    className="remove"
                    sx={{
                        position: 'absolute',
                        top: 5,
                        right: 5,
                        display: 'none',
                    }}
                    variant={'filled'}
                    radius={'xl'}
                    color={'red'}
                    onClick={() => {
                        setDeleting(true);
                        showNotification({
                            id: data.id,
                            title: 'Deleting document',
                            message: 'Document is being deleted',
                            color: 'red',
                            loading: true,
                        })

                    }}
                >
                    <IconTrash size={15} />
                </ActionIcon> */}
            </Card>
        </Box>
    )
}