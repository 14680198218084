// vendors
import { useState } from 'react';
import { Drawer, ScrollArea } from "@mantine/core";
import { showNotification, updateNotification } from '@mantine/notifications';
import { useForm } from "@mantine/form";
import { IconSend, IconAlertCircle } from '@tabler/icons';

// api
import { createUtility } from "../../../../api/utility";

// utils
import DrawerTitle from "../../../../components/DrawerTitle";
import { parseError, randomString } from "../../../../functions";
import FormFields from './componets/FormFields';
import { useTranslation } from 'react-i18next';

export default function AddDrawer(props: any) {
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const form = useForm({
        initialValues: {
            name: "",
            description: "",
            documents: [],
            price: "",
            price_literal: "",
            status: "listed",
            category: "",
            custom_id: "",
            address: "",
            public: true,
            vendor: ""
        },
    });

    const handleSubmit = (values: any) => {
        const id = `contract_${randomString(10)}`;
        setLoading(true);
        showNotification({
            id: id,
            title: t('Creating'),
            message: t("Please wait..."),
            loading: true,
            autoClose: false,
            disallowClose: true,
        });
        createUtility({ ...values, custom_id: id }).then(res => {
            updateNotification({
                id: id,
                title: t("Success"),
                message: t("Created successfully"),
                color: "green",
                icon: <IconSend />,
                autoClose: true,
            });
            form.reset();
            props?.refreshData();
            props?.onClose();
        }).catch(err => {
            console.log(err);
            updateNotification({
                id: id,
                title: t("Error while creating"),
                message: parseError(err),
                color: "red",
                autoClose: false,
                icon: <IconAlertCircle />,
            });
        }).finally(() => {
            setLoading(false);
        });
    };

    return (
        <Drawer
            position="right"
            padding="xl"
            size={800}
            {...props}
            onClose={() => {
                props.onClose();
                form.reset();
            }}
        >
            <DrawerTitle>إضافة</DrawerTitle>
            <ScrollArea
                offsetScrollbars
                style={{ height: 'calc(100vh - 170px)' }}
            >
                <FormFields
                    form={form}
                    loading={loading}
                    onSubmit={handleSubmit}
                    isEdit={false}
                    t={t}
                />
            </ScrollArea>
        </Drawer>
    );
}
