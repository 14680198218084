// UserInfo.tsx
import { Text } from "@mantine/core";
import dayjs from "dayjs";
import { useTranslation } from 'react-i18next';

export default function UserInfo({ createdAt }: { createdAt: string }) {
    const { t } = useTranslation();

    return (
        <Text size={'sm'} mt={'lg'}>
            {t('user registred on')} {dayjs(createdAt).format('MMMM D, YYYY h:mm A')}
        </Text>
    );
}
