import React from "react";
import { Grid, TextInput, Button, Card, Select } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { IconAt, IconPhone, IconMapPin, IconHeart, IconSchool, IconBriefcase, IconCurrencyDollar } from "@tabler/icons";
import { useTranslation } from "react-i18next";

interface ProfileFormProps {
    form: any;
    onSubmit: (values: any) => void;
}

const ProfileForm: React.FC<ProfileFormProps> = ({ form, onSubmit }) => {
    const { t } = useTranslation();

    return (
        <form onSubmit={form.onSubmit(onSubmit)}>
            <Card withBorder my={'lg'} sx={{ overflow: 'visible' }}>
                <Card.Section p={'lg'}>
                    <Grid>
                        <Grid.Col span={12}>
                            <TextInput
                                size={'xs'}
                                variant={'filled'}
                                label={t('First Name')}
                                {...form.getInputProps('firstname')}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <TextInput
                                size={'xs'}
                                variant={'filled'}
                                label={t('Last Name')}
                                {...form.getInputProps('lastname')}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <TextInput
                                size={'xs'}
                                variant={'filled'}
                                label={t('Email')}
                                icon={<IconAt size={16} />}
                                {...form.getInputProps('email')}
                            />
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <DatePicker
                                locale='ar'
                                size={'xs'}
                                variant={'filled'}
                                label={t('Birth Date')}
                                {...form.getInputProps('birthdate')}
                            />
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <TextInput
                                size={'xs'}
                                variant={'filled'}
                                label={t('Phone Number')}
                                icon={<IconPhone size={16} />}
                                {...form.getInputProps('phone_number')}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <TextInput
                                size={'xs'}
                                variant={'filled'}
                                label={t('Address')}
                                icon={<IconMapPin size={16} />}
                                {...form.getInputProps('address')}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <Select
                                size="xs"
                                variant="filled"
                                data={[
                                    {
                                        label: 'أعزب',
                                        value: 'أعزب',
                                    },
                                    {
                                        label: 'متزوج',
                                        value: 'متزوج',
                                    },
                                    {
                                        label: 'مطلق/ة',
                                        value: 'مطلق/ة',
                                    },
                                    {
                                        label: 'أرمل/ة',
                                        value: 'أرمل/ة',
                                    },
                                ]}
                                label={t("Marital Status")}
                                icon={<IconHeart size={16} />}
                                {...form.getInputProps('marital_status')}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <TextInput
                                size="xs"
                                variant="filled"
                                label={t("Education")}
                                icon={<IconSchool size={16} />}
                                {...form.getInputProps('education')}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <TextInput
                                size="xs"
                                variant="filled"
                                label={t("Job Title")}
                                icon={<IconBriefcase size={16} />}
                                {...form.getInputProps('job_title')}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <TextInput
                                size="xs"
                                variant="filled"
                                label={t("Salary")}
                                icon={<IconCurrencyDollar size={16} />}
                                {...form.getInputProps('salary')}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            {form.isDirty() && (
                                <Button size="xs" type="submit">
                                    {t('Save')}
                                </Button>
                            )}
                        </Grid.Col>
                    </Grid>
                </Card.Section>
            </Card>
        </form>
    );
};

export default ProfileForm;
