import { Card, Divider, Text } from "@mantine/core";
import { IconMoodEmpty } from "@tabler/icons";
import { useTranslation } from "react-i18next";

export default function NoDataFound({ message = "No data found, please add some data first" }: { message?: string }) {

    const { t } = useTranslation();

    return (
        <Card withBorder>
            <Card.Section>
                <Text size={'sm'} my={'xl'} mx={'md'} sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                    <IconMoodEmpty size={50} />
                    <Divider orientation="vertical" mx={'lg'} />
                    {t(message)}
                </Text>
            </Card.Section>
        </Card>
    );

}