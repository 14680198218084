// vendors
import { useEffect, useState } from 'react';
import { Drawer } from "@mantine/core"
import { showNotification, updateNotification } from '@mantine/notifications';
import { useForm } from "@mantine/form";
import { IconSend, IconAlertCircle, } from '@tabler/icons';
// api
import { deleteLetter, updateLetter } from "../../../../api/letter";

// utils
import DrawerTitle from "../../../../components/DrawerTitle";
import { parseError, randomString } from "../../../../functions";
import { useTranslation } from 'react-i18next';
import FormFields from './components/FormFields';
import EditMenu from '../Users/shared/components/EditMenu';

export default function AddUser(props: any) {

    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();

    const form = useForm();

    const data = props?.data;
    useEffect(() => {
        if (data) {
            form.setValues({ documents: data?.documents });
        } else {
            form.reset();
        }
    }, [data]);

    const handleSubmit = (values: any) => {
        const id = `letter_${randomString(10)}`;
        setLoading(true);
        showNotification({
            id: id,
            title: t("Updating"),
            message: t("Please wait..."),
            loading: true,
            autoClose: false,
            disallowClose: true,
        });
        updateLetter(props?.data?.id, values).then(res => {
            updateNotification({
                id: id,
                title: t("Success"),
                message: t("Updated successfully"),
                color: "green",
                icon: <IconSend />,
                autoClose: true,
            });
            form.reset();
            props?.refreshData();
            props?.onClose();
        }).catch(err => {
            updateNotification({
                id: id,
                title: t("Error while updating"),
                message: parseError(err),
                color: "red",
                autoClose: false,
                icon: <IconAlertCircle />,
            });
        }).finally(() => {
            setLoading(false);
        });
    }
    return (
        <Drawer
            position="right"
            padding="xl"
            size="xl"
            {...props}
            onClose={() => {
                props.onClose();
                form.reset();
            }}
        >
            <DrawerTitle>{t("Edit")}</DrawerTitle>
            <EditMenu
                onDelete={() => deleteLetter(props.data.id)}
                buttonLabel="خيارات"
                deleteLabel="حذف"
                refreshData={props.refreshData}
                onClose={props.onClose}
            />
            {
                props.data ? (

                    <>
                        {data && (
                            <FormFields
                                form={form}
                                data={data}
                                loading={loading}
                                onSubmit={handleSubmit}
                                isEdit={true}
                                t={t}
                            />
                        )}

                    </>
                ) : null
            }
        </Drawer>
    )

}