import { useMemo } from 'react';
import { Box, NavLink } from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';
import { useNavigate } from 'react-router-dom';
import { IconLayoutDashboard, IconHeartHandshake, IconUsers, IconMap2, IconCurrencyDollar, IconChartDots3, IconHome, IconBuildingCommunity, IconTable, IconBuildingWarehouse, IconUser, IconMoodSmile, IconArticle, IconApiApp, IconBuilding, IconBrandAsana, IconAd, IconBell, IconSettings, IconBox, IconPigMoney, IconCashBanknote, IconBuildingSkyscraper } from '@tabler/icons';
import { useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { User as UserType } from '../../types/response';

export default function MainLinks(props: any) {

    const { width } = useViewportSize();
    const navigate = useNavigate();
    const location = useLocation();
    const { t, i18n } = useTranslation();

    const user: UserType = useSelector((state: any) => state.user.data);
    const permissions = user?.permissions?.permissions;

    const data = useMemo(() => [
        {
            icon: IconLayoutDashboard,
            color: 'blue',
            label: t('Dashboard'),
            link: '/app/dashboard',
            permission: ['properties.read', 'invoices.read'],
        },
        {
            icon: IconBox,
            color: 'blue',
            label: t('Management'),
            link: '',
            permission: [],
            children: [
                {
                    icon: IconMoodSmile,
                    color: 'blue',
                    label: t('Employees'),
                    link: '/app/users/employees',
                    permission: ['company_employees.read'],
                },
                {
                    icon: IconArticle,
                    color: 'blue',
                    label: t('Letters'),
                    link: '/app/letters',
                    permission: ['letters.read'],
                },
                {
                    icon: IconBuildingSkyscraper,
                    color: 'blue',
                    label: t('Cities'),
                    link: '/app/users/cities',
                    permission: [],
                },
            ],
        },
        {
            icon: IconBuildingCommunity,
            color: 'blue',
            label: t('Sales'),
            link: '/app/sales',
            permission: [],
            children: [
                {
                    icon: IconMap2,
                    color: 'blue',
                    label: t('Map'),
                    link: '/app/sales/map',
                    permission: ['properties.read'],
                },
                {
                    icon: IconHome,
                    color: 'blue',
                    label: t('Properties'),
                    link: '/app/sales/properties',
                    permission: ['properties.read'],
                },
                {
                    icon: IconBrandAsana,
                    color: 'blue',
                    label: t('Projects'),
                    link: '/app/sales/projects',
                    permission: ['properties.read'],
                },
                {
                    icon: IconHeartHandshake,
                    color: 'blue',
                    label: t('Contracts'),
                    link: '/app/contracts',
                    permission: ['contracts.read'],
                },
            ],
        },
        {
            icon: IconPigMoney,
            color: 'blue',
            label: t('الاستثمارات'),
            link: '/app/investment',
            permission: [],
            children: [
                {
                    icon: IconPigMoney,
                    color: 'blue',
                    label: t('جدول الاستثمارات'),
                    link: '/app/investment/overview',
                    permission: ['invoices.read', 'assets.read'],
                },
                {
                    icon: IconUsers,
                    color: 'blue',
                    label: t('المستثمرين'),
                    link: '/app/investment/investors',
                    permission: ['invoices.read', 'assets.read'],
                },
                {
                    icon: IconBuilding,
                    color: 'blue',
                    label: t('العقارات الإستثمارية'),
                    link: '/app/investment/investments',
                    permission: ['invoices.read', 'assets.read'],
                },
                {
                    icon: IconCashBanknote,
                    color: 'blue',
                    label: t('السحوبات'),
                    link: '/app/investment/tableout',
                    permission: ['invoices.read', 'assets.read'],
                },
                {
                    icon: IconCashBanknote,
                    color: 'blue',
                    label: t('الايداعات'),
                    link: '/app/investment/tablein',
                    permission: ['invoices.read', 'assets.read'],
                },
            ]
        },
        {
            icon: IconCurrencyDollar,
            color: 'blue',
            label: t('Finance'),
            link: '/app/finance',
            permission: ['invoices.read', 'assets.read'],
            children: [
                {
                    icon: IconChartDots3,
                    color: 'blue',
                    label: t('Overview'),
                    link: '/app/finance/overview',
                    permission: ['invoices.read', 'assets.read'],
                },
                {
                    icon: IconTable,
                    color: 'blue',
                    label: t('Invoices'),
                    link: '/app/finance/invoices',
                    permission: ['invoices.read', 'assets.read'],
                },
            ],
        },
        {
            icon: IconSettings,
            color: 'blue',
            label: t('App Management'),
            link: '',
            permission: [],
            children: [
                {
                    icon: IconUser,
                    color: 'blue',
                    label: t('Customers'),
                    link: '/app/users/customers',
                    permission: ['customers.read'],
                },
                {
                    icon: IconAd,
                    color: 'blue',
                    label: t('Advertising'),
                    link: '/app/ads',
                    permission: ['advertisements.read'],
                },
                {
                    icon: IconApiApp,
                    color: 'blue',
                    label: t('Utilities'),
                    link: '/app/utilities',
                    permission: ['utilities.read'],
                },
                {
                    icon: IconBuilding,
                    color: 'blue',
                    label: t('Vendors'),
                    link: '/app/utilities/vendors',
                    permission: ['utilities.read'],
                },
            ],
        },
        {
            icon: IconBuildingWarehouse,
            color: 'blue',
            label: t('Inventory'),
            link: '/app/inventory',
            permission: ['inventory_items.read'],
        },
        {
            icon: IconArticle,
            color: 'blue',
            label: t('Activity Logs'),
            link: '/app/logs',
            permission: ['super_users.read'],
        },
        {
            icon: IconBell,
            color: 'blue',
            label: t('الاشعارات'),
            link: '/app/notifications',
            permission: ['super_users.read'],
        },
    ], [i18n, user]);

    const items = data
        .filter(item => item.permission.every((key: any) => permissions ? Object.keys(permissions).includes(key.split('.')[0]) : false))
        .map((item: any, index: number) => (
            (
                !item.children || (item.children && item.children
                    .filter((child: any) => child.permission.every((key: any) => permissions ? Object.keys(permissions).includes(key.split('.')[0]) : false))
                    .length > 0)
            ) && (
                <NavLink
                    sx={{
                        width: width < 768 ? '100vw' : '300px',
                    }}
                    key={item.label}
                    active={(item.link.length && location.pathname.includes(item.link)) || (item.children && item.children.some((child: any) => child.link === location.pathname))}
                    opened={(item.link.length && location.pathname.includes(item.link)) || (item.children && item.children.some((child: any) => child.link === location.pathname))}
                    label={item.label}
                    description={item.description}
                    rightSection={item.rightSection}
                    icon={<item.icon size={16} stroke={1.5} />}
                    childrenOffset={0}
                    variant='filled'
                    onClick={() => {
                        if (item.children) {
                            navigate(item.children[0].link);
                        } else {
                            navigate(item.link);
                        }
                    }}
                >
                    {item.children && item.children
                        .filter((child: any) => child.permission.every((key: any) => permissions ? Object.keys(permissions).includes(key.split('.')[0]) : false))
                        .map((child: any, j: number) => (
                            <NavLink
                                sx={{
                                    width: width < 768 ? '100vw' : '300px',
                                    paddingLeft: '30px',
                                }}
                                key={child.label}
                                active={location.pathname === child.link}
                                label={child.label}
                                description={child.description}
                                rightSection={child.rightSection}
                                icon={<child.icon size={16} stroke={1.5} />}
                                onClick={() => {
                                    navigate(child.link);
                                }}
                            />
                        ))}
                </NavLink>
            )
        ));

    return <Box sx={{ width: 220 }}>{items}</Box>;

}