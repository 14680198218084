// vendors
import {
    Box,
    Button,
} from "@mantine/core";
import {
    IconBuildingBank,
    IconCashBanknote,
} from '@tabler/icons';
import dayjs from 'dayjs';
import { useSetState } from '@mantine/hooks';

// utils
import PageHeading from "../../../../../components/PageHeading";
import AddData from "./_AddDrawer";
import EditData from "./_EditDrawer";

// api
import { getInvoices } from "../../../../../api/invoice";

// types
import { Invoice as InvoiceType } from "../../../../../types/response";
import { dateFilterFn, highlightFilterValue, stringFilterFn } from "mantine-data-grid";
import SkeletonRows from "../../../../../components/SkeletonRows";
import NoDataFound from "../../../../../components/NoDataFound";
import { useTranslation } from "react-i18next";
import { useData } from "../../../../../hooks/useUsersData";
import DataGridComponent from '../../../../../components/DataGridComponent';

export default function Users() {
    const { t } = useTranslation();
    const [addInvoiceDrawer, setAddInvoiceDrawer] = useSetState<{ open?: boolean, type?: "bank" | "cash" | null }>({ open: false, type: null });
    const closeAddDrawer = () => { setAddInvoiceDrawer({ open: false, type: null }) };
    const openAddDrawer = (type: "bank" | "cash") => { setAddInvoiceDrawer({ open: true, type: type }) };
    const {
        loading,
        data,
        noData,
        total,
        editData,
        setEditData,
        load,
        search,
        refreshData,
    } = useData<InvoiceType>({
        fetchData: (page, limit, search) => getInvoices(page, limit, search, {
            invoice_type: 'investment',
            direction: 'in',
        }).then(res => ({
            data: res.data.invoices,
            total: res.data.total,
        })),
        errorMessage: t('Error while fetching user data'),
    });
    const column = [
        {
            accessorKey: 'num_id',
            header: t('ت') as string,
            filterFn: stringFilterFn,
            cell: highlightFilterValue,
            size: 50,
        },
        {
            accessorKey: 'from_to',
            header: t('اسم المودع') as string,
            filterFn: stringFilterFn,
            size: 300,
            cell: highlightFilterValue,
        },
        {
            accessorKey: 'amount',
            header: t('المبلغ') as string,
            filterFn: stringFilterFn,
            cell: (props: any) => props.row.original.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        },
        {
            header: t('Issue Date') as string,
            accessorKey: 'Issue Date',
            filterFn: dateFilterFn,
            cell: (props: any): string => dayjs(props.row.original.issued_at).format('DD/MM/YYYY'),
        },
        {
            accessorKey: 'description',
            header: t('الملاحظات') as string,
            filterFn: stringFilterFn,
            cell: highlightFilterValue,
        },
    ];
    return (
        <>
            <PageHeading>
                {t('الايداعات')}
            </PageHeading>
            <Button.Group
                mx={20}
                mb={20}
            >
                <Button variant="default" onClick={() => openAddDrawer('bank')} leftIcon={<IconBuildingBank size={16} />}>{t("Add Bank Invoice")}</Button>
                <Button variant="default" onClick={() => openAddDrawer('cash')} leftIcon={<IconCashBanknote size={16} />}>{t("Add Cash Invoice")}</Button>
            </Button.Group>
            {/* <TextInput
                px={20}
                pb={20}
                placeholder="Search"
                value={searchValue}
                onChange={e => setSearch(e.target.value)}
            /> */}
            <Box
                sx={{
                    padding: '0 20px',
                    width: '100%',
                    height: 'calc(100vh - 151.594px)',
                }}
            >
                <AddData
                    opened={addInvoiceDrawer.open as boolean}
                    onClose={() => closeAddDrawer()}
                    type={addInvoiceDrawer.type as 'bank' | 'cash'}
                    refreshData={refreshData}
                />
                <EditData
                    opened={editData !== null}
                    onClose={() => setEditData(null)}
                    invoice={editData as InvoiceType}
                    refreshData={refreshData}
                />
                {
                    data && data.length > 0 ? (
                        <DataGridComponent
                            data={data}
                            total={total}
                            loading={loading}
                            onPageChange={load}
                            onSearch={search}
                            onRowClick={(row) => setEditData(row)}
                            columns={column}
                        />
                    ) : noData ? (
                        <NoDataFound />
                    ) : (
                        <SkeletonRows count={10} height={20} />
                    )
                }
            </Box>
        </>
    );
}