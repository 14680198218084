import React from 'react';
import { Text } from '@mantine/core';
interface CustomDataProps {
  data: string;
}
const PrintHeader: React.FC<CustomDataProps> = ({ data }) => {

  return (
    <Text size={'xl'} mb={'xl'} weight={'bold'}>
      {data}
    </Text>
  );
};
export default PrintHeader;