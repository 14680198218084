// EditDrawer.tsx

import { useState } from 'react';
import { Drawer } from '@mantine/core';
import { IconAlertCircle, IconSend } from '@tabler/icons';
import { useTranslation } from 'react-i18next';
import DrawerTitle from '../../../../../components/DrawerTitle';
import CityForm from './components/CityForm';
import { randomString, parseError } from "../../../../../functions";
import { updateCity, deleteCity } from 'api/city';
import { showNotification, updateNotification } from '@mantine/notifications';
import EditMenu from '../shared/components/EditMenu';
export default function EditDrawer(props: any) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const handleEditCity = (values: any, resetForm: () => void) => {
    const id = `city_${randomString(10)}`;
    setLoading(true);
    showNotification({
      id: id,
      title: t('Updating'),
      message: t('Please wait...'),
      loading: true,
      autoClose: false,
      disallowClose: true,
    });

    updateCity(props?.data?.id, values).then(() => {
      updateNotification({
        id: id,
        title: t('Success'),
        message: t('Updated successfully'),
        color: 'green',
        icon: <IconSend />,
        autoClose: true,
      });
      resetForm();
      props?.refreshData();
      props?.onClose();
    }).catch((err) => {
      updateNotification({
        id: id,
        title: t('Error while updating'),
        message: parseError(err),
        color: 'red',
        autoClose: false,
        icon: <IconAlertCircle />,
      });
    }).finally(() => {
      setLoading(false);
    });
  };

  return (
    <Drawer
      position="right"
      padding="xl"
      size={800}
      {...props}
      onClose={() => {
        props.onClose();
      }}
    >
      <DrawerTitle>{t('Edit')}</DrawerTitle>
      <EditMenu
        onDelete={() => deleteCity(props.data.id)}
        buttonLabel="خيارات"
        deleteLabel="حذف"
        refreshData={props.refreshData}
        onClose={props.onClose} />
      <CityForm
        initialValues={{ name: props?.data?.name || "" }}
        onSubmit={handleEditCity}
        loading={loading}
        setLoading={setLoading}
        submitLabel={t("Save")}
      />
    </Drawer>
  );
}
