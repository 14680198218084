// vendors/EditDrawer.tsx
import { useState, useEffect } from 'react';
import {
    Drawer,
    ScrollArea,
} from "@mantine/core";
import { showNotification, updateNotification } from '@mantine/notifications';
import { useForm } from "@mantine/form";
import { IconSend, IconAlertCircle } from '@tabler/icons';
import { updateVendor, deleteVendor } from '../../../../api/vendor';
import DrawerTitle from "../../../../components/DrawerTitle";
import { parseError, randomString } from "../../../../functions";
import FormFields from './components/FormFields';
import { useTranslation } from 'react-i18next';
import EditMenu from '../Users/shared/components/EditMenu';

export default function EditDrawer(props: any) {
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();

    const form = useForm({});


    useEffect(() => {
        if (props.data) {
            form.setValues({
                documents: props.data.documents
            });
        }
    }, [props.data]);

    const handleSubmit = (values: any) => {
        const id = `contract_${randomString(10)}`;
        setLoading(true);
        showNotification({
            id: id,
            title: t('Updating'),
            message: t('Please wait...'),
            loading: true,
            autoClose: false,
            disallowClose: true,
        });

        updateVendor(props.data.id, values)
            .then(() => {
                updateNotification({
                    id: id,
                    title: t("Success"),
                    message: t("Updated successfully"),
                    color: "green",
                    icon: <IconSend />,
                    autoClose: true,
                });
                form.reset();
                props.refreshData();
                props.onClose();
            })
            .catch(err => {
                console.log(err);
                updateNotification({
                    id: id,
                    title: t("Error while updating"),
                    message: parseError(err),
                    color: "red",
                    autoClose: false,
                    icon: <IconAlertCircle />,
                });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Drawer
            position="right"
            padding="xl"
            size={800}
            {...props}
            onClose={() => {
                props.onClose();
                form.reset();
            }}
        >
            <DrawerTitle>{t('Edit')}</DrawerTitle>
            <EditMenu
                onDelete={() => deleteVendor(props.data.id)}
                buttonLabel="خيارات"
                deleteLabel="حذف"
                refreshData={props.refreshData}
                onClose={props.onClose}
            />
            <ScrollArea
                offsetScrollbars
                style={{ height: 'calc(100vh - 170px)' }}
            >
                <FormFields
                    form={form}
                    data={props?.data}
                    loading={loading}
                    onSubmit={handleSubmit}
                    initialData={props.data}
                    isEdit={true}
                />
            </ScrollArea>
        </Drawer>
    );
};

