
import {  useRef, useState } from 'react';
import { Container, TextInput, Textarea } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import  PrintHeader  from '../../../../components/PrintHeader';

// types

import FormLetter from '../../../../assets/form-letters.png';
import { DndContext} from '@dnd-kit/core';
import dayjs from 'dayjs';
import { PrintButton } from '../../../../components/PrintButton';
import { useDetectPrint } from '../../../../hooks/useDetectPrint';
import { handleDragEnd,convertToAbsolute } from '../../../../utilty/utils';
import{ getDynamicFieldMetadata }from '../../../../utilty/initalPosition';
import  DraggableField  from '../../../../components/DraggableField';
import { useFormFields } from '../../../../hooks/useFormFields';
import { IFieldMetadata } from 'types/printingTypes/types';


export default function Print() {
  const location = useLocation();
  const { t } = useTranslation();
  const printContainerRef = useRef<HTMLDivElement>(null);
  const printContractRef = useRef<HTMLImageElement>(null);
  const [printContractLoaded, setPrintContractLoaded] = useState(false);
  const isPrinting = useDetectPrint();

  const data: any | null = location.state as any | null;

  const initialValues = {
    num_id: data?.num_id ?? '',
    issued_at: data?.issued_at ? dayjs(data.issued_at).format('DD / MM / YYYY') : dayjs().format('DD / MM / YYYY'),
    receiver: data?.receiver ? `إلى/ ${data.receiver}` : 'إلى/ ',
    subject: data?.subject ? `م/ ${data.subject}` : 'م/',
    body: data?.body ?? '',
    description: data?.description
      ? 'المرفقات:\n' +
        data?.description
          ?.split('\n')
          // @ts-ignore
          .map((text, index) => `${index + 1}. ${text}`)
          .join('\n')
      : 'المرفقات\n',
    sender: data?.sender ?? '',
  };
  const fieldMetadata: IFieldMetadata = getDynamicFieldMetadata(t, 'letter');
  const { form, fields, setFields } = useFormFields(initialValues, fieldMetadata, 'default');

  return (
    <DndContext onDragEnd={(ev) => handleDragEnd(ev, fields, setFields, printContractRef)}>
      <Container
        m={0}
        px={0}
        pt={isPrinting ? 0 : 50}
        style={{ display: 'flex', boxSizing: 'border-box', alignItems: 'center', flexDirection: 'column', minHeight: '100vh', width: '100vw', maxWidth: '100%' }}>
        {!isPrinting && <PrintHeader data={t("print Letter")}/>}
        {!isPrinting && <PrintButton/>}
        <div style={{ display: 'flex', justifyContent: 'center', flexGrow: 1 }}>
          <Container p={0} m={0} id="print-area" ref={printContainerRef} style={{ position: 'relative', width: '210mm', height: '297mm' }}>
            <img
              ref={printContractRef}
              onLoad={() => setPrintContractLoaded(true)}
              style={{ maxHeight: '100%', maxWidth: '100%', position: 'absolute', top: 0, right: 0 }}
              src={FormLetter}
              alt=""
            />
            {printContractLoaded &&
              fields.map((field) => (
                <div
                  style={{
                    position: 'absolute',
                    transform: `translate3d(${convertToAbsolute(
                      printContractRef.current?.scrollWidth ?? 0,
                      0, // Always aligning to right (printContractRef.current?.offsetLeft ?? 0,)
                      field.transform.percentX //
                    )}px, ${convertToAbsolute(
                      printContractRef.current?.scrollHeight ?? 0,
                      0, // Always aligning to top (printContractRef.current?.offsetTop ?? 0),
                      field.transform.percentY //
                    )}px, 0)`,
                  }}
                  key={field.dragId}>
                  {isPrinting ? (
                    <div style={{ transform: 'translate(-20px,-10px)', whiteSpace: 'pre-line', lineHeight: '175%', ...fieldMetadata[field.id].style }}>
                      {/* @ts-ignore */}
                      <p style={{ position: 'relative' }}>{form.values[field.id]}</p>
                    </div>
                  ) : (
                    <DraggableField id={field.dragId}>
                      {fieldMetadata[field.id].type === 'TextInput' ? (
                        <TextInput size={fieldMetadata[field.id].inputSize} placeholder={fieldMetadata[field.id].placeholder['default']} {...form.getInputProps(field.id)} />
                      ) : fieldMetadata[field.id].type === 'Textarea' ? (
                        <Textarea
                          minRows={fieldMetadata[field.id].rowsCount}
                          autosize
                          size={fieldMetadata[field.id].inputSize}
                          placeholder={fieldMetadata[field.id].placeholder['default']}
                          label={fieldMetadata[field.id].label['default']}
                          style={{ width: window.innerWidth / 2 }}
                          {...form.getInputProps(field.id)}
                        />
                      ) : (
                        <p>Unhandled Field Type</p>
                      )}
                    </DraggableField>
                  )}
                </div>
              ))}
          </Container>
        </div>
      </Container>
    </DndContext>
  );
}
