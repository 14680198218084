// vendors
import { useState } from 'react';
import { Drawer, } from "@mantine/core"
import { showNotification, updateNotification } from '@mantine/notifications';
import { useForm } from "@mantine/form";
import { IconSend, IconAlertCircle, } from '@tabler/icons';

// api
import { createLetter } from "../../../../api/letter";

// utils
import DrawerTitle from "../../../../components/DrawerTitle";
import { parseError, randomString } from "../../../../functions";
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import FormFields from './components/FormFields';

export default function AddUser(props: any) {

    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();

    const form = useForm({
        initialValues: {
            subject: "",
            body: "",
            issued_at: new Date(),
            custom_id: "",
            direction: "in",
            sender: "",
            receiver: "",
            documents: {}
        },
    });
    const handleSubmit = (values: any) => {

        const id = `inv_${randomString(10)}`;
        setLoading(true);
        showNotification({
            id: id,
            title: t("Creating"),
            message: t("Please wait..."),
            loading: true,
            autoClose: false,
            disallowClose: true,
        });
        createLetter({ ...values, issued_at: dayjs(values.issued_at).format('YYYY-MM-DD') }).then(res => {
            updateNotification({
                id: id,
                title: t("Success"),
                message: t("Created successfully"),
                color: "green",
                icon: <IconSend />,
                autoClose: true,
            });
            form.reset();
            props?.refreshData();
            props?.onClose();
        }).catch(err => {
            updateNotification({
                id: id,
                title: t("Error while creating"),
                message: parseError(err),
                color: "red",
                autoClose: false,
                icon: <IconAlertCircle />,
            });
        }).finally(() => {
            setLoading(false);
        });
    }
    return (
        <Drawer
            position="right"
            padding="xl"
            size="xl"
            {...props}
        >
            <DrawerTitle>
                {t("Add")}
            </DrawerTitle>
            <FormFields
                form={form}
                loading={loading}
                onSubmit={handleSubmit}
                isEdit={false}
                t={t}
            />
        </Drawer>
    )

}