// vendors
import { Grid } from '@mantine/core';
import { useTranslation } from 'react-i18next';

// utils
import PageHeading from "../../../../components/PageHeading";
import SkeletonRows from '../../../../components/SkeletonRows';

import NoPermisionPage from '../../../../components/NoPermisionPage';
import { useHasPermission } from '../../../../hooks';
import DateRangeSelector from './component/DateRangeSelector';
import ReportCard from './component/ReportCard';
import ExpensesTable from './component/ExpensesTable';
import RevenueTable from './component/RevenueTable';

// hooks
import { useDateRange } from './Hooks/useDateRange';
import { usePropertyReports } from './Hooks/usePropertyReports';

export default function Dashboard() {

    const permissionGranted = useHasPermission(["super_users.read"]);
    const { t } = useTranslation();
    const [dateRange, setDateRange] = useDateRange();
    const { propertiesReport, propertiesRemainingReport, invoicesReport } = usePropertyReports(dateRange, permissionGranted);

    if (!permissionGranted) {
        return <NoPermisionPage />;
    }

    return (
        <>
            <PageHeading>
                {t("Dashboard")}
            </PageHeading>
            <Grid gutter={'lg'} mx={'lg'} px={0}>
                <Grid.Col span={12}>
                    <DateRangeSelector dateRange={dateRange} setDateRange={setDateRange} />
                </Grid.Col>
                <Grid.Col span={6}>
                    <ReportCard
                        iconType='home'
                        title={t("Rentings")}
                        reportData={propertiesReport}
                        propertiesRemainingReport={propertiesRemainingReport}
                        color={"blue"}
                        revenueField={propertiesReport?.rent?.revinue}
                        countField={propertiesReport?.rent?.count}
                        remainingCountField={propertiesRemainingReport?.rent?.count}
                        totalLabel="Total rentings"
                    />
                </Grid.Col>
                <Grid.Col span={6}>
                    <ReportCard
                        iconType='doller'
                        title={t("Sales")}
                        reportData={propertiesReport}
                        propertiesRemainingReport={propertiesRemainingReport}
                        color={"teal"}
                        revenueField={propertiesReport?.sold?.revinue}
                        countField={propertiesReport?.sold?.count}
                        remainingCountField={propertiesRemainingReport?.sale?.count}
                        totalLabel="Total sales"
                    />
                </Grid.Col>
                <Grid.Col span={12}>
                    {
                        invoicesReport ? (
                            <>
                                <ExpensesTable invoicesReport={invoicesReport} />
                                <RevenueTable invoicesReport={invoicesReport} />

                            </>
                        ) : (
                            <SkeletonRows count={5} height={15} />
                        )
                    }
                </Grid.Col>
            </Grid>
        </>
    );
}