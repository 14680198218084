import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useHasPermission } from '../../../../hooks';
import NoPermisionPage from '../../../../components/NoPermisionPage';

export { default as Overview } from './Overview';
export { default as Tablein } from './Tablein';
export { default as Tableout } from './Tableout';
export { default as Investors } from './Investors';
export { default as Investments } from './Investments';

export default function Investment() {

    const permissionGranted = useHasPermission(["invoices.read", "assets.read"]);

    const location = useLocation();
    const navigate = useNavigate();


    useEffect(() => {

        const paths = location.pathname.split('/').filter((item) => item !== '');

        if (paths[paths.length - 1] === 'investment') {
            navigate('/app/investment/overview');
        }
    }, []);

    if (!permissionGranted) {
        return <NoPermisionPage />;
    }

    return <Outlet />;

}