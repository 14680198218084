import React, { useEffect } from "react";
import { Select } from "@mantine/core";
import { IconAlertCircle, IconSend } from "@tabler/icons";

// api
import { createvCategory, getCategories } from "../api/vendor";

// types
import { Category as CategoryType } from "../types/response";
import { parseError, randomString } from "../functions";
import { showNotification, updateNotification } from "@mantine/notifications";


export default function VendorCategorySelectField({
    defaultData = [],
    ...props
}: {
    defaultData?: any;
    [key: string]: any;
}) {
    const [data, setData] = React.useState<CategoryType[]>(defaultData);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [creatingCategory, setCreatingCategory] = React.useState<boolean>(false);
    const [search, setSearch] = React.useState<string>('');
    const [value, setValue] = React.useState<string | null>(props.defaultValue ? props.defaultValue : null);

    useEffect(() => {
        if (defaultData.length > 0 && search === '') {
            setData(defaultData);
            return;
        }
        setLoading(true);
        getCategories(1, 10, search).then((res) => {
            setData(res.data.categories);
        }).catch((err) => {
            console.error(err);
        }).finally(() => {
            setLoading(false);
        });
    }, [search]);

    return (
        <Select
            label="Property"
            placeholder={loading ? 'Loading...' : 'Select category'}
            {...props}
            searchable
            creatable={true}
            disabled={creatingCategory}
            data={data.map((property: CategoryType) => ({
                label: property.name,
                value: property.name,

            }))}
            getCreateLabel={(query) => `+ Create ${query}`}
            value={value}
            onChange={(v) => {
                props.onChange && props.onChange(v);
                setValue(v);
            }}
            onCreate={(query) => {
                setCreatingCategory(true);
                const id = `category_${randomString(10)}`;
                showNotification({
                    id: id,
                    title: "Creating category",
                    message: "Please wait...",
                    loading: true,
                    autoClose: false,
                    disallowClose: true,
                });
                createvCategory({ name: query, category: query, category_ar: query })
                    .then((res: any) => {
                        updateNotification({
                            id: id,
                            title: "Success",
                            message: "Category created successfully",
                            color: "green",
                            icon: <IconSend />,
                            autoClose: true,
                        });
                        setData([res.data.category, ...data]);
                    })
                    .catch((err: any) => {
                        updateNotification({
                            id: id,
                            title: "Error while creating category",
                            message: parseError(err),
                            color: "red",
                            autoClose: false,
                            icon: <IconAlertCircle />,
                        });
                    })
                    .finally(() => {
                        setCreatingCategory(false);
                    });
                return { value: query, label: query };
            }}
            onSearchChange={(value) => {
                setSearch(value);
            }}
        />
    );
}
