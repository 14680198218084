import { useState, useEffect } from 'react';
import { Drawer, ScrollArea } from "@mantine/core";
import { showNotification, updateNotification } from '@mantine/notifications';
import { useForm } from "@mantine/form";
import { IconSend, IconAlertCircle } from '@tabler/icons';
import { useTranslation } from 'react-i18next';

// utils
import DrawerTitle from "../../../../../components/DrawerTitle";
import { parseError, randomString } from "../../../../../functions";
import { updateCustomer as updateUser, deleteCustomer as deleteUser } from "../../../../../api/customer";

// components
import UserForm from './components/CustomerForm';
import UserInfo from './components/UserInof';
import EditMenu from '../shared/components/EditMenu';

export default function EditDrawer(props: any) {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const form = useForm();
    const data = props?.data;
    useEffect(() => {
        !data && form.reset();
        data && form.setFieldValue('brithdate', new Date(data.brithdate));
    }, [data]);

    const handleSubmit = (values: any) => {
        if (values.birthdate) values.birthdate = (new Date(values.birthdate as string)).toISOString().split('T')[0]
        if (values.employment_date) values.employment_date = (values.employment_date as Date).toISOString().split('T')[0]
        const id = `user_${randomString(10)}`;
        setLoading(true);
        showNotification({
            id: id,
            title: t("Updating"),
            message: t("Please wait..."),
            loading: true,
            autoClose: false,
            disallowClose: true,
        });
        updateUser(props?.data?.id, values).then(res => {
            updateNotification({
                id: id,
                title: t("Success"),
                message: t("Updated successfully"),
                color: "green",
                icon: <IconSend />,
                autoClose: true,
            });
            form.reset();
            props?.refreshData();
            props?.onClose();
        }).catch(err => {
            console.log(err);
            updateNotification({
                id: id,
                title: t("Error while updating"),
                message: parseError(err),
                color: "red",
                autoClose: false,
                icon: <IconAlertCircle />,
            });
        }).finally(() => {
            setLoading(false);
        });
    };

    return (
        <Drawer
            position="right"
            padding="xl"
            size={800}
            {...props}
            onClose={() => {
                props.onClose();
                form.reset();
            }}
        >
            <DrawerTitle>
                {t("Edit")}
            </DrawerTitle>
            <EditMenu
                onDelete={() => deleteUser(props.data.id)}
                buttonLabel="خيارات"
                deleteLabel="حذف"
                refreshData={props.refreshData}
                onClose={props.onClose} />
            <ScrollArea
                offsetScrollbars
                style={{ height: 'calc(100vh - 117px)' }}
            >
                <UserInfo createdAt={props?.data?.created_at} />
                <UserForm
                    form={form}
                    data={data}
                    onSubmit={handleSubmit}
                    loading={loading}
                    isEdit={true}
                />
            </ScrollArea>
        </Drawer>
    );
}
