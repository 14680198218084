import React from "react";
import { Avatar, Box, Text } from "@mantine/core";
import dayjs from "dayjs";
import { User } from "../../../../../types/response";
import i18next from "i18next";

interface ProfileHeaderProps {
    user: User | null;
}

const ProfileHeader: React.FC<ProfileHeaderProps> = ({ user }) => (

    <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Avatar radius={'xl'} size={150}>
            {user?.firstname[0]}
        </Avatar>
        <Box mx={'xl'}>
            <Text size={'xs'}>{dayjs(user?.created_at as string).format("D MMM, YYYY")}</Text>
            <Text mt={'md'} size={30}>
                {
                    i18next.language === 'en' ?
                        user?.firstname ? user?.firstname + ' ' + user?.lastname : user?.firstname_ar + ' ' + user?.lastname_ar
                        :
                        user?.firstname_ar ? user?.firstname_ar + ' ' + user?.lastname_ar : user?.firstname + ' ' + user?.lastname
                }
            </Text>
            <Text mt={-5}>@{user?.accountname}</Text>
        </Box>
    </Box>
);

export default ProfileHeader;
